import { CompletedForm, Form } from '../types/models';

class OnboardingService {
    static getInitialFormsOnboarding() {
        return JSON.parse(localStorage.getItem('initialFormsOnboarding') || '[]');
    }

    static setInitialFormsOnboarding(forms: Form[]) {
        const formsString = JSON.stringify(forms);
        localStorage.setItem('initialFormsOnboarding', formsString);
        localStorage.setItem('initialFormsSet', 'true');
    }

    static isInitialFormsSet(): boolean {
        return !!localStorage.getItem('initialFormsSet');
    }

    static getCompletedForms() {
        return JSON.parse(localStorage.getItem('completedFormsOnboarding') || '[]');
    }

    static setCompletedForms(completedForms: CompletedForm[]) {
        const completedFormsString = JSON.stringify(completedForms);
        localStorage.setItem('completedFormsOnboarding', completedFormsString);
    }

    static markZoomLinkVisited() {
        localStorage.setItem('zoomLinkVisited', 'true');
    }

    static isZoomLinkVisited() {
        return localStorage.getItem('zoomLinkVisited') === 'true';
    }

    static clearOnboardingStorage(onLogout: boolean) {
        const onboardingKeys = [
            'onboardingSkipped',
            'zoomLinkVisited',
            'completedFormsOnboarding',
            'initialFormsOnboarding',
            'initialFormsSet',
            'showOnboardingVideo',
            'onboardingVideoWatched',
        ];
        onboardingKeys.forEach(key => localStorage.removeItem(key));

        if (onLogout) {
            localStorage.removeItem('TestZoomLinkVisited');
        }
    }

    static setOnboardingSkipped() {
        localStorage.setItem('onboardingSkipped', 'true');
    }

    static setOnboardingCompleted() {
        localStorage.setItem('showOnboardingCompletionModal', 'true');
    }

    static isOnboardingCompleted() {
        return localStorage.getItem('showOnboardingCompletionModal') === 'true';
    }

    static removeOnboardingCompleted() {
        localStorage.removeItem('showOnboardingCompletionModal');
    }

    static setTestZoomCompleted() {
        localStorage.setItem('TestZoomLinkVisited', 'true');
    }

    static isTestZoomCompleted() {
        return localStorage.getItem('TestZoomLinkVisited') === 'true';
    }

    static isOnboardingVideoWatched() {
        return localStorage.getItem('onboardingVideoWatched') === 'true';
    }

    static setOnboardingVideoWatched() {
        localStorage.setItem('onboardingVideoWatched', 'true');
    }

    static shouldShowOnboardingVideo() {
        // Show onboarding video if it's the first time the user is logging in or if the value is set to true
        return localStorage.getItem('showOnboardingVideo') === 'true'
          || localStorage.getItem('showOnboardingVideo') === null;
    }

    static setOnboardingVideoShown(show: boolean) {
        localStorage.setItem('showOnboardingVideo', show.toString());
    }
}

export default OnboardingService;
