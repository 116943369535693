var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from '../index.styles';
import { faMessage } from '@fortawesome/free-solid-svg-icons';
import { formatPhoneNumberForDisplay } from '../utlils';
var SupportText = function (_a) {
    var phoneNumber = _a.phoneNumber, supportImage = _a.supportImage;
    return (_jsxs(S.SupportCard, { children: [_jsxs(S.HeadingContainer, { children: [_jsx(S.SupportImg, { src: supportImage, alt: "Chat" }), _jsx(S.SubHeader, { children: "SMS Text Message" })] }), _jsxs(S.DesktopText, { children: ["Text ", formatPhoneNumberForDisplay(phoneNumber)] }), _jsxs(S.DesktopTextInfo, { children: ["We will respond as soon as", _jsx("br", {}), "possible via text message"] }), _jsx(S.MobileText, { children: formatPhoneNumberForDisplay(phoneNumber) }), _jsxs(S.MobileButton, __assign({ href: "sms:+".concat(phoneNumber) }, { children: [_jsx(S.ButtonIcon, { icon: faMessage }), "Text our Support Team"] }))] }));
};
export default SupportText;
