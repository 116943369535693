var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
var SpinnerContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n"], ["\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n"])));
var SpinnerInner = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: ", " solid ", ";\n  border-top: ", " solid ", ";\n  border-radius: 50%;\n  width: ", ";\n  height: ", ";\n  animation: spin 0.5s linear infinite;\n"], ["\n  border: ", " solid ", ";\n  border-top: ", " solid ", ";\n  border-radius: 50%;\n  width: ", ";\n  height: ", ";\n  animation: spin 0.5s linear infinite;\n"])), function (props) { return props.small ? '3px' : '10px'; }, function (props) { return props.theme.background; }, function (props) { return props.small ? '3px' : '10px'; }, function (props) { return props.theme.primary.medium; }, function (props) { return props.small ? '20px' : '50px'; }, function (props) { return props.small ? '20px' : '50px'; });
function Spinner(_a) {
    var _b = _a.small, small = _b === void 0 ? false : _b;
    return (_jsx(SpinnerContainer, { children: _jsx(SpinnerInner, { small: small }) }));
}
export default Spinner;
var templateObject_1, templateObject_2;
