var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var Select = styled.select(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  border-radius: 10px;\n  padding: 10px;\n  margin: 10px;\n  width: ", ";\n  background-color: #FBFBFB;\n  :focus {\n    outline: none;\n  }\n"], ["\n  border: 1px solid ", ";\n  border-radius: 10px;\n  padding: 10px;\n  margin: 10px;\n  width: ", ";\n  background-color: #FBFBFB;\n  :focus {\n    outline: none;\n  }\n"])), function (props) { return (props.error ? props.theme.text.error : props.theme.shades.lightGrey); }, function (props) {
    if (props.size === 'small') {
        return '100px';
    }
    else if (props.size === 'medium') {
        return '200px';
    }
    else if (props.size === 'large') {
        return '300px';
    }
    return '100%';
});
export default Select;
var templateObject_1;
