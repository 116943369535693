import { Buttons, Card } from '@apps/common-ui';
import styled from 'styled-components';

export const SupportContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70vw;
  max-width: 1100px;
  margin: 0 20px;

  @media (max-width: ${props => props.theme.screen.medium}) {
    width: 100vw;
    padding: 0 20px;
  }
`;

export const BackButton = styled(Buttons.Button)`
  margin: 0;
  padding: 0;
  align-self: flex-start;

  & svg:last-of-type {
    margin-left: 0;
  }
`;

export const SubHeader = styled.h2`
  margin-top: 20px;
  font-weight: normal;
  @media (max-width: ${props => props.theme.screen.medium}) {
    margin: 0;
    margin-left: 20px;
    padding-bottom: 0;
    padding-top: 0;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const Header = styled(SubHeader)`
  @media (max-width: ${props => props.theme.screen.medium}) {
    margin-left: 0;
    padding-bottom: 20px;
    padding-top: 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: ${props => props.theme.screen.medium}) {
    padding: 0 0 0 10px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: ${props => props.theme.screen.medium}) {
    flex-direction: column;
  }
`;
