import { Card } from '@apps/common-ui';
import { Button } from '@aws-amplify/ui-react';
import styled from 'styled-components';

export const AccountItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0;
  height: 100px;
`;

export const AccountAction = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AccountItemTitle = styled.p`
  margin: 0;
  font-size: 18px;
  color: ${props => props.theme.primary.medium};
`;

export const AccountItemInput = styled.input`
  border: none;
  background: none;
`;

export const AccountCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
`;

export const LogoutButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;
