import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import PostExerciseBaseline from '../BaselineModals/PostExerciseBaseline';
import * as S from './index.styles';
import { redirect, useLocation, useNavigate, useParams } from 'react-router';
import { ExerciseTypes, Utils } from '@apps/common-utilities';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { equipment } from '@apps/common-utilities/src/types/labels';
import Mixpanel from '../../../../services/Mixpanel';
import { useActionLoader } from '../../../../hooks/useActionLoader';
import { createExerciseSubmission, fetchExercisePlan } from '../../../../state/reducers/coaching/actions';
import BaselineWarning from '../BaselineModals/BaselineWarning';
import { Spinner, useModal } from '@apps/common-ui';
import { format } from 'date-fns';
import { Indexable } from '../../../../types/common';
import { TenantApi } from '../../../../api/UsersApi';
import { RequestMethod, useApiRequest } from '../../../../hooks/useApiRequest';
import toast from 'react-hot-toast';

const getTypeString = (type: ExerciseTypes.ExerciseType): string => {
    if (type === ExerciseTypes.ExerciseType.SETS_AND_REPS || type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS) {
        return 'sets of';
    } else if (type === ExerciseTypes.ExerciseType.INTERVALS) {
        return 'intervals of';
    } else if (type === ExerciseTypes.ExerciseType.HOLD) {
        return 'hold for';
    }
    return '';
};

interface Metrics {
    heartRate: number | null;
    immediateSpO2Percent: number | null;
    lowestSpO2Percent: number | null;
    flowRate: number | null;
    breathlessnessLevel: number | null;
    assignedExerciseResults: ExerciseTypes.IAssignedExerciseResults[] | null;
    rmtDifficulty: number | null;
    rmtInhaleResistance: number | null;
    rmtExhaleResistance: number | null;
    comment: string | null;
}

const initialDaySummary = {
    planId: 0,
    dayOfWeek: '',
    startDate: '',
    endDate: '',
    publishedAt: '',
    exercises: [],
    spO2PercentTarget: 0,
    userAge: 0,
    restingHeartRate: 0,
    onBetaBlockers: false,
    dischargeDate: null,
    complete: false
};

export const ExercisePage = () => {
    const [warningMetrics, setWarningMetrics] = useState<Metrics|null>(null);
    const { date } = useParams<{date: string}>();
    const { id } = useParams<{id: string}>();
    const [daySummary, setDaySummary] = useState<ExerciseTypes.IExerciseDaySummary>(initialDaySummary);
    const [exercise, setExercise] = useState<ExerciseTypes.IExerciseSummary>();
    const startTime = new Date();
    const { tenantUser } = useSelector((state: RootState) => state.session);
    const [lastBaseline, setLastBaseline] = useState<ExerciseTypes.IFetchedBaseline>();
    const [showActiveTargets, setShowActiveTargets] = useState(false);
    const [showRestTargets, setShowRestTargets] = useState(false);
    const [loadingHistory, setLoadingHistory] = useState(false);
    const { isOpen: isBaselineOpen, openModal: openBaselineModal, closeModal: closeBaselineModal } = useModal();
    const { isOpen: isWarningOpen, openModal: openWarningModal, closeModal: closeWarningModal } = useModal();
    const [isPortrait, setIsPortrait] = useState(false);
    const [isRMT, setIsRMT] = useState(false);
    const navigate = useNavigate();
    const [submittingBaseline, setSubmittingBaseline] = useState(false);

    const [warningType, setWarningType] = useState(ExerciseTypes.WarningType.MULTIPLE);

    const { callApi: createSubmission } = useApiRequest(RequestMethod.POST);

    const fetchLastBaseline = () => {
        if (!tenantUser) {
            return;
        }
        TenantApi.get(`/users/${tenantUser.id}/exercise-plans/exercise-baselines/latest`).then((res: ExerciseTypes.IFetchedBaseline) => {
            if (res) {
                setLastBaseline(res);
            }
        }).catch(err => {
            console.error(err);
        });
    };

    useEffect(() => {
        // eslint-disable-next-line no-restricted-globals
        const currentState = history.state;
        if (isRMT) {
            // eslint-disable-next-line no-restricted-globals
            history.replaceState(currentState, '', '/breather/');
        } else {
            // eslint-disable-next-line no-restricted-globals
            history.replaceState(currentState, '', '/exercises/');
        }
        fetchLastBaseline();
    }, [isRMT]);

    useEffect(() => {
        if (date) {
            setLoadingHistory(true);
            TenantApi.get(`/users/${tenantUser?.id}/exercise-plans/daily-summary`, {
                params: {
                    startDate: new Date(date).toISOString(),
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                } }).then((res: ExerciseTypes.IExerciseDaySummary) => {
                if (res) {
                    setDaySummary(res);
                    const exerciseToShow = res.exercises.find((e) => e.id === Number(id));
                    setIsRMT(exerciseToShow?.exerciseInfo.type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS);
                    setExercise(exerciseToShow);
                    setShowActiveTargets(exerciseToShow?.exerciseVariable.showSpO2TargetPercent || exerciseToShow?.exerciseVariable.showBreathlessnessTarget || !!exerciseToShow?.exerciseVariable.maxHeartRatePercent || !!exerciseToShow?.exerciseVariable.minHeartRatePercent);
                    setShowRestTargets(exerciseToShow?.exerciseVariable.showSpO2RestPercent || exerciseToShow?.exerciseVariable.showBreathlessnessRest || !!exerciseToShow?.exerciseVariable.heartRateRestPercent);
                }
            }).finally(() => {
                setLoadingHistory(false);
            });
        }
    }, [date, id]);

    useEffect(() => {
        if (exercise) {
            const videoUrlParams = new URLSearchParams(exercise.exerciseInfo.videoUrl);
            setIsPortrait(videoUrlParams.get('portrait') === '1');
        }
    }, [exercise]);

    if (loadingHistory) {
        return <Spinner />;
    }

    if (!exercise || !id) {
        return null;
    }

    const { videoUrl } = exercise.exerciseInfo;
    const getHeartRateFromPercentage = (percentage: number): number => Utils.KarvonenFormula(daySummary.userAge, daySummary.restingHeartRate, daySummary.onBetaBlockers, percentage);
    const getEquipmentString = equipment;

    const submitExercise = async (metrics: Metrics, hasWarning?: boolean) => {
        setSubmittingBaseline(true);
        const submission: ExerciseTypes.IAssignedExerciseSubmission = {
            ...metrics,
            id: null,
            startTime,
            assignedExerciseId: exercise.id,
            exerciseBaselineId: lastBaseline?.id ? lastBaseline.id : -1,
            calendarCreatedAt: format(new Date(), 'yyyy-MM-dd'),
            calendarCompletionDate: format(new Date(), 'yyyy-MM-dd'),
        };

        const res = await createSubmission('/exercise-plans/exercise-submissions', submission);
        setSubmittingBaseline(false);
        if (res.error.hasError) {
            toast.error('Error submitting exercise, please try again and contact support if the problem persists.');
            return;
        }
        closeBaselineModal();
        toast.success('Exercise submitted successfully');
        if (!hasWarning) {
            navigate(-1);
        }
    };

    const shouldShowWarning = (metrics: Metrics) => {
        let warningCount = 0;
        if (metrics.heartRate && metrics.heartRate < 50) {
            setWarningType(ExerciseTypes.WarningType.HEARTRATE_LOW);
            warningCount += 1;
        } else if (metrics.heartRate && metrics.heartRate > 150) {
            setWarningType(ExerciseTypes.WarningType.HEARTRATE_HIGH);
            warningCount += 1;
        }

        if ((metrics.immediateSpO2Percent && (metrics.immediateSpO2Percent < 85 || metrics.immediateSpO2Percent < daySummary.spO2PercentTarget)) || (metrics.lowestSpO2Percent && (metrics.lowestSpO2Percent < 85 || metrics.lowestSpO2Percent < daySummary.spO2PercentTarget))) {
            setWarningType(ExerciseTypes.WarningType.SPO2);
            warningCount += 1;
        }

        if (warningCount > 0) {
            if (warningCount > 1) {
                setWarningType(ExerciseTypes.WarningType.MULTIPLE);
            }
            setWarningMetrics(metrics);
            submitExercise(metrics, true);
            closeBaselineModal();
            openWarningModal();
        } else {
            submitExercise(metrics);
        }
    };

    // Check if any of the required submission options are true
    const areVitalsRequired = () => {
        if (daySummary.complete) {
            return false;
        }
        return Object.keys(exercise.exerciseVariable)
            .some(key => key.startsWith('require') && (exercise.exerciseVariable as Indexable<ExerciseTypes.IExerciseVariable>)[key]);
    };

    return (
        <>
            {isBaselineOpen && (
                <PostExerciseBaseline
                  exercise={exercise}
                  onClose={closeBaselineModal}
                  checkSubmission={(metrics: Metrics) => shouldShowWarning(metrics)}
                  showOxygen={lastBaseline ? !!lastBaseline.flowRate : true}
                  submitting={submittingBaseline}
                />
            )}
            {isWarningOpen && (
                <BaselineWarning
                  onClose={closeWarningModal}
                  sp02Target={daySummary.spO2PercentTarget}
                  openBaselineModal={() => { closeWarningModal(); openBaselineModal(); }}
                  warningType={warningType}
                  proceedToExercise={() => {
                    if (warningMetrics) {
                        closeWarningModal();
                        navigate(-1);
                    } else {
                        closeWarningModal();
                        openBaselineModal();
                    }
                }}
                />
            )}
            <S.Container>
                <S.TopBar>
                    <S.BackButton onClick={() => navigate(-1)}><FontAwesomeIcon icon={faChevronLeft as IconProp} /> Back</S.BackButton>
                </S.TopBar>
                <S.PageContent isPortrait={isPortrait}>
                    <S.ExerciseDetails>
                        <S.DetailsContainer>
                            <S.ExerciseTitle>{exercise.exerciseInfo.title}</S.ExerciseTitle>
                            <S.Section>
                                <S.SectionRow>
                                    {(exercise.exerciseInfo.type === ExerciseTypes.ExerciseType.SETS_AND_REPS
                                    || exercise.exerciseInfo.type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS) && (
                                        <S.AmountContainer>
                                            <S.AmountLabel>{exercise.exerciseVariable.sets}</S.AmountLabel>
                                            <S.UnitLabel> {getTypeString(exercise.exerciseInfo.type)} </S.UnitLabel>
                                            <S.AmountLabel>{exercise.exerciseVariable.amount} {exercise.exerciseVariable.unit.toLowerCase()}</S.AmountLabel>
                                        </S.AmountContainer>
                                    )}
                                    {exercise.exerciseInfo.type === ExerciseTypes.ExerciseType.INTERVALS && (
                                        <S.AmountContainer>
                                            <S.AmountLabel>{exercise.exerciseVariable.amount}</S.AmountLabel>
                                            <S.UnitLabel> {getTypeString(exercise.exerciseInfo.type)} </S.UnitLabel>
                                            <S.AmountLabel>{exercise.exerciseVariable.sets} {exercise.exerciseVariable.unit.toLowerCase()}</S.AmountLabel>
                                        </S.AmountContainer>
                                    )}
                                    {exercise.exerciseInfo.type === ExerciseTypes.ExerciseType.HOLD && (
                                        <S.AmountContainer>
                                            {!!exercise.exerciseVariable.sets && <><S.AmountLabel>{exercise.exerciseVariable.sets}</S.AmountLabel><S.UnitLabel>sets of</S.UnitLabel></>}
                                            <S.AmountLabel>{exercise.exerciseVariable.amount}</S.AmountLabel>
                                            <S.UnitLabel>reps, </S.UnitLabel>
                                            <S.UnitLabel>{getTypeString(exercise.exerciseInfo.type)} </S.UnitLabel>
                                            <S.AmountLabel>{exercise.exerciseVariable.holdSeconds} seconds</S.AmountLabel>
                                        </S.AmountContainer>
                                    )}
                                </S.SectionRow>
                            </S.Section>
                            <S.Section>
                                <S.SectionTitle>Equipment</S.SectionTitle>
                                {!!exercise.exerciseVariable.exerciseEquipment.length && (
                                <>
                                    {exercise.exerciseVariable.exerciseEquipment.map((eq) => (
                                        <S.SectionRow>
                                            {eq.equipment === ExerciseTypes.ExerciseEquipment.ASSISTED_DEVICES && eq.specification
                                            ? <S.SectionText>{eq.specification}</S.SectionText> : <S.SectionText>{eq.specification ? `${eq.specification} ` : ''}{getEquipmentString(eq.equipment)}</S.SectionText>}
                                        </S.SectionRow>
                                ))}
                                </>
                                )}
                                {exercise.exerciseInfo.type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS && (
                                    <S.SectionRow>
                                        <S.SectionText>RMT Breather</S.SectionText>
                                    </S.SectionRow>
                                )}
                                {!exercise.exerciseVariable.exerciseEquipment.length
                                    && exercise.exerciseInfo.type !== ExerciseTypes.ExerciseType.SETS_AND_BREATHS
                                    && (
                                    <S.SectionRow>
                                        <S.SectionText>No Equipment</S.SectionText>
                                    </S.SectionRow>
                                )}
                            </S.Section>
                            {showActiveTargets && (
                                <S.Section>
                                    <S.SectionTitle>Active Interval Targets</S.SectionTitle>
                                    {exercise.exerciseVariable.showSpO2TargetPercent && <S.SectionText>{'SpO2 >'} {daySummary.spO2PercentTarget}% </S.SectionText>}
                                    {exercise.exerciseVariable.showBreathlessnessTarget && <S.SectionText>Breathlessness between 4-6/10</S.SectionText>}
                                    {!!exercise.exerciseVariable.maxHeartRatePercent && !!exercise.exerciseVariable.minHeartRatePercent && (
                                        <S.SectionText>
                                            Target HR: {getHeartRateFromPercentage(exercise.exerciseVariable.minHeartRatePercent)} - {getHeartRateFromPercentage(exercise.exerciseVariable.maxHeartRatePercent)}bpm
                                        </S.SectionText>
                                    )}
                                </S.Section>
                            )}
                            {showRestTargets && (
                                <S.Section>
                                    <S.SectionTitle>Rest Interval Targets</S.SectionTitle>
                                    {exercise.exerciseVariable.showSpO2RestPercent && <S.SectionText>{'SpO2 >'} {daySummary.spO2PercentTarget}% </S.SectionText>}
                                    {exercise.exerciseVariable.showBreathlessnessRest && <S.SectionText>{'Breathlessness < 4/10'}</S.SectionText>}
                                    {!!exercise.exerciseVariable.heartRateRestPercent && <S.SectionText>{`HR < ${getHeartRateFromPercentage(exercise.exerciseVariable.heartRateRestPercent)}bpm`} </S.SectionText>}
                                </S.Section>
                            )}
                            <S.Section noBorder>
                                <S.SectionTitle blue>Exercise Description</S.SectionTitle>
                                <S.SectionTextDiv dangerouslySetInnerHTML={{ __html: exercise.exerciseInfo.instructions }} />
                            </S.Section>
                            {exercise.exerciseVariable.additionalInstructions && (
                                <S.Section noBorder>
                                    <S.SectionTitle blue>Personalized Instructions</S.SectionTitle>
                                    <S.SectionText>{exercise.exerciseVariable.additionalInstructions}</S.SectionText>
                                </S.Section>
                            )}
                        </S.DetailsContainer>
                    </S.ExerciseDetails>
                    <S.VideoColumn isPortrait={isPortrait}>
                        <S.VideoContainer isPortrait={isPortrait}>
                            <iframe src={videoUrl} title="ExerciseVideo" allow="autoplay;" frameBorder="0" allowFullScreen />
                        </S.VideoContainer>
                        <S.InputContainer isPortrait={isPortrait}>
                            <S.InputButton
                              onClick={() => {
                                if (daySummary.complete) {
                                    navigate(-1);
                                    return;
                                }
                                if (areVitalsRequired()) {
                                    openBaselineModal();
                                } else {
                                    openBaselineModal();
                                }
                                Mixpanel.track('web_exercise_page_input_results', {
                                    exercise: exercise.exerciseInfo.title,
                                    exerciseNumber: daySummary.exercises.findIndex((e) => e.id === exercise.id) + 1,
                                });
                            }}
                            >
                                {areVitalsRequired() ? 'Input your results' : 'Continue'}
                            </S.InputButton>
                        </S.InputContainer>
                    </S.VideoColumn>
                </S.PageContent>
            </S.Container>
        </>
    );
};

export default ExercisePage;
