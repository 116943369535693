import { useSelector } from 'react-redux';
import * as S from './index.styles';
import { RootState } from '../../state/store';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

export const ActionPlanPage = () => {
    const { actionPlan } = useSelector((state: RootState) => state.coaching);
    const [createdAtFormatted, setCreatedAtFormatted] = useState<string>();

    useEffect(() => {
        if (actionPlan) {
            setCreatedAtFormatted(format(new Date(actionPlan.createdAt), 'MMMM d, yyyy'));
        }
    }, [actionPlan]);

    return (
        <S.Container>
            <S.Heading>Action Plan</S.Heading>
            <S.ActionPlanCard>
                <S.ActionPlanBody>
                    <S.ActionPlanText fontWeight="bold">
                        Please review and approve this plan with your healthcare provider,
                        <br />{actionPlan?.createdByName}, before following it.
                    </S.ActionPlanText>
                    <S.ActionPlanText>
                        Plan was last updated {createdAtFormatted} by {actionPlan?.createdByName}.
                    </S.ActionPlanText>
                </S.ActionPlanBody>
            </S.ActionPlanCard>
            <S.ActionPlanMainText>We have broken your plan into 3 types of days:</S.ActionPlanMainText>
            <S.ActionPlanCard>
                <S.TitleContainer colorZone="green"><S.TitleText>Green Zone</S.TitleText></S.TitleContainer>
                <S.ActionPlanBody>
                    <S.ActionRow>
                        <S.ActionPlanText marginBottom="0">I am doing well today.</S.ActionPlanText>
                        <S.ActionPlanButton to="/action-plan/details/green">View Details</S.ActionPlanButton>
                    </S.ActionRow>
                </S.ActionPlanBody>
            </S.ActionPlanCard>
            <S.ActionPlanCard>
                <S.TitleContainer colorZone="yellow"><S.TitleText>Yellow Zone</S.TitleText></S.TitleContainer>
                <S.ActionPlanBody>
                    <S.ActionRow>
                        <S.ActionPlanText marginBottom="0">
                            My symptoms are worse than usual or I am having a flare-up.
                        </S.ActionPlanText>
                        <S.ActionPlanButton to="/action-plan/details/yellow">View Details</S.ActionPlanButton>
                    </S.ActionRow>
                </S.ActionPlanBody>
            </S.ActionPlanCard>
            <S.ActionPlanCard>
                <S.TitleContainer colorZone="red"><S.TitleText>Red Zone</S.TitleText></S.TitleContainer>
                <S.ActionPlanBody>
                    <S.ActionRow>
                        <S.ActionPlanText marginBottom="0">I need urgent medical care.</S.ActionPlanText>
                        <S.ActionPlanButton to="/action-plan/details/red">View Details</S.ActionPlanButton>
                    </S.ActionRow>
                </S.ActionPlanBody>
            </S.ActionPlanCard>
        </S.Container>

    );
};

export default ActionPlanPage;
