var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
var TranslucentContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: fixed;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    width: 100%;\n    height 100%;\n    background-color: rgba(0, 0, 0, 0.30);\n    z-index: 6;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n"], ["\n    position: fixed;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    width: 100%;\n    height 100%;\n    background-color: rgba(0, 0, 0, 0.30);\n    z-index: 6;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n"])));
var TranslucentBackground = function (_a) {
    var showBackground = _a.showBackground, children = _a.children;
    return (showBackground ? _jsx(TranslucentContainer, { children: children }) : _jsx("div", { children: children }));
};
export default TranslucentBackground;
var templateObject_1;
