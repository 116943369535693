import React from 'react';
import { Route, Routes } from 'react-router';
import ExercisePage from '../../pages/Exercises/components/ExercisePage';
import Lesson from '../../pages/Learning/Lesson';
import Quiz from '../../pages/Learning/Quiz';

const ModalRouter = () => (
    <main className="main-content-modal">
        <Routes>
            <Route path="exercise/:date/:id" element={<ExercisePage />} />
            <Route path="module/:moduleId/lesson/:id/:completed" element={<Lesson />} />
            <Route path="module/:moduleId/quiz/:id/:completed/:score" element={<Quiz />} />
        </Routes>
    </main>
);

export default ModalRouter;
