import { Modal } from '@apps/common-ui';
import { ExerciseTypes } from '@apps/common-utilities';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Mixpanel from '../../../../../services/Mixpanel';
import * as S from './index.styles';

type Props = {
    onClose: () => void;
    openBaselineModal: () => void;
    sp02Target: number;
    warningType: ExerciseTypes.WarningType;
    proceedToExercise?: Function;
}

const BaselineWarning = ({ onClose, openBaselineModal, warningType, proceedToExercise, sp02Target }: Props) => {
    const getTitle = () => {
        if (warningType === ExerciseTypes.WarningType.HEARTRATE_LOW) {
            return 'Low Heart Rate (HR) Warning';
        } else if (warningType === ExerciseTypes.WarningType.HEARTRATE_HIGH) {
            return 'High Heart Rate (HR) Warning';
        } else if (warningType === ExerciseTypes.WarningType.SPO2) {
            return 'Low Oxygen Saturation (SpO2) Warning';
        } else if (warningType === ExerciseTypes.WarningType.BPDIASTOLIC_LOW || warningType === ExerciseTypes.WarningType.BPSYSTOLIC_LOW) {
            return 'Low Blood Pressure Warning';
        } else if (warningType === ExerciseTypes.WarningType.BPDIASTOLIC_HIGH || warningType === ExerciseTypes.WarningType.BPSYSTOLIC_HIGH) {
            return 'High Blood Pressure Warning';
        } else if (warningType === ExerciseTypes.WarningType.MULTIPLE) {
            return 'Multiple Warnings';
        }
        return '';
    };

    const getButtonText = () => {
        if (warningType === ExerciseTypes.WarningType.HEARTRATE_LOW) {
            return 'My HR is above 50 bpm';
        } else if (warningType === ExerciseTypes.WarningType.HEARTRATE_HIGH) {
            return 'My HR is below 150bpm';
        } else if (warningType === ExerciseTypes.WarningType.SPO2) {
            return `My SpO2 is above ${sp02Target}%`;
        } else if (warningType === ExerciseTypes.WarningType.MULTIPLE) {
            return 'My vitals are in the safe range shown above';
        }
        return '';
    };

    return (
        <Modal showModal dismissModal={onClose} showTopBar={false} padded={false} borderRadius="16px" maxHeight="100vh" closeOnOutsideClick={false}>
            <S.ModalContent>
                <S.TopBar>
                    <S.TitleAndIconContainer>
                        <S.IconContainer>
                            <S.WarningIcon src="/SafetyWarningIconBlue.svg" />
                        </S.IconContainer>
                        <S.Title>{getTitle()}</S.Title>
                    </S.TitleAndIconContainer>
                    <S.CloseContainer>
                        <S.CloseButton onClick={onClose}><FontAwesomeIcon icon={faX as IconProp} size="xs" /><p>Close</p></S.CloseButton>
                    </S.CloseContainer>
                </S.TopBar>
                {!proceedToExercise && (
                <>
                    <S.WarningBody>
                        {warningType === ExerciseTypes.WarningType.HEARTRATE_LOW && (
                        <>
                            <S.Paragraph>Hey, it looks like your <b>heart rate is reading low.</b></S.Paragraph>
                            <S.Paragraph>
                                Take time to sit and rest, and check again in 5 minutes. For your safety, please do not begin exercising until your HR is <b>higher than 50bpm.</b>
                            </S.Paragraph>
                            <S.Paragraph>If you&apos;re feeling faint, dizzy or unwell with a low HR please call 9-1-1.</S.Paragraph>
                        </>
                        )}
                        {warningType === ExerciseTypes.WarningType.HEARTRATE_HIGH && (
                        <>
                            <S.Paragraph>Hey, it looks like your <b>heart rate is reading high for rest.</b></S.Paragraph>
                            <S.Paragraph>
                                Take time to sit and rest, and check again in 5 minutes. For your safety, do not begin exercising until your <b>HR is below 110bpm.</b>
                            </S.Paragraph>
                            <S.Paragraph>If you&apos;re feeling unwell with a persistent high HR please contact your doctor or call 9-1-1 if you feel this is an emergency.</S.Paragraph>
                        </>
                        )}
                        {warningType === ExerciseTypes.WarningType.SPO2 && (
                        <>
                            <S.Paragraph>Hey, it looks like your <b>oxygen saturation (SpO2) is reading low.</b></S.Paragraph>
                            <S.Paragraph>Let&apos;s try a few things:</S.Paragraph>
                            <S.List>
                                <li>Sit and rest</li>
                                <li>Use pursed lip breathing</li>
                                <li>Warm up your fingers and be sure you see a regular pulsing bar or wave on your pulse ox screen</li>
                            </S.List>
                            <S.Paragraph>For your safety, please do not begin exercising until your <b>SpO2 is above {sp02Target}%.</b></S.Paragraph>
                        </>
                        )}
                        {(warningType === ExerciseTypes.WarningType.BPDIASTOLIC_HIGH || warningType === ExerciseTypes.WarningType.BPSYSTOLIC_HIGH) && (
                        <>
                            <S.Paragraph>Hey it looks like your <b>blood pressure is reading high for rest.</b></S.Paragraph>
                            <S.Paragraph>
                                Take time to sit and rest and check again in 5 minutes. For your safety, please do not begin exercise unless your <b>BP is lower than 180/100mmHg</b>
                            </S.Paragraph>
                            <S.Paragraph>If your high BP persists despite rest, please call your doctor.</S.Paragraph>
                            <S.Paragraph>If your <b>BP is above 200/110mmHG</b> call 9-1-1 as this is a medical emergency even if you don&apos;t have symptoms.</S.Paragraph>
                        </>
                        )}
                        {(warningType === ExerciseTypes.WarningType.BPDIASTOLIC_LOW || warningType === ExerciseTypes.WarningType.BPSYSTOLIC_LOW) && (
                        <>
                            <S.Paragraph>Hey it looks like your <b>blood pressure is reading low.</b></S.Paragraph>
                            <S.Paragraph>
                                Take time to sit and rest and check again in 5 minutes. For your safety, please do not begin exercise unless your BP is <b>higher than 90/60mmHg</b> and you are not feeling light headed.
                            </S.Paragraph>
                            <S.Paragraph>If your low BP persists or you feel unwell and dizzy, please call 9-1-1 and seek help.</S.Paragraph>
                        </>
                        )}
                        {warningType === ExerciseTypes.WarningType.MULTIPLE && (
                        <>
                            <S.Paragraph>
                                Hey, it looks like your resting vitals are out of a safe range to begin exercise. Please take a seat and rest and check again in 5 minutes.
                            </S.Paragraph>
                            <S.Paragraph>
                                For your safety, do not begin exercise unless your <b>HR is between 50-110bpm, SpO2 is above {sp02Target}% and BP is above 90/60 but below 179/100.</b>
                            </S.Paragraph>
                            <S.Paragraph>If your vitals persist outside this range, please call your doctor or call 9-1-1 if you feel this is an emergency.</S.Paragraph>
                        </>
                        )}
                    </S.WarningBody>
                    <S.ButtonContainer>
                        <S.BlueButton
                          onClick={() => {
                            openBaselineModal();
                            Mixpanel.track('web_pre_exercise_baseline_warning_take_measurements_again');
                          }}
                        >
                            Take measurements again
                        </S.BlueButton>
                    </S.ButtonContainer>
                </>
                )}
                {!!proceedToExercise && (
                <>
                    <S.WarningBody>
                        {warningType === ExerciseTypes.WarningType.HEARTRATE_LOW && (
                        <>
                            <S.Paragraph>Hey, it looks like your <b>heart rate is reading too low to continue exercising.</b></S.Paragraph>
                            <S.Paragraph>Take time to sit and rest, and check again in 5 minutes.</S.Paragraph>
                            <S.Paragraph>
                                For your safety, please do not continue exercising until your <b>HR is higher than 50bpm.</b> If you&apos;re feeling faint, dizzy or unwell with a low HR please call 9-1-1.
                            </S.Paragraph>
                        </>
                        )}
                        {warningType === ExerciseTypes.WarningType.HEARTRATE_HIGH && (
                        <>
                            <S.Paragraph>Hey, it looks like your <b>heart rate is reading too high to continue exercising.</b></S.Paragraph>
                            <S.Paragraph>
                                Take time to sit and rest, and check again in 5 minutes. For your safety, do not continue exercising unless your <b>HR recovers to below 150bpm.</b>
                            </S.Paragraph>
                            <S.Paragraph>
                                If you&apos;re feeling unwell with a persistent high HR reading, please contact your doctor or call 9-1-1 if you feel this is an emergency.
                            </S.Paragraph>
                        </>
                        )}
                        {warningType === ExerciseTypes.WarningType.SPO2 && (
                        <>
                            <S.Paragraph>Hey, it looks like your oxygen saturation is reading low.</S.Paragraph>
                            <S.Paragraph>You may see normal values during exercise. But once you&apos;ve stopped moving, your SpO2 may gradually drop temporarily, followed by recovery</S.Paragraph>
                            <S.Paragraph>
                                When this happens, <b>continue to sit and rest while practicing your pursed lip breathing.</b> Allow yourself enough time to fully recover your SpO2 above {sp02Target}% before moving on.
                            </S.Paragraph>
                            <S.Paragraph>Once your <b>SpO2 is above {sp02Target}%</b> click the button below to move to the next exercise. You&apos;ve got this!</S.Paragraph>
                        </>
                        )}
                        {warningType === ExerciseTypes.WarningType.MULTIPLE && (
                        <>
                            <S.Paragraph>Hey, it looks like your resting vitals are out of a safe range to begin exercise. Please take a seat and rest and check again in 5 minutes</S.Paragraph>
                            <S.Paragraph>
                                For your safety, do not continue exercise unless your HR is between <b>50 - 150bpm</b> and <b>SpO2 is above {sp02Target}%.</b> Extra recovery time is needed at times and that&apos;s okay.
                            </S.Paragraph>
                            <S.Paragraph>
                                If your vitals persist outside this range despite seated rest, please call your doctor or call 9-1-1 if you feel this is an emergency.
                            </S.Paragraph>
                        </>
                        )}
                    </S.WarningBody>
                    <S.ButtonContainer>
                        <S.BlueButton onClick={() => proceedToExercise()}>{getButtonText()}</S.BlueButton>
                    </S.ButtonContainer>
                </>
                )}
            </S.ModalContent>
        </Modal>
    );
};

export default BaselineWarning;
