import { Auth } from 'aws-amplify';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useActionLoader } from '../../../hooks/useActionLoader';
import { loginUser } from '../../../state/reducers/session/actions';
import { Spinner, theme } from '@apps/common-ui';
import Form from '../../../components/common/Form';
import * as S from '../index.styles';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Mixpanel from '../../../services/Mixpanel';
import { useLoginRedirect } from '../../../hooks/useLoginRedirect';

function LoginForm() {
    const { t } = useTranslation();
    const { callAction: setUser, loading: loginLoading } = useActionLoader(loginUser);
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const { loggedIn } = useSelector((state: RootState) => state.session);
    const { handleRedirect } = useLoginRedirect();
    const [searchParams] = useSearchParams();
    const [allowSignup, setAllowSignup] = useState(false);
    const [submittable, setSubmittable] = useState(false);
    const passwordRef = useRef<HTMLInputElement | null>(null);

    const width = window.innerWidth;
    const breakpoint = Number(theme.screen.small.split('px')[0]);

    const navigate = useNavigate();

    const shouldAllowSignup = () => {
        const from = searchParams.get('from');
        if (!from || from === 'email') {
            setAllowSignup(true);
        } else if (from === 'breathesuite.com') {
            setAllowSignup(false);
        }
    };

    useEffect(() => {
        // If a redirect URL is specified, use that instead of the default
        if (loggedIn) {
            setLoading(true);
            handleRedirect();
        }
    }, [loggedIn]);

    // Show loading while login is in progress
    useEffect(() => {
        if (loginLoading) {
            setLoading(true);
        }
    }, [loginLoading]);

    // For Mixpanel
    useEffect(() => {
        Mixpanel.track('web_login_page');
    }, []);

    useEffect(() => {
        shouldAllowSignup();
    }, []);

    const schema = yup.object({
        email: yup.string().trim().email(t('login.error.emailIsInvalid')).required(t('login.error.emailRequired')),
        password: yup.string().required(t('login.error.passwordRequired')),
    }).required();

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const { ref, ...rest } = register('password');

    useEffect(() => {
        const formSub = watch((value) => {
            setSubmittable(!!value.email && !!value.password);
        });
        return () => formSub.unsubscribe();
    }, [watch]);

    useLayoutEffect(() => {
        // Autofill doesn't trigger change events because the field values aren't set until the user
        // clicks on the page.
        // Browsers that use webkit apply this to autofilled fields, if present assume that autofill has occured.
        // Firefox's autofill does trigger change events by default.
        const checkIfAutofilled = () => {
            const autofilled = passwordRef.current?.matches('*:-webkit-autofill');
            if (autofilled) {
                setSubmittable(true);
            }
        };

        // time for autofill can vary so add a few checks at different intervals
        const timeout1 = setTimeout(checkIfAutofilled, 500);
        const timeout2 = setTimeout(checkIfAutofilled, 1000);
        const timeout3 = setTimeout(checkIfAutofilled, 2000);

        return () => {
            clearTimeout(timeout1);
            clearTimeout(timeout2);
            clearTimeout(timeout3);
        };
    }, []);

    const login = async (data: any) => {
        setError('');
        const { email, password } = data;
        const loweredEmail = email.toLowerCase();
        const result = await Auth.signIn(loweredEmail, password).catch(err => {
            switch (err.code) {
                case 'UserNotConfirmedException':
                    setError(t('login.error.userNotConfirmed'));
                    break;
                case 'NotAuthorizedException':
                case 'UserNotFoundException':
                case 'InvalidParameterException':
                    setError(t('login.error.invalidCredentials'));
                    break;
                default:
                    setError(t('login.error.unknownError'));
                    break;
            }
        });
        if (result) {
            setUser({
                user: {
                    email: loweredEmail
                },
            });
        }
    };

    return (
        <S.LoginContainer>
            <S.TopBar>
                {width >= breakpoint && <S.TopBarLogo src="/BlueLogoNoBackground.svg" />}
                {width < breakpoint && <S.TopBarLogo src="/MobileLogoNoTextOrBackground.svg" />}
                <S.TopBarButtonsGroup>
                    {allowSignup && <S.TopBarButton onClick={() => navigate('/sign-up')}>Sign Up</S.TopBarButton>}
                    <S.TopBarButton
                      onClick={() => navigate('/support', { state: { previousPage: '/login' } })}
                    >Get Help
                    </S.TopBarButton>
                </S.TopBarButtonsGroup>
            </S.TopBar>
            <S.LoginFormContainer>
                <S.FormTitle>Login</S.FormTitle>
                <Form onSubmit={handleSubmit(login)}>
                    <S.AuthInputContainer>
                        <S.LabelContainer>
                            <S.LoginLabel htmlFor="email">{`${t('login.email')}`}</S.LoginLabel>
                        </S.LabelContainer>
                        <S.LoginInput
                          {...register('email')}
                          id="email"
                          autoCapitalize="none"
                        />
                        {errors.email && <S.Message type="error">{errors.email.message}</S.Message>}
                    </S.AuthInputContainer>
                    <S.AuthInputContainer>
                        <S.LabelContainer>
                            <S.LoginLabel htmlFor="password">{`${t('login.password')}`}</S.LoginLabel>
                            <S.LabelLink to="/reset-password">Forgot Password?</S.LabelLink>
                        </S.LabelContainer>
                        <S.PasswordWithButtonContainer justifyContent="center">
                            <S.LoginInput
                              {...rest}
                              type={showPassword ? 'text' : 'password'}
                              id="password"
                              ref={(e) => {
                                // share the ref with the form hook register ref and the password ref that checks for autofill
                                ref(e);
                                passwordRef.current = e;
                              }}
                            />
                            <S.PasswordButton
                              type="button"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                                {!showPassword ? 'SHOW' : 'HIDE'}
                            </S.PasswordButton>
                        </S.PasswordWithButtonContainer>
                        {errors.password && <S.Message type="error">{errors.password.message}</S.Message>}
                    </S.AuthInputContainer>
                    {error && <S.Message type="error">{error}</S.Message>}
                    <S.SubmitButton
                      type="submit"
                      disabled={loading}
                      submittable={submittable}
                    >
                        {loading
                            ? <Spinner small />
                            : t('login.loginButton')}
                    </S.SubmitButton>
                    <S.MobileForgotPassword to="/reset-password">Forgot Password?</S.MobileForgotPassword>
                </Form>
            </S.LoginFormContainer>
        </S.LoginContainer>
    );
}

export default LoginForm;
