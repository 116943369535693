import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../state/store';
import { useActionLoader } from '../hooks/useActionLoader';
import { loginUser } from '../state/reducers/session/actions';
import usePageAnalytics from '../hooks/usePageAnalytics';
import useInitializeApp from '../hooks/useInitializeApp';
import { Spinner } from '@apps/common-ui';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ModalRouter from './ModalRouter';
import AppRouter from './AppRouter';
import LoginForm from '../pages/Login/LoginForm';
import SimpleSignUpForm from '../pages/Login/SimpleSignUpForm';
import ForgotPassword from '../pages/Login/ForgotPasswordForm';
import useSmartlook from '../hooks/useSmartlook';

const RouterContainer = () => {
    const { loggedIn, tenantUser, user } = useSelector((state: RootState) => state.session);
    const { exercisePlan, course, actionPlan } = useSelector((state: RootState) => state.coaching);
    const { callAction: login, done, loading } = useActionLoader(loginUser, { hideToast: true });
    const { forms } = useSelector((state: RootState) => state.coaching);

    useInitializeApp();
    useSmartlook(user?.id);

    useEffect(() => {
        if (!done && !loading) {
            login();
        }
    }, [done, loading, login]);

    if (!done) {
        return <Spinner />;
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route
                  path="/modal/*"
                  element={(
                      <div className="app-container">
                          <ModalRouter />
                      </div>
                    )}
                />
                <Route
                  path="/login/*"
                  element={(
                      <div className="app-container">
                          <main className="main-content-modal">
                              <LoginForm />
                          </main>
                      </div>
                  )}
                />
                <Route
                  path="/sign-up"
                  element={(
                      <div className="app-container">
                          <main className="main-content-modal">
                              <SimpleSignUpForm />
                          </main>
                      </div>
                  )}
                />
                <Route
                  path="/reset-password"
                  element={(
                      <div className="app-container">
                          <main className="main-content-modal">
                              <ForgotPassword />
                          </main>
                      </div>
                    )}
                />
                <Route
                  path="*"
                  element={(
                      <div className="app-container">
                          <AppRouter
                            exercisePlan={exercisePlan}
                            course={course}
                            forms={forms}
                            actionPlan={actionPlan}
                            tenantUser={tenantUser}
                            loggedIn={loggedIn}
                          />
                      </div>
                )}
                />
            </Routes>
        </BrowserRouter>
    );
};

export default RouterContainer;
