import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  iframe {
    min-width: 700px;
    width: 100%;
    min-height: 450px;
  }

  @media (max-width: ${props => props.theme.screen.small}) {
    iframe {
      min-width: 100%;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;
