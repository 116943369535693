import { Spinner } from '@apps/common-ui';
import { LearningTypes } from '@apps/common-utilities';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import NotFound from '../../../components/common/NotFound';
import { useActionLoader } from '../../../hooks/useActionLoader';
import Mixpanel from '../../../services/Mixpanel';
import { fetchCourse } from '../../../state/reducers/coaching/actions';
import { RootState } from '../../../state/store';
import * as S from './index.styles';
import { TenantApi } from '../../../api/UsersApi';
import QuizAnswers from './QuizAnswers';
import { format } from 'date-fns';

const Quiz = () => {
    const [quiz, setQuiz] = useState<LearningTypes.IUserQuiz>();
    const [quizAnswers, setQuizAnswers] = useState<LearningTypes.IQuizSubmission | null>(null);
    const [quizFetched, setQuizFetched] = useState(false);
    const [alreadySubmitted, setAlreadySubmitted] = useState(false);
    const [loading, setLoading] = useState(true);

    const { tenantUser } = useSelector((state: RootState) => state.session);
    const { course } = useSelector((state: RootState) => state.coaching);
    const { moduleId } = useParams<{moduleId: string}>();
    const { id } = useParams<{id: string}>();
    const { completed } = useParams<{completed: string}>();
    const { score } = useParams<{score: string}>();

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    // current questions selected answers
    const [selectedAnswers, setSelectedAnswers] = useState<number[]>([]);
    // all questions selected answers
    const [savedAnswers, setSavedAnswers] = useState<{answerIndex: number, answerId: number}[][]>([]);
    const [showResults, setShowResults] = useState(false);
    const [quizScore, setQuizScore] = useState(-1);
    const [completedDate, setCompletedDate] = useState('');
    const [showQuizAnswers, setShowQuizAnswers] = useState(false);

    const { callAction: refreshCourse, done: courseRefreshDone } = useActionLoader(fetchCourse);

    const navigate = useNavigate();

    const getQuizData = () => {
        if (!tenantUser) {
            return;
        }

        TenantApi.get(`/users/${tenantUser.id}/courses/assigned/quizzes/${id}`).then((res: LearningTypes.IUserQuiz) => {
            setQuiz(res);
            setQuizFetched(true);
        }).finally(() => {
            setLoading(false);
        });
    };

    const saveAnswer = () => {
        const temp = savedAnswers.slice();
        temp[currentQuestionIndex] = [];
        selectedAnswers.forEach((answerIndex) => {
            temp[currentQuestionIndex].push({
                // could probably remove this answerText if it doesn't end up being used outside of testing
                answerIndex,
                answerId: quiz ? quiz.questions[currentQuestionIndex].answers[answerIndex].id : -1
            });
        });
        setSavedAnswers(temp);
    };

    const selectAnswer = (answerIndex: number) => {
        if (!quiz) {
            return;
        }
        if (!quiz.questions[currentQuestionIndex].multAnswer) {
            setSelectedAnswers([answerIndex]);
        } else if (quiz.questions[currentQuestionIndex].multAnswer) {
            if (selectedAnswers.includes(answerIndex)) {
                setSelectedAnswers(selectedAnswers.filter((index) => index !== answerIndex));
            } else {
                setSelectedAnswers([...selectedAnswers, answerIndex]);
            }
        }
    };

    const getQuizReport = () => {
        if (!tenantUser || !quiz) {
            return;
        }

        setLoading(true);
        TenantApi.get(`/users/${tenantUser.id}/courses/assigned/quizzes/${quiz.id}/report`).then((q) => {
            setQuizAnswers(q);
            const formattedDate = format(new Date(q.completedAt), 'MMMM d, yyyy');
            setCompletedDate(formattedDate);
        }).finally(() => {
            setLoading(false);
        });
    };

    const onSubmit = () => {
        if (!tenantUser || !quiz) {
            return;
        }
        const quizResponses: LearningTypes.IUserQuizQuestionResponse[] = [];

        for (let i = 0; i < savedAnswers.length; i += 1) {
            const questionId = quiz.questions[i].id;
            const answerIds = savedAnswers[i].map((answer) => answer.answerId);
            quizResponses.push({ questionId, answerIds });
        }

        const submission: LearningTypes.IUserQuizSubmission = {
            quizId: quiz.id,
            completedAt: new Date(),
            responses: quizResponses
        };

        TenantApi.post(`/users/${tenantUser.id}/courses/quizzes/completions`, submission).then((res: any) => {
            setQuizScore(res.quizScorePercentage);
            refreshCourse({ userId: tenantUser.id });
            getQuizReport();
        }).then(() => setShowResults(true));
    };

    const onContinue = () => {
        if (selectedAnswers.length === 0 || !quiz) {
            return;
        }

        if (currentQuestionIndex < quiz.questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            // the user already answered this question and is going back to it
            // so we need to set the selected answers to the ones they already selected
            if (currentQuestionIndex + 1 < savedAnswers.length) {
                let newAnswers: number[] = [];
                savedAnswers[currentQuestionIndex + 1].forEach((answer, index) => {
                    newAnswers = [...newAnswers, answer.answerIndex];
                });
                setSelectedAnswers(newAnswers);
            } else {
                // the user is going to a new question
                // so we need to clear the selected answers
                setSelectedAnswers([]);
            }
        } else {
            onSubmit();
        }
    };

    const onPrevious = () => {
        const lastQuestionAnswers: number[] = [];
        const lastQuestionIndex = currentQuestionIndex - 1;
        savedAnswers[lastQuestionIndex].forEach((answer, index) => {
            lastQuestionAnswers.push(answer.answerIndex);
        });
        setSelectedAnswers(lastQuestionAnswers);
        setCurrentQuestionIndex(lastQuestionIndex);
    };

    useEffect(() => {
        if (selectedAnswers.length > 0) {
            saveAnswer();
        }
    }, [selectedAnswers]);

    useEffect(() => {
        getQuizData();
    }, [tenantUser, course]);

    useEffect(() => {
        setAlreadySubmitted(completed === 'true');
    }, [completed]);

    useEffect(() => {
        if (Number(score) >= 0) {
            setQuizScore(Number(score));
        }
    }, [score]);

    useEffect(() => {
        if (quiz?.id && alreadySubmitted) {
            getQuizReport();
        }
    }, [quiz, alreadySubmitted]);

    const onBackClick = () => {
        Mixpanel.track('web_lesson_page_back_button');
        navigate(-1);
    };

    const moduleNumber = course ? course.modules.findIndex((module) => module.id === Number(moduleId)) + 1 : -1;

    if (loading || moduleNumber === -1) {
        return <Spinner />;
    }

    if (!quiz && quizFetched) {
        return <NotFound errorMessage="Quiz not found" />;
    }

    return (
        <S.Container>
            <S.TitleBar>
                <S.BackButton onClick={onBackClick}> <FontAwesomeIcon icon={faChevronLeft as IconProp} /> Back</S.BackButton>
            </S.TitleBar>
            <S.PageContent showQuizAnswers={showQuizAnswers}>
                <S.IndexContainer>
                    <S.QuizIndexContainer showQuizAnswers={showQuizAnswers}>
                        <S.ModuleLink
                          showQuizAnswers={showQuizAnswers}
                          onClick={() => Mixpanel.track('web_quiz_page_module_link')}
                          to={`/learning/modules/${moduleId}`}
                        >Module {moduleNumber}
                        </S.ModuleLink>
                        {showQuizAnswers
                        ? (
                            <S.DateLabel>
                                Completed {completedDate}
                            </S.DateLabel>
                          )
                        : <S.QuizLabel>Quiz</S.QuizLabel>}
                    </S.QuizIndexContainer>
                    {showQuizAnswers && (
                    <div>
                        <h2>{quizAnswers?.title}</h2>
                        <div style={{ display: 'flex' }}>
                            <S.ScoreTitle>Your score</S.ScoreTitle>
                            <S.NumericScore>{quizAnswers?.score}</S.NumericScore>
                        </div>
                        <S.CompleteButtonContainer>
                            <S.Score>{quizScore}%</S.Score>
                            <S.CompleteButton
                              onClick={() => {
                                Mixpanel.track('web_quiz_page_complete_button');
                                navigate(`/learning/modules/${moduleId}`);
                              }}
                            >
                                Continue
                            </S.CompleteButton>
                        </S.CompleteButtonContainer>
                    </div>
                )}
                </S.IndexContainer>
                {!showResults && !alreadySubmitted && (
                <S.QuestionContainer>
                    <S.QuestionIndex>Question {currentQuestionIndex + 1} of {quiz?.questions.length}</S.QuestionIndex>
                    <S.QuestionText dangerouslySetInnerHTML={{ __html: quiz ? quiz.questions[currentQuestionIndex].question : '' }} />
                    {!quiz?.questions[currentQuestionIndex].multAnswer
                        ? <S.QuestionInstructions>Choose only ONE best answer.</S.QuestionInstructions>
                        : <S.QuestionInstructions>Choose ALL correct answers.</S.QuestionInstructions>}
                    <S.AnswerContainer>
                        {!!quiz?.questions[currentQuestionIndex].answers.length && quiz.questions[currentQuestionIndex].answers.map((answer, index) => (
                            <S.Answer
                              isSelected={selectedAnswers.includes(index)}
                              onClick={() => {
                                Mixpanel.track('web_quiz_page_answer_selected');
                                selectAnswer(index);
                            }}
                            >
                                <S.AnswerText isSelected={selectedAnswers.includes(index)}>{answer.answer}</S.AnswerText>
                            </S.Answer>
                        ))}
                    </S.AnswerContainer>
                    <S.ButtonContainer>
                        <S.ContinueButton
                          answerSelected={selectedAnswers.length > 0}
                          onClick={() => {
                            Mixpanel.track('web_quiz_page_continue_button');
                            onContinue();
                          }}
                        >
                            {quiz && currentQuestionIndex === quiz.questions.length - 1 ? 'Complete' : 'Continue'}
                        </S.ContinueButton>
                        {currentQuestionIndex > 0
                            && (
                            <S.PreviousButton
                              onClick={() => {
                                Mixpanel.track('web_quiz_page_previous_question_button');
                                onPrevious();
                              }}
                            >
                                Previous Question
                            </S.PreviousButton>
                        )}
                    </S.ButtonContainer>
                </S.QuestionContainer>
                )}
                {(showResults || alreadySubmitted) && (
                    <S.QuizCompleteContainer>
                        {!showQuizAnswers && (
                        <div>
                            <S.QuizCompleteText>Quiz Complete</S.QuizCompleteText>
                            <S.ScoreContainer>
                                <div style={{ display: 'flex' }}>
                                    <S.ScoreTitle>Your score</S.ScoreTitle>
                                    <S.NumericScore>{quizAnswers?.score}</S.NumericScore>
                                </div>
                                <S.Score>{quizScore}%</S.Score>
                            </S.ScoreContainer>
                            <S.CompleteButton
                              onClick={() => {
                            Mixpanel.track('web_quiz_page_complete_button');
                            navigate(`/learning/modules/${moduleId}`);
                          }}
                            >
                                {alreadySubmitted ? 'Continue' : 'Complete & Continue'}
                            </S.CompleteButton>
                            <S.ViewAnswerButton
                              buttonType="tertiary"
                              onClick={() => setShowQuizAnswers(true)}
                            >
                                View Correct Answers
                            </S.ViewAnswerButton>
                        </div>
                        )}
                        {showQuizAnswers && <QuizAnswers quizAnswers={quizAnswers} />}
                    </S.QuizCompleteContainer>
                )}
            </S.PageContent>
        </S.Container>
    );
};

export default Quiz;
