import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

interface CardProps {
    isCurrentDay: boolean
}

export const CardBody = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
`;

export const ExercisePreviewInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;

export const CardDate = styled.p`
    margin-bottom: 0px;
`;

export const ExerciseCount = styled.p`
    font-weight: bold;
    margin-top: 8px;
`;

export const ViewButton = styled(Buttons.LinkButton)<CardProps>`
    width: 100px;
    color: ${props => props.isCurrentDay ? props.theme.text.white : props.theme.buttons.blue};
    background: ${props => props.isCurrentDay ? props.theme.buttons.blue : props.theme.shades.white};
    border-width: 2px;
    border-style: solid;
    border-color: ${props => props.theme.buttons.blue};
    text-align: center;
`;

export const Completed = styled.p`
    color: ${props => props.theme.success.medium};
    font-weight: bold;
    margin-left: 0px;
`;

export const NotCompleted = styled(Completed)`
    color: ${props => props.theme.shades.grey};
    margin-left: 0px;
`;
