import { Buttons, theme } from '@apps/common-ui';
import styled from 'styled-components';

export const ModalContent = styled.div`
    min-width: 700px;

    @media (max-width: ${props => props.theme.screen.medium}) {
        min-width: 100vw;
        min-height: 100vh;
    }
`;

export const TopBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 140px;
    background: #DBECF5;
    border-radius: 16px 16px 0px 0px;
    padding-left: 30px;
    margin-bottom: 30px;
`;

export const TitleAndIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 70%;
`;

export const Title = styled.p`
    font-size: 21px;
    font-weight: bold;
    color: ${props => props.theme.text.blue};
    margin-left: 20px;
`;

export const CloseContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    height: 100%;
`;

export const CloseButton = styled(Buttons.Button)`
    display: flex;
    flex-direction: row;
    justify-content flex-start;
    align-items: flex-start;
    background: #DBECF5;
    padding-bottom: 0px;
    margin-bottom: 0px;

    border-color: #DBECF5;

    p {
        margin-top: 0px;
        margin-bottom: 0px;
        color: ${props => props.theme.primary.medium}
    }

    svg {
        margin-top: 6px;
        color: ${props => props.theme.primary.medium}
    }
`;

export const IconContainer = styled.div`

`;

export const WarningIcon = styled.img`
`;

export const WarningBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
`;

export const Paragraph = styled.p`
    margin-bottom: 0px;
    b {
        color: ${props => props.theme.primary.medium}
    }
`;

export const List = styled.ul`
    padding-left: 25px;
    margin-top: 10px;
    li {

    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-left: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
`;

export const BlueButton = styled(Buttons.Button)`
    color: ${props => props.theme.text.white};
    background: ${props => props.theme.buttons.blue};
    margin-left: 0px;
`;
