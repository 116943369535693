import { Buttons, Inputs } from '@apps/common-ui';
import styled from 'styled-components';

export const ModalContent = styled.div`
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    @media (max-width: ${props => props.theme.screen.medium}) {
        min-width: 85vw;
        max-width: 100vw;
    }
`;

export const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
    @media (max-width: ${props => props.theme.screen.medium}) {
        flex-direction: column-reverse;
        align-items: flex-end;
        text-align: center;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 

    @media (max-width: ${props => props.theme.screen.medium}) {
        margin: 0 5px;
        text-align: center;
        width: 100%; 
    }
`;

export const Title = styled.h2`
    margin-top: 10px;
     @media (max-width: ${props => props.theme.screen.medium}) {
        margin-top: 5px;
    }
`;

export const CloseButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: ${props => props.theme.screen.medium}) {
        margin-right: 30px;
        align-items: flex-start;
        align-self: flex-end;
    }
`;

export const CloseButton = styled(Buttons.Button)`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: ${props => props.theme.shades.white};
    border-color: ${props => props.theme.shades.white};
    margin-top: 0px;
    margin-right: 0px;
    padding-right: 0px;

    p {
        margin-top: 0px;
        margin-bottom: 10px;
        color: ${props => props.theme.primary.medium}
    }

    svg {
        margin-top: 6px;
        color: ${props => props.theme.primary.medium}
    }

`;

export const Message = styled.p`
    margin-top: 0px;
    
`;

export const CompletedDateContainer = styled.div`
    display: flex;
    align-items: center; 
    width: 100%;
    @media (max-width: ${props => props.theme.screen.medium}) {
        align-items: start; 
        flex-direction: column;
    }
`;

export const CompletedDateLabel = styled.p`
    background: white;
    font-weight: bold;
    margin: 0;
    margin-right: 5px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-top: 5px;

    @media (max-width: ${props => props.theme.screen.medium}) {
        justify-content: center;
    }
`;

export const VitalsButton = styled(Buttons.Button)`

`;
