import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    padding: 20px;
    border: 1px solid ${props => props.theme.shades.darkOrange};
    background: ${props => props.theme.shades.orange};
    border-radius: 5px;
    margin-top: 10px;
    
    @media (max-width: ${props => props.theme.screen.medium}) {
        align-items: center;    
        width: 100%;
    }
`;

export const Message = styled.label`
    display: flex;
    align-content: flex-start;
    padding: 3px;
    margin: 0 0 5px 0;
`;

export const Button = styled(Buttons.Button)`
    text-align: center;
    font-size: 14px;
    width: 240px;
    padding: 5px 8px;
    margin: 3px 0 0 0;
`;
