var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from '../index.styles';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { formatPhoneNumberForDisplay } from '../utlils';
var SupportCall = function (_a) {
    var phoneNumber = _a.phoneNumber, supportImage = _a.supportImage;
    return (_jsxs(S.SupportCard, { children: [_jsxs(S.HeadingContainer, { children: [_jsx(S.SupportImg, { src: supportImage, alt: "Call" }), _jsx(S.SubHeader, { children: "Phone Call" })] }), _jsxs(S.DesktopText, { children: ["Call ", formatPhoneNumberForDisplay(phoneNumber)] }), _jsxs(S.DesktopCallInfo, { children: ["If we are not available we will", _jsx("br", {}), "respond to your voicemail as soon", _jsx("br", {}), "as possible"] }), _jsx(S.MobileText, { children: formatPhoneNumberForDisplay(phoneNumber) }), _jsxs(S.MobileButton, __assign({ href: "tel:+".concat(phoneNumber) }, { children: [_jsx(S.ButtonIcon, { icon: faPhone }), "Call our Support Team"] }))] }));
};
export default SupportCall;
