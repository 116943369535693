import styled from 'styled-components';

export const Container = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Header = styled.h2`
    padding: 0 20px;
    text-align: center;
`;

export const Text = styled.p`
    max-width: 600px;
    text-wrap: wrap;
    text-align: center;
    font-size: 0.9rem;
    margin: 0 0 30px 0;
`;

export const Error = styled(Text)`
    color: ${props => props.theme.text.error};
    margin-top: 30px;
    font-size: 1rem;
`;
