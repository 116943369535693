import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from '../../../components/common/Form';
import * as S from '../index.styles';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { InputSize } from '@apps/common-ui/src/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { theme } from '@apps/common-ui';

function ForgotPassword() {
    const { t } = useTranslation();
    const [codeSent, setCodeSent] = useState(false);
    const [codeSubmitted, setCodeSubmitted] = useState(false);
    const { loggedIn, user } = useSelector((state: RootState) => state.session);
    const [email, setEmail] = useState(user?.email || '');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirm, setShowConfirm] = useState(false);
    const [message, setMessage] = useState({ text: '', type: 'error' });
    const navigate = useNavigate();
    const [emailSubmittable, setEmailSubmittable] = useState(false);
    const [formTitle, setFormTitle] = useState('');
    const [formSubtitle, setFormSubtitle] = useState('');
    const [digitArray, setDigitArray] = useState(['', '', '', '', '', '']);
    const singleDigitStrings = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    const width = window.innerWidth;
    const breakpoint = Number(theme.screen.small.split('px')[0]);

    const schema = yup.object({
        code0: yup.string().required().max(1).matches(/^\d+$/),
        code1: yup.string().required().max(1).matches(/^\d+$/),
        code2: yup.string().required().max(1).matches(/^\d+$/),
        code3: yup.string().required().max(1).matches(/^\d+$/),
        code4: yup.string().required().max(1).matches(/^\d+$/),
        code5: yup.string().required().max(1).matches(/^\d+$/)
    }).required('Please enter all 6 digits of the code');

    const { register, formState: { errors, isValid }, getValues } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        setEmailSubmittable(!!email);
    }, [email]);

    useEffect(() => {
        if (!codeSent && !codeSubmitted) {
            setFormTitle('Reset Password');
            setFormSubtitle('Please enter the email associated with your account.');
        } else if (codeSent && !codeSubmitted) {
            setFormTitle('Enter Code');
            setFormSubtitle(`A 6 digit code has been sent to ${email}`);
        } else if (codeSent && codeSubmitted) {
            setFormTitle('Create a New Password');
            setFormSubtitle('Create a secure password with at least 10 letters or numbers.');
        }
    }, [codeSent, codeSubmitted]);

    const codeChanged = (index: number) => {
        const temp = digitArray.slice();
        temp[index] = getValues(`code${index}`);
        setDigitArray(temp);
    };

    const focusOnNextInput = (index: number) => {
        if (index < 5) {
            const element = document.getElementById(`code${index + 1}`);
            element?.focus();
        } else if (index === 5) {
            const element = document.getElementById('submitCode');
            element?.focus();
        }
    };

    const resendCode = async (event: { preventDefault: () => void}) => {
        const loweredEmail = email.toLowerCase();
        const result = await Auth.forgotPassword(loweredEmail).catch(err => {
            switch (err.code) {
                case 'UserNotFoundException':
                    setMessage({ text: t('login.error.userNotFound'), type: 'error' });
                    break;
                default:
                    setMessage({ text: t('login.error.unknownError'), type: 'error' });
                    break;
            }
        });
        if (result) {
            setMessage({ text: '', type: 'success' });
            toast.success(t('login.success.codeSentToEmail'), { duration: 8000 });
            setCodeSent(true);
        }
    };

    const forgotPassword = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        const loweredEmail = email.toLowerCase();
        if (!codeSent && !codeSubmitted) {
            if (email.length === 0) {
                setMessage({ text: t('login.error.emailRequired'), type: 'error' });
                return;
            }
            const result = await Auth.forgotPassword(loweredEmail).catch(err => {
                switch (err.code) {
                    case 'UserNotFoundException':
                        setMessage({ text: t('login.error.userNotFound'), type: 'error' });
                        break;
                    default:
                        setMessage({ text: t('login.error.unknownError'), type: 'error' });
                        break;
                }
            });
            if (result) {
                setMessage({ text: '', type: 'success' });
                toast.success(t('login.success.codeSentToEmail'), { duration: 8000 });
                setCodeSent(true);
            }
        } else if (codeSent && !codeSubmitted) {
            if (!isValid) {
                setMessage({ text: 'Please enter the 6 digit code sent to your email.', type: 'error' });
            } else if (isValid) {
                setMessage({ text: '', type: '' });
                setCodeSubmitted(true);
            }
        } else if (codeSent && codeSubmitted) {
            if (password.length === 0) {
                setMessage({ text: t('login.error.passwordRequired'), type: 'error' });
            }
            if (password !== confirmPassword) {
                setMessage({ text: t('login.error.passwordMismatch'), type: 'error' });
                return;
            }
            if (password.length < 10) {
                setMessage({ text: t('login.error.passwordTooShort'), type: 'error' });
                return;
            }
            let code = '';
            for (let i = 0; i < digitArray.length; i += 1) {
                code += digitArray[i];
            }
            const result = await Auth.forgotPasswordSubmit(loweredEmail, code, password).catch(err => {
                setCodeSubmitted(false);
                setMessage({ text: 'The 6 digit code you entered is incorrect. Please re-enter the code or resend a new code and try again.', type: 'error' });
            });
            if (result) {
                setMessage({ text: t('login.success.passwordChanged'), type: 'success' });
                toast.success(t('login.success.passwordChanged'));
                navigate('/login');
            }
        }
    };

    return (
        <S.LoginContainer>
            <S.TopBar>
                {width >= breakpoint && <S.TopBarLogo src="/BlueLogoNoBackground.svg" />}
                {width < breakpoint && <S.TopBarLogo src="/MobileLogoNoTextOrBackground.svg" />}
                <S.TopBarButtonsGroup>
                    <S.TopBarButton onClick={() => navigate('/login')}>Login</S.TopBarButton>
                    <S.TopBarButton
                      onClick={() => navigate('/support', { state: { previousPage: '/reset-password' } })}
                    >Get Help
                    </S.TopBarButton>
                </S.TopBarButtonsGroup>
            </S.TopBar>
            <S.LoginFormContainer>
                <S.FormTitle>{formTitle}</S.FormTitle>
                <S.FormSubtitle hasButtonUnderneath>{formSubtitle}</S.FormSubtitle>
                {codeSent && !codeSubmitted
                    && (
                    <S.ChangeEmailButton
                      onClick={() => {
                            setMessage({ text: '', type: '' });
                            setCodeSent(false);
                        }}
                    >
                        Change Email
                    </S.ChangeEmailButton>
                    )}
                <Form>
                    {!codeSent && (
                    <S.AuthInputContainer>
                        <S.LabelContainer>
                            <S.LoginLabel>{`${t('login.email')}`}</S.LoginLabel>
                        </S.LabelContainer>
                        <S.LoginInput
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          name="email"
                          type="email"
                        />
                        <S.SubmitButton
                          submittable={emailSubmittable}
                          onClick={forgotPassword}
                        >
                            Next
                        </S.SubmitButton>
                    </S.AuthInputContainer>
                    )}
                    {codeSent && !codeSubmitted && (
                    <>
                        <S.AuthInputContainer>
                            <S.LabelContainer>
                                <S.LoginLabel>{`${t('login.code')}`}</S.LoginLabel>
                                <S.ResendButton
                                  type="button"
                                  onClick={resendCode}
                                >
                                    Resend Code
                                </S.ResendButton>
                            </S.LabelContainer>
                            <S.CodeInputsContainer>
                                {digitArray.map((val, index) => (
                                    <S.LoginCodeInput
                                      {...register(`code${index}`)}
                                      onKeyDown={(e) => {
                                        if (e.key === 'Tab') {
                                            focusOnNextInput(index - 1);
                                        }
                                      }}
                                      onKeyUp={(e) => {
                                        if (e.key !== 'Tab' && e.key !== 'Backspace' && e.key !== 'Delete' && singleDigitStrings.includes(e.key)) {
                                            focusOnNextInput(index);
                                            codeChanged(index);
                                        }
                                      }}
                                      maxLength={1}
                                      inputSize={InputSize.singleCharacter}
                                      id={`code${index}`}
                                      type="number"
                                      inputMode="numeric"
                                    />
                                ))}
                            </S.CodeInputsContainer>
                        </S.AuthInputContainer>
                        <S.SubmitButton submittable={isValid} onClick={forgotPassword} id="submitCode">Next</S.SubmitButton>
                    </>
                    )}
                    {codeSent && codeSubmitted && (
                    <>
                        <S.AuthInputContainer>
                            <S.LabelContainer>
                                <S.LoginLabel>New Password</S.LoginLabel>
                            </S.LabelContainer>
                            <S.PasswordWithButtonContainer justifyContent="center">
                                <S.LoginInput
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  required
                                  type={showPassword ? 'text' : 'password'}
                                  name="password"
                                />
                                <S.PasswordButton type="button" onClick={() => setShowPassword(!showPassword)} tabIndex={-1}>{showPassword ? 'HIDE' : 'SHOW'}</S.PasswordButton>
                            </S.PasswordWithButtonContainer>
                        </S.AuthInputContainer>
                        <S.AuthInputContainer>
                            <S.LabelContainer>
                                <S.LoginLabel>Confirm New Password</S.LoginLabel>
                            </S.LabelContainer>
                            <S.PasswordWithButtonContainer justifyContent="center">
                                <S.LoginInput
                                  value={confirmPassword}
                                  onChange={(e) => setConfirmPassword(e.target.value)}
                                  required
                                  type={showConfirm ? 'text' : 'password'}
                                  name="confirmPassword"
                                />
                                <S.PasswordButton type="button" onClick={() => setShowConfirm(!showConfirm)} tabIndex={-1}>{showConfirm ? 'HIDE' : 'SHOW'}</S.PasswordButton>
                            </S.PasswordWithButtonContainer>
                        </S.AuthInputContainer>
                        <S.SubmitButton
                          onClick={forgotPassword}
                          submittable={!!password && !!confirmPassword}
                        >
                            Save New Password
                        </S.SubmitButton>
                    </>
                    )}
                    <S.MessageContainer>
                        <S.Message type="error">{message.text}</S.Message>
                    </S.MessageContainer>
                </Form>
            </S.LoginFormContainer>
        </S.LoginContainer>
    );
}

export default ForgotPassword;
