import * as S from '../index.styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { IZoneItem, InputType, Zone } from '@apps/common-utilities/src/types/actionPlanTypes';

export const ActionPlanDetails = () => {
    const { zone } = useParams();
    const upperCaseZone = zone?.toUpperCase();
    const { actionPlan } = useSelector((state: RootState) => state.coaching);

    const [phoneNumberFormatted, setPhoneNumberFormatted] = useState<string>();
    const [emergencyPhoneNumberFormatted, setEmergencyPhoneNumberFormatted] = useState<string>();
    const [medications, setMedications] = useState<IZoneItem[]>([]);
    const [actions, setActions] = useState<IZoneItem[]>([]);
    const [minFlowRate, setMinFlowRate] = useState<number>();
    const [maxFlowRate, setMaxFlowRate] = useState<number>();

    useEffect(() => {
        if (actionPlan) {
            setPhoneNumberFormatted(
                `(${actionPlan.phoneNumber.slice(0, 3)}) ${actionPlan.phoneNumber.slice(3, 6)}-${actionPlan.phoneNumber.slice(6)}`
            );
            setEmergencyPhoneNumberFormatted(
                `(${actionPlan.emergencyContactPhone.slice(0, 3)}) ${actionPlan.emergencyContactPhone.slice(3, 6)}-${actionPlan.emergencyContactPhone.slice(6)}`
            );
        }
    }, [actionPlan, zone]);

    useEffect(() => {
        if (zone && actionPlan) {
            const currentZoneItems = actionPlan.actionPlanZones.find(z => z.zone === upperCaseZone)?.zoneItems;
            const currentZoneData = actionPlan.actionPlanZones.find(z => z.zone === upperCaseZone);
            if (currentZoneItems) {
                setMedications(currentZoneItems.filter(item => item.inputType === InputType.MEDICATION));
                setActions(currentZoneItems.filter(item => item.inputType === InputType.ACTION));
            }
            if (currentZoneData) {
                setMinFlowRate(currentZoneData.minFlowRate);
                setMaxFlowRate(currentZoneData.maxFlowRate);
            }
        }
    }, [actionPlan, zone]);

    let color;
    let textColor;
    let zoneTitle;
    switch (upperCaseZone) {
        case Zone.GREEN:
            color = 'green';
            textColor = 'white';
            zoneTitle = 'Green Zone';
            break;
        case Zone.YELLOW:
            color = 'yellow';
            textColor = 'black';
            zoneTitle = 'Yellow Zone';
            break;
        case Zone.RED:
            color = 'red';
            textColor = 'white';
            zoneTitle = 'Red Zone';
            break;
        default:
            color = 'green';
            textColor = 'white';
            zoneTitle = 'Zone';
    }
    return (
        <S.Container>
            <S.BackButton buttonType="tertiary" to="/action-plan">
                <FontAwesomeIcon icon={faChevronLeft as IconProp} /> Back to Action Plan
            </S.BackButton>
            <S.ActionPlanCard>
                <S.TitleContainer colorZone={color}><S.TitleText>{zoneTitle}</S.TitleText></S.TitleContainer>
                <S.ActionPlanBody>
                    {upperCaseZone === Zone.GREEN
                        && (
                        <>
                            <S.ActionPlanText>
                                <S.ColorText colorZone="black">{zoneTitle}</S.ColorText> are days when your symptoms are usual.
                            </S.ActionPlanText>
                            <S.ActionPlanText>
                                When you’re in the green zone you should:
                            </S.ActionPlanText>
                            <S.ActionPlanText marginBottom="0">
                                <S.BulletPointsContainer>
                                    <S.BulletPoint>Be able to complete usual levels of activity and exercise</S.BulletPoint>
                                    <S.BulletPoint>Be coughing up usual amounts of phlegm/mucus for you</S.BulletPoint>
                                    <S.BulletPoint>Be sleeping well at night</S.BulletPoint>
                                    <S.BulletPoint>Have a good appetite</S.BulletPoint>
                                </S.BulletPointsContainer>
                            </S.ActionPlanText>
                        </>
)}
                    {upperCaseZone === Zone.YELLOW && (
                        <>
                            <S.ActionPlanText>
                                <S.ColorText colorZone="black">{zoneTitle}</S.ColorText> are days when your symptoms are a bit worse than usual.
                                This usually means having more phlegm/mucus than usual and/or feeling more short of breath than usual,
                                but can also include:
                            </S.ActionPlanText>
                            <S.ActionPlanText marginBottom="0">
                                <S.BulletPointsContainer>
                                    <S.BulletPoint>Having less energy</S.BulletPoint>
                                    <S.BulletPoint>Increased rescue inhaler use</S.BulletPoint>
                                    <S.BulletPoint>More coughing than usual</S.BulletPoint>
                                    <S.BulletPoint>COPD symptoms waking you throughout the night</S.BulletPoint>
                                    <S.BulletPoint>Poor appetite</S.BulletPoint>
                                </S.BulletPointsContainer>
                            </S.ActionPlanText>
                        </>
                    )}
                    {upperCaseZone === Zone.RED && (
                        <>
                            <S.ActionPlanText>
                                <S.ColorText colorZone="black">{zoneTitle}</S.ColorText> are days when your symptoms are much worse than usual.
                            </S.ActionPlanText>
                            <S.ActionPlanText marginBottom="0">
                                <S.BulletPointsContainer>
                                    <S.BulletPoint>You've been in the yellow zone for 2-3 days but haven't see any improvement in your symptoms</S.BulletPoint>
                                    <S.BulletPoint>Severe shortness of breath, even at rest</S.BulletPoint>
                                    <S.BulletPoint>Not able to do any activity because of your breathing</S.BulletPoint>
                                    <S.BulletPoint>Not able to sleep because of your breathing</S.BulletPoint>
                                    <S.BulletPoint>Chest pains</S.BulletPoint>
                                    <S.BulletPoint>Fever</S.BulletPoint>
                                    <S.BulletPoint>Feeling confused or very drowsy</S.BulletPoint>
                                </S.BulletPointsContainer>
                            </S.ActionPlanText>
                        </>
                    )}
                </S.ActionPlanBody>
            </S.ActionPlanCard>
            <S.ActionPlanMainText as="h2">To do</S.ActionPlanMainText>
            {upperCaseZone === Zone.YELLOW && (
                <S.ActionPlanCard>
                    <S.ActionPlanBody>
                        <S.ActionPlanText marginBottom="5px" fontWeight="bold">Contact your healthcare provider</S.ActionPlanText>
                        <S.ActionPlanText marginBottom="0">{actionPlan?.providerName}, {phoneNumberFormatted}</S.ActionPlanText>
                    </S.ActionPlanBody>
                </S.ActionPlanCard>
            )}
            {upperCaseZone === Zone.RED && (
                <S.ActionPlanCard>
                    <S.ActionPlanBody>
                        <S.ActionPlanText marginBottom="5px" fontWeight="bold">Contact your healthcare provider</S.ActionPlanText>
                        <S.ActionPlanText marginBottom="0">{actionPlan?.providerName}, {phoneNumberFormatted}</S.ActionPlanText>
                    </S.ActionPlanBody>
                    <S.ActionPlanCardDivider />
                    <S.ActionPlanBody>
                        <S.ActionPlanText marginBottom="5px" fontWeight="bold">If required, contact your emergency contact</S.ActionPlanText>
                        <S.ActionPlanText marginBottom="0">{actionPlan?.emergencyContactName}, {emergencyPhoneNumberFormatted}</S.ActionPlanText>
                    </S.ActionPlanBody>
                    <S.ActionPlanCardDivider />
                    <S.ActionPlanBody>
                        <S.ColorText colorZone="darkRed">If required, seek Immediate medical care, Call 911</S.ColorText>
                    </S.ActionPlanBody>
                </S.ActionPlanCard>
            )}
            <S.ActionPlanCard>
                <S.DetailsBody>
                    <S.ActionPlanText marginBottom="0" fontWeight="bold">{upperCaseZone === Zone.GREEN ? 'Follow your normal routine' : 'Medication to take'}</S.ActionPlanText>
                </S.DetailsBody>
                {medications && medications.map((medication, index) => (
                    <React.Fragment key={index}>
                        <S.ActionPlanCardDivider />
                        <S.DetailsBody>
                            <S.ActionPlanText marginBottom="0">{medication.value}</S.ActionPlanText>
                        </S.DetailsBody>
                    </React.Fragment>
                ))}
                <S.ActionPlanCardDivider />
                <S.DetailsBody><S.ActionPlanText marginBottom="0">{minFlowRate} L /min Oxygen at rest</S.ActionPlanText></S.DetailsBody>
                <S.ActionPlanCardDivider />
                <S.DetailsBody><S.ActionPlanText marginBottom="0">{maxFlowRate} L /min Oxygen during activity</S.ActionPlanText></S.DetailsBody>
            </S.ActionPlanCard>
            {upperCaseZone !== Zone.GREEN
                && (
                <S.ActionPlanCard>
                    <S.DetailsBody>
                        <S.ActionPlanText marginBottom="0" fontWeight="bold">Other actions to take</S.ActionPlanText>
                    </S.DetailsBody>
                    {actions && actions.map((action, index) => (
                        <React.Fragment key={index}>
                            <S.ActionPlanCardDivider />
                            <S.DetailsBody>
                                <S.ActionPlanText marginBottom="0">{action.value}</S.ActionPlanText>
                            </S.DetailsBody>
                        </React.Fragment>
                    ))}
                </S.ActionPlanCard>
)}
        </S.Container>
    );
};

export default ActionPlanDetails;
