import styled from 'styled-components';
import { Container } from '../Forms/index.styles';
import { Card, Buttons } from '@apps/common-ui';

interface Props {
    zoomCard?: boolean;
}

interface MobileProps {
    completed?: boolean;
}

interface ModalProps {
    boldFont?: boolean;
}

interface CompleteIconProps {
    modalWith?: boolean;
}

export const OnboardingContainer = styled(Container)`
    justify-content: center;
`;

export const Logo = styled.img`
    width: 69px;
`;

export const OnboardingTitle = styled.p`
    color: ${props => props.theme.primary.medium};
    font-size: 23px;
    font-weight: bold;
    margin: 0;
`;

export const OnboardingText = styled.p`
    font-size: 18px;
    margin: 40px 0;
    text-align: center;
    br {
        display: block;
        @media (max-width: ${props => props.theme.screen.small}) {
            display: none;
        }
    }
`;

export const OnboardingCard = styled(Card)<Props>`
    width: 680px;
    min-height: 100px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 20px 0 0;
    overflow: hidden;

    &:last-of-type {
        margin-bottom: 0;
    }

    @media (max-width: ${props => props.theme.screen.smallTablet}) {
        width: 100%;
        flex-direction: ${props => props.zoomCard ? 'column' : 'row'};
    }
`;

export const ZoomCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
`;

export const ZoomCardDetail = styled.div`
    display: none;

    @media (max-width: ${props => props.theme.screen.small}) {
        display: block;
        width: 100%;
        hr {
            margin: 0 auto;
            margin-left: 20px;
            border-style: solid;
            border-color: ${props => props.theme.shades.lighterGrey};
            border-width: 1px 0 0 0;
        }
        p {
            text-align: left;
            font-size: 18px;
            margin: 10px;
            button {
                margin: 0;
                padding:0;
                margin-right: 5px;
            }
    }
`;

export const FormDetails = styled.div`
    flex: 1;
    h4 {
        margin: 5px 0 0 20px;
    }
    p {
        font-size: 18px;
        margin: 0;
        margin-left: 20px;
        button {
            margin: 0;
            padding:0;
            margin-right: 5px;
        }
        @media (max-width: ${props => props.theme.screen.small}) {
            display: none;
        }
    }
`;

export const MobileViewContainer = styled.div<MobileProps>`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    @media (max-width: ${props => props.theme.screen.small}) {
        display: ${props => props.completed ? 'flex' : 'block'};
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
`;

export const OnboardingListButton = styled(Buttons.Button)`
    margin: 0;
    width: 140px;
    text-align: center;
    flex-shrink: 0;

    @media (max-width: ${props => props.theme.screen.small}) {
        margin-left: 20px;
        margin-top: 10px;
    }
`;

export const SkipButton = styled(OnboardingListButton)`
    width: 100px;
    margin-right: 10px;

    @media (max-width: ${props => props.theme.screen.small}) {
        margin: 0;
    }
`;

export const HelpButton = styled(SkipButton)`
    width: auto;
    @media (max-width: ${props => props.theme.screen.small}) {
        padding: 0;
    }
`;

export const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 96px;
    min-width: 71px;
    background: ${props => props.theme.shades.lightBlueGray};

    img {
        width: 50px;
    }
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 30px;
`;

export const ModalText = styled.p<ModalProps>`
    font-size: 18px;
    font-weight: ${props => props.boldFont ? 'bold' : 'normal'};
    margin-top: 5px;
    margin-bottom: 25px;
    text-align: center;
`;

export const OnboardingCompleteIcon = styled.img<CompleteIconProps>`
    width: ${props => props.modalWith ? '37px' : '30px'};
    margin: 5px;
    margin-left: ${props => props.modalWith ? '5px' : '30px'};;
`;

export const ModalButton = styled(OnboardingListButton)`
    @media (max-width: ${props => props.theme.screen.small}) {
        margin: 0;
    }
`;
export const BSIconContainer = styled(IconContainer)`
    background: ${props => props.theme.primary.medium};
`;
