import { PhoneNumberField } from '@aws-amplify/ui-react';
import styled from 'styled-components';

const PhoneNumberInput = styled(PhoneNumberField)<{ error?: boolean }>`
  width: 100%;
  border-radius: 0;
  
  input {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid ${props => (props.error ? props.theme.text.error : 'lightgrey')};
    box-shadow: none;
    font-size: 12px;
    :focus {
      outline: none;
      border-bottom: 1px solid ${props => (props.error ? props.theme.text.error : props.theme.primary.dark)};
      box-shadow: none;
    }
  }
  select {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid ${props => (props.error ? props.theme.text.error : 'lightgrey')};
    font-size: 12px;
    text-align: start;
    :focus {
      outline: none;
      border-bottom: 1px solid ${props => (props.error ? props.theme.text.error : props.theme.primary.dark)};
      box-shadow: none;
    }
  }
  .amplify-field-group__control {
    border-radius: 0;
  }
`;

export default PhoneNumberInput;
