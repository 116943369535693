import React, { useEffect, useState } from 'react';
import * as S from './index.styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import AppointmentList from '../../components/AppointmentsList';
import { Appointment, Form } from '../../types/models';
import Mixpanel from '../../services/Mixpanel';
import { LearningTypes } from '@apps/common-utilities';
import { useNavigate } from 'react-router';
import { Modal, Spinner, theme, useModal } from '@apps/common-ui';
import HomeCard from './HomeCard';
import { Page } from '../../components/Page';
import OnboardingList from '../Onboarding/OnboardingList';
import OnboardingCompleteModal from '../Onboarding/OnboardingCompleteModal';
import { useActionLoader } from '../../hooks/useActionLoader';
import { markOnboardingComplete } from '../../state/reducers/session/actions';
import TestZoomModal from '../TestZoomPopup';
import { fetchAppointments } from '../../state/reducers/coaching/actions';
import OnboardingService from '../../services/OnboardingService';
import NoAppointments from '../Appointments/NoAppointments';
import { formatDate } from '../../components/AppointmentsList/utils';
import OnboardingVideo from '../Onboarding/OnboardingVideo';

const Home = () => {
    const { callAction: getAppointments } = useActionLoader(fetchAppointments);
    const { t } = useTranslation();
    const { forms, formsLoading, appointments, appointmentsLoading, exercisePlan, course } = useSelector(
        (state: RootState) => state.coaching
    );
    const [filteredAppointments, setFilteredAppointments] = useState<Appointment[]>([]);
    const [UpcomingDate, setUpcomingDate] = useState<string | null>(null);

    const [currentModuleNumber, setCurrentModuleNumber] = useState<number>();
    const [currentSubmoduleType, setCurrentSubmoduleType] = useState<string>();
    const [showOnboardingCompleteModal, setShowOnboardingCompleteModal] = useState(
        OnboardingService.isOnboardingCompleted()
    );
    const { callAction: setOnboardingComplete } = useActionLoader(markOnboardingComplete);
    const [showZoomTestModal, setshowZoomTestModal] = useState(false);
    const [showCompleted, setShowCompleted] = useState(OnboardingService.isTestZoomCompleted());

    const [testZoomCompleted] = useState(OnboardingService.isTestZoomCompleted());
    const [onboardingVideoWatched, setOnboardingVideoWatched] = useState(OnboardingService.isOnboardingVideoWatched());
    const [showOnboardingVideo, setShowOnboardingVideo] = useState(true);
    const { isOpen: isOnboardingVideoModalOpen, closeModal: closeOnboardingVideoModal, openModal: openOnboardingVideoModal } = useModal();

    const { openModal } = useModal();

    const { tenantUser, user } = useSelector((state: RootState) => state.session);
    const navigate = useNavigate();

    const getCurrentSubmoduleDetails = () => {
        if (!course || !course.currentSubModule) {
            return;
        }

        if (course.currentSubModule.subModuleType === LearningTypes.SubModuleType.LESSON) {
            setCurrentSubmoduleType('Lesson');
        } else if (course.currentSubModule.subModuleType === LearningTypes.SubModuleType.QUIZ) {
            setCurrentSubmoduleType('Quiz');
        }

        setCurrentModuleNumber(
            course.modules.findIndex((module) => module.id === course.currentSubModule.moduleId) + 1
        );
    };

    const navigateToCurrentSubmodule = () => {
        // if no current submodule, then navigate to modules page, else navigate to current lesson or quiz
        if (!course || !course.currentSubModule) {
            navigate('/learning/modules');
        } else if (course.currentSubModule.subModuleType === LearningTypes.SubModuleType.LESSON) {
            navigate(
                `/modal/module/${course.currentSubModule.moduleId}/lesson/${course.currentSubModule.id}/${false}`
            );
        } else if (course.currentSubModule.subModuleType === LearningTypes.SubModuleType.QUIZ) {
            navigate(
                `/modal/module/${course.currentSubModule.moduleId}/quiz/${course.currentSubModule.id}/${false}/${-1}`
            );
        }
    };

    const dispatchSetOnboardingComplete = async () => {
        if (user) {
            await setOnboardingComplete(user.id);
            OnboardingService.setOnboardingCompleted();
            setShowOnboardingCompleteModal(true);
            OnboardingService.clearOnboardingStorage(false);
            Mixpanel.track('web_home_onboarding_complete');
        }
    };

    const closeOnboardingCompleteModal = () => {
        setShowOnboardingCompleteModal(false);
        OnboardingService.removeOnboardingCompleted();
    };

    const closeZoomTestModal = () => {
        setshowZoomTestModal(false);
    };

    const openWelcomeVideoModal = () => {
        openOnboardingVideoModal();
        setShowOnboardingVideo(true);
    };

    const closeWelcomeVideoModal = () => {
        closeOnboardingVideoModal();
        setShowOnboardingVideo(false);
    };

    const markWelcomeVideoWatched = () => {
        OnboardingService.setOnboardingVideoWatched();
        setOnboardingVideoWatched(true);
    };

    useEffect(() => {
        Mixpanel.track('web_home_page');
    }, []);

    useEffect(() => {
        if (!localStorage.getItem('downloadPromptShown')) {
            openModal();
        }
    }, []);

    useEffect(() => {
        if (course) {
            getCurrentSubmoduleDetails();
        }
    }, [course]);

    useEffect(() => {
        if (user) {
            getAppointments({ userId: user.id });
        }
    }, [user]);

    useEffect(() => {
        if (appointments.length > 0) {
            const lastAppointment = appointments[0];
            const lastAppointmentDate = formatDate(new Date(lastAppointment.date));

            const appointmentsForLastDate = appointments
                .filter(appointment => formatDate(new Date(appointment.date)) === lastAppointmentDate);

            setFilteredAppointments(appointmentsForLastDate);
            setUpcomingDate(lastAppointmentDate);
        }
    }, [appointments]);
    if (appointmentsLoading || formsLoading) {
        return <Spinner />;
    }

    return (
        <Page>
            {/* If onboarding is not complete, show the Onboarding list */}
            <Modal showModal={isOnboardingVideoModalOpen} dismissModal={closeWelcomeVideoModal}>
                <OnboardingVideo
                  setShowWelcomeVideo={closeWelcomeVideoModal}
                  setVideoWatched={markWelcomeVideoWatched}
                  videoWatched={onboardingVideoWatched}
                />
            </Modal>
            { !user?.onboardingComplete && (
                <S.Section>
                    <S.Heading>Welcome to BreatheSuite</S.Heading>
                    <S.Subheading>Please complete these tasks before your first appointment</S.Subheading>
                    <OnboardingList
                      dispatchSetOnboardingComplete={dispatchSetOnboardingComplete}
                      sourcePage="home"
                      setShowWelcomeVideo={openWelcomeVideoModal}
                      welcomeVideoWatched={onboardingVideoWatched}
                    />
                </S.Section>
            )}
            {!appointmentsLoading && !formsLoading && (
            <>
                <S.Section>
                    <S.Heading>Upcoming Appointments</S.Heading>
                    {filteredAppointments.length > 0 && UpcomingDate ? (
                        <AppointmentList appointments={filteredAppointments} day={UpcomingDate} />
                    ) : (
                        <NoAppointments />
                    )}
                </S.Section>
                <S.Section>
                    <S.Heading>To Do</S.Heading>
                    <S.Subheading>
                        Make sure to check and stay on top of your Exercises, Courses, etc.
                    </S.Subheading>
                    {!testZoomCompleted
                        && (
                        <HomeCard
                          isDone={showCompleted}
                          imageUrl="/ZoomTest.png"
                          title="Test Zoom on this device"
                          clickFunction={() => {
                          Mixpanel.track('web_home_page_test_zoom');
                          setshowZoomTestModal(true);
                        }}
                          buttonText="Test"
                          buttonArrow={false}
                        />
                    )}
                    <HomeCard
                      imageUrl="/HomePageExerciseIcon.svg"
                      title="Exercise"
                      clickFunction={() => {
                                    Mixpanel.track('web_home_page_explore_exercises');
                                    navigate('/exercises');
                                }}
                      buttonText="View"
                      buttonArrow={false}
                    />
                    {exercisePlan?.dailySummaries.some((day) => day.totalRMTExercises > 0) && (
                    <HomeCard
                      imageUrl="/BreatherIcon.svg"
                      title="Breather"
                      clickFunction={() => {
                            Mixpanel.track('web_home_page_explore_breather');
                            navigate('/breather');
                          }}
                      buttonText="View"
                      buttonArrow={false}
                    />
                    )}
                    <HomeCard
                      imageUrl="/LearningIcon.svg"
                      title="Education"
                      clickFunction={() => {
                        Mixpanel.track('web_home_page_explore_courses');
                        navigateToCurrentSubmodule();
                    }}
                      buttonText="Read"
                      buttonArrow={false}
                    />
                    {forms.map((form: Form) => (
                        <HomeCard
                          key={form.jotformId}
                          imageUrl="/FormIcon.svg"
                          title={form.name.split(' - ')[0]}
                          clickFunction={() => {
                            navigate(`/forms/${form.jotformId}`);
                        }}
                          buttonText="Start Form"
                          buttonArrow={false}
                          buttonWidth="140px"
                        />
                ))}
                </S.Section>
                <OnboardingCompleteModal
                  isOpen={showOnboardingCompleteModal}
                  closeModal={closeOnboardingCompleteModal}
                />
                <TestZoomModal
                  isOpen={showZoomTestModal}
                  closeModal={closeZoomTestModal}
                  onTestCompleted={() => setShowCompleted(true)}
                />
            </>
            )}

        </Page>
    );
};

export default Home;
