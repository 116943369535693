import { format, isToday } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AppointmentList from '../../components/AppointmentsList';
import Mixpanel from '../../services/Mixpanel';
import { RootState } from '../../state/store';
import { Appointment } from '../../types/models';
import * as S from './index.styles';
import PageHeader from '../../components/common/PageHeader/insex';
import { Page } from '../../components/Page';
import { useActionLoader } from '../../hooks/useActionLoader';
import { fetchAppointments } from '../../state/reducers/session/actions';
import NoAppointments from './NoAppointments';
import { formatDate } from '../../components/AppointmentsList/utils';
import { Spinner } from '@apps/common-ui';

type GroupedAppointments = {
    [monthYear: string]: {
        [day: string]: Appointment[];
    };
};

const groupAppointments = (appointments: Appointment[]) => {
    const groupedByDate: GroupedAppointments = {};
    appointments.forEach((appointment) => {
        const date = new Date(appointment.date);
        const monthYearKey = format(date, 'MMMM');
        const dayKey = formatDate(date);
        if (!groupedByDate[monthYearKey]) {
            groupedByDate[monthYearKey] = {};
        }
        if (!groupedByDate[monthYearKey][dayKey]) {
            groupedByDate[monthYearKey][dayKey] = [];
        }
        groupedByDate[monthYearKey][dayKey].push(appointment);
    });
    return groupedByDate;
};

const isAppointmentToday = (appointments: Appointment[]): boolean => appointments
    .some(appointment => isToday(new Date(appointment.date)));

const Appointments = () => {
    const { user } = useSelector((state: RootState) => state.session);
    const { appointments } = useSelector((state: RootState) => state.coaching);
    const { callAction: getAppointments, loading, done } = useActionLoader(fetchAppointments);
    const [groupedAppointments, setGroupedAppointments] = useState<GroupedAppointments>({});
    const [todaysAppointments, setTodaysAppointments] = useState(false);

    useEffect(() => {
        if (user) {
            getAppointments({ userId: user.id });
        }
    }, [user]);

    useEffect(() => {
        setGroupedAppointments(groupAppointments(appointments));
        setTodaysAppointments(isAppointmentToday(appointments));
    }, [appointments]);

    useEffect(() => {
        Mixpanel.track('web_appointments_page');
    }, []);

    if (appointments.length === 0 && loading && !done) {
        return <Spinner />;
    }

    return (
        <Page>
            <PageHeader imgSrc="/AppointmentsTitle.png" title="Appointments" />
            {appointments.length === 0 && (
                <NoAppointments />
            )}
            {!todaysAppointments && appointments.length > 0 && (
                <NoAppointments noToday />
            )}
            {Object.keys(groupedAppointments).map(monthYear => (
                <div key={monthYear}>
                    {monthYear !== format(new Date(), 'MMMM')
                    && <S.MonthHeading>{monthYear}</S.MonthHeading>}
                    {Object.keys(groupedAppointments[monthYear]).map(day => (
                        <AppointmentList appointments={groupedAppointments[monthYear][day]} day={day} />
                    ))}
                </div>
            ))}
        </Page>
    );
};

export default Appointments;
