import { Slider } from '@apps/common-ui';
import React, { useEffect, useState } from 'react';
import * as S from '../index.styles';

enum SliderColors {
    blue0 = '#bfecff',
    blue1 = '#8bc0ff',
    green2 = '#c2edb0',
    green3 = '#acd999',
    green4 = '#87e099',
    orange5 = '#f8c187',
    orange6 = '#f9a987',
    red7 = '#ff9287',
    red8 = '#ff7676',
    red9 = '#ff4a38',
    red10 = '#ff0000'
}

/**
 * Maps the Borg Dyspnea Scale to a slider with relevant colors and emojis
 * See here for an example of the Borg Dyspnea Scale:
 * https://www.royalberkshire.nhs.uk/media/042lkbwy/pulmonary-rehabilitation-exercises_jul20.pdf
 */
const BreathlessnessSlider = ({ value, width, onChange }: {value: number, width: string, onChange: Function}) => {
    const sliderValueStrings = ['Nothing at all', 'Very slight', 'Slight', 'Moderate', 'Somewhat severe', 'Severe', 'Severe', 'Very severe', 'Very severe', 'Very, very severe', 'Maximal'];
    const [emojiPath, setEmojiPath] = useState('');
    const [valueString, setValueString] = useState('');
    const [sliderColor, setSliderColor] = useState<string>(SliderColors.blue0);

    // Get the severity color based on the slider value
    const getColor = (sliderVal: number) => {
        let color = SliderColors.blue0;
        // 0.5 is a special case
        if (sliderVal === 0.5) {
            return '#9ddcff';
        }
        for (let i = 0; i < sliderValueStrings.length; i += 1) {
            if (Math.floor(sliderVal) === i) {
                color = Object.values(SliderColors)[i];
            }
        }
        return color;
    };

    // Get the severity label based on the slider value
    const getSeverityLabel = (sliderVal: number) => {
        let label = sliderValueStrings[0];
        // handle case of 0.5 value
        if (Number(sliderVal) === 0.5) {
            return 'Very, very slight';
        }
        for (let i = 0; i < sliderValueStrings.length; i += 1) {
            if (Math.floor(sliderVal) === i) {
                label = sliderValueStrings[i];
            }
        }
        return label;
    };

    const getEmojiString = (sliderVal: number) => {
        if (sliderVal >= 9) {
            return '/breathlessness/MaximalIcon.png';
        } else if (sliderVal >= 7) {
            return '/breathlessness/SevereIcon.png';
        } else if (sliderVal >= 4) {
            return '/breathlessness/ModerateIcon.png';
        } else if (sliderVal >= 2) {
            return '/breathlessness/SlightIcon.png';
        } else if (sliderVal >= 0) {
            return '/breathlessness/NothingIcon.png';
        }
        return '';
    };

    useEffect(() => {
        setEmojiPath(getEmojiString(value));
        setValueString(getSeverityLabel(value));
        setSliderColor(getColor(value));
    }, [value]);

    return (
        <S.SliderRow>
            <div>
                <S.LevelLabel>Level of Breathlessness</S.LevelLabel>
                <S.LevelLabel>{Number(value) === 0.5 ? value : Math.floor(value)}/10</S.LevelLabel>
            </div>
            <S.SliderContainer>
                <Slider
                  value={value}
                  min={0}
                  max={10}
                  step={0.5}
                  maxWidth={width}
                  onChange={onChange}
                  sliderColor={sliderColor}
                />
                <S.SliderLabelContainer>
                    <S.SliderLabel>0</S.SliderLabel>
                    <S.SliderLabel>10</S.SliderLabel>
                </S.SliderLabelContainer>
            </S.SliderContainer>
            <S.SeverityContainer>
                <S.EmojiContainer>
                    <S.Emoji src={emojiPath} />
                </S.EmojiContainer>
                <S.SeverityText>
                    {valueString}
                </S.SeverityText>
            </S.SeverityContainer>
        </S.SliderRow>
    );
};

export default BreathlessnessSlider;
