export var Zone;
(function (Zone) {
    Zone["GREEN"] = "GREEN";
    Zone["YELLOW"] = "YELLOW";
    Zone["RED"] = "RED";
})(Zone || (Zone = {}));
export var InputType;
(function (InputType) {
    InputType["MEDICATION"] = "MEDICATION";
    InputType["ACTION"] = "ACTION";
})(InputType || (InputType = {}));
