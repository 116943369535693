import { ExerciseTypes, LearningTypes } from '@apps/common-utilities';
import ApiError from '@apps/common-utilities/src/api/ApiError';
import { IAssignedExerciseSubmission, IExerciseWeekSummary } from '@apps/common-utilities/src/types/exerciseTypes';
import { IActionPlan } from '@apps/common-utilities/src/types/actionPlanTypes';
import { Routes } from '../../../api/Routes';
import { Appointment, Form } from '../../../types/models';
import { AppDispatch } from '../../store';
import { setCourse, setAppointments, setForms, removeForm, setExercisePlan, setExerciseSubmissionHistory, setNewBaselineRequired, setActionPlan, setAppointmentsLoading, setFormsLoading } from './index';
import { format, startOfWeek } from 'date-fns';
import { formatDateForSafari } from './utils';
import { TenantApi } from '../../../api/UsersApi';

export const fetchAppointments = ({ userId }: {userId: string}) => async (dispatch: AppDispatch) => {
    dispatch(setAppointmentsLoading({ appointmentsLoading: true }));
    TenantApi.get(`${Routes.healthie.users}/${userId}/appointments`)
        .then((res: Appointment[]) => {
            res.forEach(v => {
                v.date = formatDateForSafari(v.date);
            });
            dispatch(setAppointments({ appointments: res }));
        }).finally(() => {
            dispatch(setAppointmentsLoading({ appointmentsLoading: false }));
        });
};

export const fetchForms = ({ userId }: {userId: string}) => async (dispatch: AppDispatch) => {
    dispatch(setFormsLoading({ formsLoading: true }));
    await TenantApi.get(`${Routes.tenant.user}/${userId}/${Routes.tenant.forms}`)
        .then((res: Form[]) => {
            dispatch(setForms({ forms: res || [] }));
        }).catch((e: ApiError) => {
            throw new Error('Error fetching forms, please try again later and contact support if the problem persists.');
        }).finally(() => {
            dispatch(setFormsLoading({ formsLoading: false }));
        });
};

export const completeForm = ({ formId }: { formId: string }) => async (dispatch: AppDispatch) => {
    dispatch(removeForm({ formId }));
};

export const fetchExercisePlan = ({ userId }: {userId: string}) => async (dispatch: AppDispatch) => {
    TenantApi.get(`/users/${userId}/exercise-plans/weekly-summary`, {
        params: {
            startDate: startOfWeek(new Date(), { weekStartsOn: 1 }).toISOString(),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    }).then((res: IExerciseWeekSummary) => {
        dispatch(setExercisePlan({ exercisePlan: res }));
    });
};

export const fetchExerciseSubmissionHistory = ({ userId }: {userId: string}) => async (dispatch: AppDispatch) => {
    TenantApi.get(`${Routes.tenant.user}/${userId}/exercise-plans/submission-history`).then((res: IAssignedExerciseSubmission[]) => {
        dispatch(setExerciseSubmissionHistory({ exerciseSubmissionHistory: res }));
    });
};

export const createExerciseBaseline = ({ baseline }: {baseline: ExerciseTypes.ICreatedBaseline}) => async () => {
    baseline.calendarCreatedAt = format(new Date(), 'yyyy-MM-dd');
    return TenantApi.post('/exercise-plans/exercise-baselines', baseline);
};

export const createExerciseSubmission = ({ exerciseSubmission }: {exerciseSubmission: ExerciseTypes.IAssignedExerciseSubmission}) => async () => {
    await TenantApi.post('/exercise-plans/exercise-submissions', exerciseSubmission);
};

export const isBaselineRequired = ({ userId }: {userId: string}) => async (dispatch: AppDispatch) => {
    TenantApi.get(`${Routes.tenant.user}/${userId}/exercise-plans/exercise-baselines/baseline-required`).then((res: {newBaselineRequired: boolean}) => {
        dispatch(setNewBaselineRequired({ newBaselineRequired: res.newBaselineRequired }));
    }).catch(() => {
        dispatch(setNewBaselineRequired({ newBaselineRequired: true }));
    });
};

export const fetchCourse = ({ userId }: {userId: string}) => async (dispatch: AppDispatch) => {
    TenantApi.get(`${Routes.tenant.user}/${userId}/courses/assigned/summary`).then((res: LearningTypes.IUserCourse) => {
        dispatch(setCourse({ course: res }));
    });
};

export const createLessonSubmission = ({ userId, lessonSubmission }: { userId: string, lessonSubmission: LearningTypes.IUserLessonSubmission}) => async (dispatch: AppDispatch) => {
    await TenantApi.post(`${Routes.tenant.user}/${userId}/courses/lessons/completions`, lessonSubmission);
};

export const fetchActionPlan = ({ userId }: {userId: string}) => async (dispatch: AppDispatch) => {
    TenantApi.get(`${Routes.tenant.user}/${userId}/action-plans/active`).then((res: IActionPlan) => {
        dispatch(setActionPlan({ actionPlan: res }));
    });
};
