import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Provider, TenantUser, User } from '../../../types/models';

export interface SessionState {
    loggedIn: boolean;
    user: User | null;
    tenantUser: TenantUser | null;
}

const initialState = {
    loggedIn: false,
    user: null,
    tenantUser: null,
    appointments: [],
} as SessionState;

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setTenantUser: (state, { payload }: {payload: { user:TenantUser }}) => {
            state.tenantUser = payload.user;
        },
        login: (state, { payload }: { payload: { user: User } }) => {
            state.loggedIn = !!payload.user?.id;
            state.user = payload.user ? payload.user : null;
        },
        logout: state => {
            state.loggedIn = false;
            state.user = null;
            state.tenantUser = null;
        },
        setUser: (state, { payload }: { payload: User }) => {
            state.user = { ...payload };
        },
        setOnboardingComplete: (state, { payload }: PayloadAction<boolean>) => {
            if (state.user) {
                state.user.onboardingComplete = payload;
            }
        }
    }
});

export const {
    login,
    logout,
    setUser,
    setTenantUser,
    setOnboardingComplete,
} = sessionSlice.actions;

export default sessionSlice.reducer;
