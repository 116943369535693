import React, { useEffect, useState } from 'react';
import * as S from './index.styles';
import { Appointment } from '../../types/models';
import { format, isSameDay, isToday, isTomorrow, parseISO } from 'date-fns';
import AppointmentCard from './AppointmentCard';
import TestZoomModal from '../../pages/TestZoomPopup';
import AppointmentPopup from '../../pages/AppointmentPopup';

type Props = {
    appointments: Appointment[];
    day: string;
}

const showTimeBanner = (appointment: Appointment) => isSameDay(new Date(), new Date(appointment.date));

const AppointmentList = ({ appointments, day }: Props) => {
    const [showCardBanners, setShowCardBanners] = useState<Map<string, boolean>>(new Map<string, boolean>());
    const [currentAppointment, setCurrentAppointment] = useState(appointments[0]);
    const [isAppointmentPopupOpen, setIsAppointmentPopupOpen] = useState(false);
    const [isZoomTestModalOpen, setIsZoomTestModalOpen] = useState(false);

    useEffect(() => {
        const tempMap = new Map<string, boolean>();
        appointments.forEach((appointment) => {
            tempMap.set(appointment.id, showTimeBanner(appointment));
        });
        setShowCardBanners(tempMap);
    }, [appointments]);

    const getDayLabel = (dateStr: string): string => {
        const date = parseISO(dateStr);
        if (isToday(date)) return `Today, ${format(date, 'MMMM d')}`;
        if (isTomorrow(date)) return `Tomorrow, ${format(date, 'MMMM d')}`;
        return format(date, 'EEEE, MMMM d');
    };

    const handleOpenZoomTestModal = () => {
        setIsZoomTestModalOpen(true);
        setIsAppointmentPopupOpen(false);
    };

    const closeZoomTest = () => {
        setIsZoomTestModalOpen(false);
        setIsAppointmentPopupOpen(true);
    };

    return (
        <>
            {isAppointmentPopupOpen
            && (
            <AppointmentPopup
              onDismiss={() => setIsAppointmentPopupOpen(false)}
              appointment={currentAppointment}
              onOpenZoomTestModal={handleOpenZoomTestModal}
            />
            )}
            <TestZoomModal
              isOpen={isZoomTestModalOpen}
              closeModal={closeZoomTest}
              onTestCompleted={closeZoomTest}
            />
            <S.Container>
                <S.Section isToday={isToday(parseISO(day))}>
                    <S.DateHeading isToday={isToday(parseISO(day))}>
                        {getDayLabel(day)}
                    </S.DateHeading>
                    {appointments.map((appointment: Appointment) => (
                        <AppointmentCard
                          appointment={appointment}
                          key={appointment.id}
                          showCardBanner={showCardBanners.get(appointment.id) || false}
                          setAppointment={setCurrentAppointment}
                          openAppointmentPopup={() => setIsAppointmentPopupOpen(true)}
                          isToday={isToday(new Date(appointment.date))}
                        />
                ))}
                </S.Section>
            </S.Container>
        </>
    );
};

export default AppointmentList;
