import { Buttons } from '@apps/common-ui';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Container from '../../components/common/Container';
import { useActionLoader } from '../../hooks/useActionLoader';
import Mixpanel from '../../services/Mixpanel';
import { logoutUser } from '../../state/reducers/session/actions';
import { RootState } from '../../state/store';
import * as S from './index.styles';
import { Page } from '../../components/Page';
import OnboardingService from '../../services/OnboardingService';

const Account = () => {
    const { callAction: logout } = useActionLoader(logoutUser);
    const { user } = useSelector((state: RootState) => state.session);

    useEffect(() => {
        Mixpanel.track('web_account_page');
    }, []);

    const handleLogout = () => {
        // Clear onboarding related local storage items
        OnboardingService.clearOnboardingStorage(true);
        Mixpanel.track('web_account_page_logout_button');
        logout();
    };

    return (
        <Page>
            <h2>Account Details</h2>
            <S.AccountCard>
                <S.AccountItem>
                    <S.AccountItemTitle>
                        Email Address:
                    </S.AccountItemTitle>
                    <S.AccountAction>
                        {user?.email}
                    </S.AccountAction>
                </S.AccountItem>
                <S.AccountItem>
                    <S.AccountItemTitle>
                        Password:
                    </S.AccountItemTitle>
                    <S.AccountAction>
                        <S.AccountItemInput
                          disabled
                          type="password"
                          value="***************"
                        />
                        <Buttons.LinkButton
                          onClick={() => Mixpanel.track('web_account_page_edit_password_button')}
                          buttonType="secondary"
                          to="/reset-password"
                        >
                            Edit
                        </Buttons.LinkButton>
                    </S.AccountAction>
                </S.AccountItem>
                <S.LogoutButtonContainer>
                    <Buttons.Button
                      buttonStyle="danger"
                      buttonType="tertiary"
                      onClick={handleLogout}
                    >
                        Logout
                    </Buttons.Button>
                </S.LogoutButtonContainer>
            </S.AccountCard>
        </Page>
    );
};

export default Account;
