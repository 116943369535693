import styled from 'styled-components';

export const WeekSelection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const ArrowButton = styled.button<{ visible: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 40px;
    background: ${props => props.theme.shades.white};
    border: 1px solid ${props => props.theme.shades.lightGrey};
    border-radius: 13px;
    cursor: pointer;
    visibility: ${props => (props.visible ? 'visible' : 'hidden')};
    pointer-events: ${props => (props.visible ? 'auto' : 'none')};
`;

export const WeekString = styled.p`
`;
