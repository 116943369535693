import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import FormsList from '../../components/FormsList';
import * as S from './index.styles';
import { Buttons } from '@apps/common-ui';
import Mixpanel from '../../services/Mixpanel';

const Forms = () => {
    const { forms } = useSelector((state: RootState) => state.coaching);

    useEffect(() => {
        Mixpanel.track('web_forms_page');
    }, []);

    return (
        <S.Container>
            <div>
                <S.Heading>Pending forms</S.Heading>
                <S.SubHeading>These forms help us provide more personalized care. This list will change from time to time to collect new information based on your stage in our program.</S.SubHeading>
                {forms.length
                    ? <FormsList forms={forms} />
                    : <S.SubHeading>You have no forms to complete.</S.SubHeading>}
                <S.HelpLink>
                    <Buttons.LinkButton
                      buttonType="tertiary"
                      to="/support"
                      style={{ fontWeight: 'bold' }}
                    >
                        Having issues with our forms?
                    </Buttons.LinkButton>
                </S.HelpLink>
            </div>
        </S.Container>
    );
};

export default Forms;
