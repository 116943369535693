var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import styled from 'styled-components';
var ScrollPrompt = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: absolute;\n    bottom: ", ";\n    border: 1px solid black;\n    border-radius: 5px;\n    padding: 5px 15px;\n    background-color: #e7e7e7;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    span {\n        font-size: 12px;\n        font-weight: bold;\n    }\n"], ["\n    position: absolute;\n    bottom: ", ";\n    border: 1px solid black;\n    border-radius: 5px;\n    padding: 5px 15px;\n    background-color: #e7e7e7;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    span {\n        font-size: 12px;\n        font-weight: bold;\n    }\n"])), function (props) { return props.customOffset || '2%'; });
/**
 * Shows a custom prompt on scrollable containers that can be used to tell a user to scroll down
 * @param ref The ref to the scrolling container
 * @param prompt A string to display in the prompt
 * @param offsetFromBottom A custom offset from the bottom of the container
 * @returns A function that renders the prompt
 */
var usePromptToScroll = function (ref, prompt, offsetFromBottom) {
    var _a = useState(false), showing = _a[0], setShowing = _a[1];
    var handleScroll = function () { return setShowing(false); };
    useEffect(function () {
        var node = ref.current;
        var canScroll = node && node.scrollHeight > node.clientHeight;
        if (!node || !canScroll) {
            return;
        }
        setShowing(true);
        node.addEventListener('scroll', handleScroll);
        // eslint-disable-next-line consistent-return
        return function () { return node.removeEventListener('scroll', handleScroll); };
    }, [ref.current]);
    useEffect(function () {
        if (!showing) {
            var node = ref.current;
            if (!node) {
                return;
            }
            node.removeEventListener('scroll', handleScroll);
        }
    }, [showing]);
    if (showing) {
        return function () { return (_jsx(ScrollPrompt, __assign({ customOffset: offsetFromBottom }, { children: _jsx("span", { children: "\u2193 ".concat(prompt) }) }))); };
    }
    return function () { return null; };
};
export default usePromptToScroll;
var templateObject_1;
