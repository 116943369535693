import * as S from './index.styles';

type Props = {
    onClick: () => void;
}

const VitalTipCard = ({ onClick }: Props) => {
    return (
        <>
        <S.Container>
            <S.Message>Unsure how to read your vitals?</S.Message>
            <S.Button onClick={onClick}>View Pulse Oximeter Guide</S.Button>
        </S.Container>
        </>
    );
};

export default VitalTipCard;
