// by default, createTheme extends the defaultTheme.
export const amplifyTheme = {
    name: 'my-theme',
    tokens: {
        colors: {
            blue: {
                10: { value: '#2c3e87' },
                20: { value: '#2c3e87' },
                40: { value: '#2c3e87' },
                80: { value: '#2c3e87' },
                90: { value: '#2c3e87' },
                100: { value: '#2c3e87' },
            },
            brand: {
                primary: {
                    10: { value: '#2c3e87' },
                    20: { value: '#2c3e87' },
                    40: { value: '#2c3e87' },
                    80: { value: '#2c3e87' },
                    90: { value: '#2c3e87' },
                    100: { value: '#2c3e87' },
                },
            },
            background: {
                primary: { value: 'white' },
                secondary: { value: '#2c3e87' },
            },
            font: {
                secondary: { value: 'white' },
            },
        },
    },
};

export const theme = {
    background: '#F6F6F6',
    text: {
        default: '#383838',
        blue: '#2c3e87',
        white: '#ffff',
        lightGreen: '#6fbf3a',
        black: '#282727',
        error: '#df6262',
        success: '#00b300',
        warning: '#ff9100',
        lightBlue: '#82BCFF',
        lightGrey: '#8C979D',
        slateGray: '#5C666C',
    },
    links: {
        blue: '#0F77F0',
        red: '#F85757'
    },
    buttons: {
        blue: '#1277f0',
        grey: '#8C979D',
        lightGrey: '#EAF1F5',
        red: '#F85757',
        green: '#00A962',
    },
    primary: {
        light: '#788ad3e8',
        medium: '#2c3e87',
        dark: '#1f2c61',
    },
    shades: {
        white: '#ffffff',
        offWhite: '#FBFBFB',
        lighterGrey: '#DBE3E8',
        lightestGrey: '#f8f9fa',
        lightGrey: '#CCCCCC',
        grey: '#BBBBBB',
        darkGrey: '#747474',
        darkerGrey: '#85899C',
        darkestGrey: '#4A4A4A',
        lightBlueGray: '#B8CFDC4F',
        softBlue: '#6978B2',
        black: '#000000',
        orange: '#FAE9CA',
        darkOrange: '#F4D08E',
        green: '#00A962',
        yellow: '#FFD800',
        red: '#F85757'
    },
    secondary: {},
    danger: {
        medium: '#df6262',
        light: '#f9bdbd',
        dark: '#c83939',
    },
    success: {
        medium: '#00b300',
        light: '#bee3be',
        dark: '#317231'
    },
    warning: {
        medium: '#ff9100',
        light: '#ffdb579c',
        dark: '#bd9400'
    },
    backgrounds: {
        lightBlue: '#DBECF5',
        lightGrey: '#E3EFF5',
        cream: '#FAE9CA'
    },
    layout: {
        depth: {
            front: '3',
            middle: '2',
            back: '1',
        }
    },
    screen: {
        small: '500px',
        smallTablet: '750px',
        medium: '1000px',
        large: '1050px',
    },
    topBar: {
        height: '80px'
    },
    actionPlanZone: {
        green: '#4EE979',
        yellow: '#FCD95D',
        red: '#FF9292',
        darkRed: '#F85757',
        black: '#282727'
    },
    table: {
        borderDark: '#27282E',
        borderMedium: '#B7C2C9',
        borderLight: '#E1EBF0',
        backgroundDark: '#D9D9D9'
    }
};
