import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isSameMonth, isSameWeek } from 'date-fns';
import * as S from './index.styles';

type Props = {
    previousWeek: () => void;
    nextWeek: () => void;
    planStartDate: string;
    planEndDate: string | null;
    selectedWeekDates: Date[];
    isCurrentWeek: boolean;
}

const WeekSelection = ({
    previousWeek,
    nextWeek,
    planStartDate,
    planEndDate,
    selectedWeekDates,
    isCurrentWeek,
}: Props) => {
    const getWeekSelectionString = (startOfWeek: Date, endOfWeek: Date): string => {
    // if start and end of week dates are in different months, then return those 2 months in the string
        const sameMonthString = `${startOfWeek.toLocaleString('default', { month: 'short' })} ${startOfWeek.getDate()} - ${endOfWeek.getDate()}`;
        const differentMonthString = `${startOfWeek.toLocaleString('default', { month: 'short' })} ${startOfWeek.getDate()} - ${endOfWeek.toLocaleString('default', { month: 'short' })} ${endOfWeek.getDate()}`;
        return isSameMonth(startOfWeek, endOfWeek) ? sameMonthString : differentMonthString;
    };

    return (
        <S.WeekSelection>
            <S.ArrowButton
              onClick={previousWeek}
              visible={!isSameWeek(new Date(planStartDate), selectedWeekDates[0], { weekStartsOn: 1 })}
            >
                <FontAwesomeIcon icon={faChevronLeft as IconProp} />
            </S.ArrowButton>
            {isCurrentWeek
            ? <S.WeekString>This Week </S.WeekString>
            : <S.WeekString>{getWeekSelectionString(selectedWeekDates[0], selectedWeekDates[6])}</S.WeekString>}
            <S.ArrowButton
              onClick={nextWeek}
              visible
            >
                <FontAwesomeIcon icon={faChevronRight as IconProp} />
            </S.ArrowButton>
        </S.WeekSelection>
    );
};

export default WeekSelection;
