import { Buttons } from '@apps/common-ui';
import React from 'react';
import Mixpanel from '../../services/Mixpanel';
import { Form } from '../../types/models';
import * as S from './index.styles';

type Props = {
    forms: Form[]
}

const FormsList = ({ forms }: Props) => {
    const formatName = (name: string) => name.split(' - ')[0];

    return (
        <S.Container>
            {forms.map((form: Form) => (
                <S.FormCard key={form.jotformId}>
                    <S.FormCardImageContainer>
                        <S.FormCardImage src="/FormIcon.svg" />
                    </S.FormCardImageContainer>
                    <S.FormCardBody>
                        <S.FormDetails>
                            <h3>{formatName(form.name)}</h3>
                        </S.FormDetails>
                        <S.FormCardButton
                          onClick={() => Mixpanel.track(`web_form_started_${formatName(form.name)}`)}
                          to={`/forms/${form.jotformId}`}
                        >
                            Start form
                        </S.FormCardButton>
                    </S.FormCardBody>
                </S.FormCard>
            ))}
        </S.Container>
    );
};

export default FormsList;
