import { Buttons, Inputs } from '@apps/common-ui';
import styled from 'styled-components';

export const ModalContent = styled.div`
    padding: 30px 20px 20px;
    overflow-y: auto;

    @media(max-width: ${props => props.theme.screen.medium}) {
        min-height: 100vh;
        min-width: 100vw;
        padding-bottom: 50px;
    }
`;

export const Content = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;

    @media(max-width: ${props => props.theme.screen.medium}) {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
`;

export const TitleBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.shades.lightGrey};
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const Title = styled.h2`
    margin-top: 10px;
`;

export const CloseButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const CloseButton = styled(Buttons.Button)`
    display: flex;
    justify-content flex-start;
    align-items: flex-start;
    background: ${props => props.theme.shades.white};
    border-color: ${props => props.theme.shades.white};
    margin-top: 0px;
    margin-right: 0px;
    padding-right: 0px;

    p {
        margin-top: 0px;
        margin-bottom: 10px;
        color: ${props => props.theme.primary.medium}
    }

    svg {
        margin-top: 6px;
        color: ${props => props.theme.primary.medium}
    }
`;

export const Form = styled.form`
    flex-basis: 60%;
    width: 100%;
`;

export const FormContainer = styled.div`
    padding-bottom: 30px;
    border-bottom: 1px solid ${props => props.theme.shades.lightGrey};
`;

export const InputsRow = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 60px;
    row-gap: 30px;
    padding-top: 20px;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: space-between;
`;

export const InputContainerFlowRate = styled.div`
    display: flex;
    align-items: center; 
    gap: 40px; 
`;

export const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 50px;
`;

export const InputLabel = styled.label`
    font-weight: bold;
    margin-top: 0px;
`;

export const HelperText = styled.p`
    font-size: 14px;
    color: ${props => props.theme.shades.darkestGrey};
    margin: 0;
`;

export const InputDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 10px;
`;

export const MultiInputRow = styled.div`
    display: flex;
`;

export const Slash = styled.p`
    margin-right: 8px;
    padding-top: 10px;
    font-weight: bold;
`;

export const ErrorContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const ErrorMessage = styled.p`
    font-weight: bold;
    color: ${props => props.theme.text.error};
`;

export const SubmitRow = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-top: 20px;
    padding-right: 20px;
    margin-top: 20px;

    @media (max-width: ${props => props.theme.screen.medium}) {
        justify-content: center;
    }
`;

export const SubmitButton = styled(Buttons.Button)`
    width: 180px;
    text-align: center;
    white-space: nowrap;
`;

export const VitalTip = styled.div`
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
`;
