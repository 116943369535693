var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { theme } from '../theme';
var buttonStyles = {
    danger: {
        color: theme.text.error,
        borderColor: theme.text.error,
        background: theme.text.error,
    },
    warning: {
        color: theme.text.warning,
        borderColor: theme.text.warning,
        background: theme.text.warning,
    },
    success: {
        color: theme.text.success,
        borderColor: theme.text.success,
        background: theme.text.success,
    },
};
var buttonTypes = {
    // no background, just border
    secondary: {
        background: 'transparent',
        borderWidth: '2px',
        borderStyle: 'solid',
    },
    // no background, no border
    tertiary: {
        background: 'transparent',
        borderWidth: '2px',
        borderStyle: 'none',
        borderColor: 'transparent'
    },
};
var buttonSizes = {
    small: {
        fontSize: '0.8rem',
        padding: '0.5rem 1rem',
    },
    medium: {
        fontSize: '1rem',
        padding: '0.5rem 1rem',
    },
    large: {
        fontSize: '1.5rem',
        padding: '0.5rem 1rem',
    }
};
var defaultStyles = {
    color: theme.buttons.blue,
    borderColor: theme.buttons.blue,
    background: theme.buttons.blue,
    borderWidth: '2px',
    borderStyle: 'solid',
};
var getProperty = function (prop, props) {
    var style = defaultStyles[prop];
    if (props.buttonStyle) {
        style = buttonStyles[props.buttonStyle][prop];
    }
    if (props.buttonType && buttonTypes[props.buttonType][prop]) {
        style = buttonTypes[props.buttonType][prop];
    }
    if (prop === 'color' && !props.buttonType) {
        style = theme.text.white;
    }
    return style;
};
export var ButtonStyles = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  background: ", ";\n  font-size: ", ";\n  padding: ", ";\n  border-width: ", ";\n  border-color: ", ";\n  border-style: ", ";\n  border-radius: 6px;\n  margin: 5px;\n  cursor: pointer;\n  position: relative;\n  font-weight: 600;\n\n  &:hover {\n    opacity: 0.8;\n  }\n\n  svg:first-of-type {\n    margin-right: 5px;\n  }\n\n  svg:last-of-type {\n    margin-left: 5px;\n  }\n"], ["\n  color: ", ";\n  background: ", ";\n  font-size: ", ";\n  padding: ", ";\n  border-width: ", ";\n  border-color: ", ";\n  border-style: ", ";\n  border-radius: 6px;\n  margin: 5px;\n  cursor: pointer;\n  position: relative;\n  font-weight: 600;\n\n  &:hover {\n    opacity: 0.8;\n  }\n\n  svg:first-of-type {\n    margin-right: 5px;\n  }\n\n  svg:last-of-type {\n    margin-left: 5px;\n  }\n"])), function (props) { return getProperty('color', props); }, function (props) { return getProperty('background', props); }, function (props) { return (props.size ? buttonSizes[props.size].fontSize : buttonSizes.medium.fontSize); }, function (props) { return (props.size ? buttonSizes[props.size].padding : buttonSizes.medium.padding); }, function (props) { return getProperty('borderWidth', props); }, function (props) { return getProperty('borderColor', props); }, function (props) { return getProperty('borderStyle', props); });
// See here for the reason for this extra config:
// https://gist.github.com/meddokss/009cdb5192e487654c0d90cd8f692214
export var Button = styled.button.withConfig({
    shouldForwardProp: function (prop) { return !['buttonStyle', 'buttonType', 'size'].includes(prop); },
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n  &:disabled {\n    opacity: 0.6;\n    cursor: not-allowed;\n  }\n"], ["\n  ", "\n  &:disabled {\n    opacity: 0.6;\n    cursor: not-allowed;\n  }\n"])), ButtonStyles);
export var LinkButton = styled(Link)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  text-decoration: none;\n  font-weight: bold;\n"], ["\n  ", "\n  text-decoration: none;\n  font-weight: bold;\n"])), ButtonStyles);
export var FullWidthButtonStyles = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  width: 374px;\n  height: 55px;\n  align-self: center;\n  justify-content: center;\n  font-size: 1.1rem;\n  font-weight: bold;\n  line-height: 2.2;\n  @media (max-width: 780px) {\n    width: 250px;\n  }\n"], ["\n  display: flex;\n  width: 374px;\n  height: 55px;\n  align-self: center;\n  justify-content: center;\n  font-size: 1.1rem;\n  font-weight: bold;\n  line-height: 2.2;\n  @media (max-width: 780px) {\n    width: 250px;\n  }\n"])));
export var FullWidthButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), ButtonStyles, FullWidthButtonStyles);
export var FullWidthLinkButton = styled(Link)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), ButtonStyles, FullWidthButtonStyles);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
