import { Buttons, Card } from '@apps/common-ui';
import styled from 'styled-components';

interface ImageProps {
    bottomLeftRadius: boolean;
}

interface CurrentModuleProps {
    isCurrent: boolean;
}

export const ModuleCard = styled(Card)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 750px;
    min-height: 110px;
    max-height: 270px;
    padding: 0px;

    @media(max-width: ${props => props.theme.screen.medium}) {
        max-width: 90vw;
        max-height: 325px;
    }
`;

export const CardTop = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    min-height: 110px;
`;

export const CardTopLeft = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const ImageContainer = styled.div<ImageProps>`
    width: 80px;
    height: 100%;
    margin-right: 15px;
    border-top-left-radius: 9px;
    border-bottom-left-radius: ${props => props.bottomLeftRadius ? '9px' : ''};
    overflow: hidden;
`;

export const ModuleImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const ModuleInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const ModuleTitle = styled.p`
    color: ${props => props.theme.primary.medium};
    cursor: pointer;
    max-width: 300px;
`;

export const ModuleLessons = styled.p<CurrentModuleProps>`
    font-weight: ${props => props.isCurrent ? 'bold' : 'normal'};
    color: ${props => props.isCurrent ? props.theme.text.default : props.theme.buttons.grey};
    margin-top: 0px;
`;

export const CardTopRight = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 15px;
`;

export const CompletionDate = styled.p`
    color: ${props => props.theme.success.medium};
    font-weight: bold;
    margin-right: 25px;

    @media(max-width: ${props => props.theme.screen.smallTablet}) {
        display: none;
    }
`;

export const ViewButton = styled(Buttons.Button)`
    width: 100px;
    height: 45px;
    color: ${props => props.theme.buttons.blue};
    background: ${props => props.theme.shades.white};
    border-width: 2px;
    border-style: solid;
    border-color: ${props => props.theme.buttons.blue};
    
    @media(max-width: ${props => props.theme.screen.smallTablet}) {
        order: 2;
    }

    @media(max-width: ${props => props.theme.screen.small}) {
        display: none;
    }
`;

export const MobileCompletionIcon = styled.img`
    display: none;
    object-fit: contain;
    width: 30px;
    height: 30px;

    @media(max-width: ${props => props.theme.screen.smallTablet}) {
        order: 1;
        margin-right: 10px;
        display: block;
    }

    @media(max-width: ${props => props.theme.screen.small}) {
        margin-right: 0px;
    }

`;

export const DetailsAndTargetDateContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Details = styled.p`
    color: ${props => props.theme.buttons.blue};
    align-self: flex-end;
    margin-bottom: 0px;
    cursor: pointer;
    
    @media(max-width: ${props => props.theme.screen.small}) {
        display: none;
    }
`;

export const TargetDate = styled.p`
    color: ${props => props.theme.buttons.grey};

    @media(max-width: ${props => props.theme.screen.smallTablet}) {
        display: none;
    }
`;

export const LockedIcon = styled.img`
    object-fit: contain;
    width: 30px;
    height: 30px;
    margin-left: 20px;
`;

export const CardBottom = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const CurrentLessonBanner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 45px;
    background: #E3DCFA;
`;

export const CurrentLessonText = styled.p`
    font-weight: bold;
    margin-left: 20px;
`;

export const ModuleDetailsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;

    @media(max-width: ${props => props.theme.screen.small}) {
        padding-top: 20px;
        padding-bottom: 0px;
    }
`;

export const ModuleDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media(max-width: ${props => props.theme.screen.small}) {
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 20px;
        width: 100%;
    }
`;

export const SubModuleTitle = styled.p`
    font-weight: bold;
    margin-left: 20px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media(max-width: ${props => props.theme.screen.small}) {
        display: none;
    }
`;

const BaseReadButton = styled(Buttons.Button)`
    color: ${props => props.theme.shades.white};
    background: ${props => props.theme.buttons.blue};
    border: 2px solid ${props => props.theme.buttons.blue};
`;

export const ReadButton = styled(BaseReadButton)`
    margin-right: 15px;
    margin-bottom 0px;
    margin-top 0px;
    width: 150px;
    height: 45px;
`;

export const MobileReadButton = styled(BaseReadButton)`
    display: none;
    margin-right: 15px;
    margin-left: auto;
    width: 90px;

    @media(max-width: ${props => props.theme.screen.small}) {
        display: block;
    }
`;
