import { Card } from '@apps/common-ui';
import React from 'react';
import * as S from './index.styles';

const BreatherCard = () => (
    <S.BreatherCard>
        <S.CardHeader>
            <S.CardImage src="/BreatherImage.png" alt="Breather" />
            <S.Heading>Breather Cleaning Tips</S.Heading>
        </S.CardHeader>
        <p>To ensure a sanitary Breather, clean with warm soapy water daily, santize once a week, for more details
            <S.Link
              href="https://www.pnmedical.com/lessons/breather-fit-cleaning/"
              target="_blank"
              rel="noreferrer"
            >
                click here.
            </S.Link>
        </p>
    </S.BreatherCard>
);

export default BreatherCard;
