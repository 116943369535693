export var formatPhoneNumberForDisplay = function (phoneNumber) {
    if (!phoneNumber) {
        return '';
    }
    if (phoneNumber.length === 11) {
        return "(".concat(phoneNumber.slice(1, 4), ") ").concat(phoneNumber.slice(4, 7), "-").concat(phoneNumber.slice(7, 11));
    }
    var cleaned = ("".concat(phoneNumber)).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return "(".concat(match[1], ") ").concat(match[2], "-").concat(match[3]);
    }
    return phoneNumber;
};
