import { Buttons, Card } from '@apps/common-ui';
import styled from 'styled-components';

interface ButtonProps {
    isCompleted: boolean;
}

interface StatusProps {
    isLocked: boolean;
}

interface isCurrent {
    isCurrent: boolean;
}

export const SubmoduleCard = styled(Card)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 750px;
    max-height: 200px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;

    @media(max-width: ${props => props.theme.screen.medium}) {
        max-width: 90vw;
        max-height: 325px;
    }
`;

export const CurrentBanner = styled.div`
    display: flex;
    align-items: center;
    background: #E3DCFA;
    width: 100%;
    height: 45px;
    padding-left: 20px;
`;

export const CardContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;

    @media(max-width: ${props => props.theme.screen.small}){
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const DetailsColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 400px;
    height: 100%;

    @media(max-width: ${props => props.theme.screen.small}) {
        width: 100%;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;

    @media(max-width: ${props => props.theme.screen.small}) {
        width: 100%;
    }
`;

export const TitleImage = styled.img`
    width: 24px;
    height: 24px;
    object-fit: cover;
    margin-right: 10px;
`;

export const Title = styled.p`
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
    color: ${props => props.theme.primary.medium};
`;

export const MobileStatusIcon = styled.img`
    display: none;
    object-fit: contain;
    width: 30px;
    height: 30px;
    margin-left: auto;

    @media(max-width: ${props => props.theme.screen.small}) {
        display: block;
    }
`;

export const Description = styled.p<isCurrent>`
    font-weight: ${props => props.isCurrent ? 'bold' : 'normal'};
    margin-top: 0px;
    margin-bottom: ${props => props.isCurrent ? '35px' : '0px'};
    color: ${props => props.isCurrent ? props.theme.text.default : props.theme.buttons.grey};

    @media(max-width: ${props => props.theme.screen.small}) {
        margin-bottom: 0px;
    }
`;

export const ReadTime = styled.p`
    margin-top: 15px;
    margin-bottom: 0px;
`;

export const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    @media(max-width: ${props => props.theme.screen.small}) {
        display: none;
    }
`;

export const StatusIcon = styled.img<StatusProps>`
    object-fit: contain;
    width: 30px;
    height: 30px;
    margin-right: ${props => props.isLocked ? '0px' : '20px'};
`;

const BaseReadButton = styled(Buttons.Button)<ButtonProps>`
    color: ${props => props.isCompleted ? props.theme.buttons.blue : props.theme.shades.white};
    background: ${props => props.isCompleted ? props.theme.shades.white : props.theme.buttons.blue};
    border: ${props => props.theme.buttons.blue} 2px solid;
`;

export const ReadButton = styled(BaseReadButton)`
    width: 150px;
    height: 50px;

    
`;

export const MobileReadButton = styled(BaseReadButton)<ButtonProps>`
    display: none;
    width: 100%;
    margin-top: 20px;
    margin-left: 0px;

    @media(max-width: ${props => props.theme.screen.small}) {
        display: block;   
    }
`;
