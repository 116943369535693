import React from 'react';
import styled from 'styled-components';

type SpinnerProps = {
    small?: boolean
}

const SpinnerContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const SpinnerInner = styled.div<SpinnerProps>`
  border: ${props => props.small ? '3px' : '10px'} solid ${props => props.theme.background};
  border-top: ${props => props.small ? '3px' : '10px'} solid ${props => props.theme.primary.medium};
  border-radius: 50%;
  width: ${props => props.small ? '20px' : '50px'};
  height: ${props => props.small ? '20px' : '50px'};
  animation: spin 0.5s linear infinite;
`;

function Spinner({ small = false }: SpinnerProps) {
    return (
        <SpinnerContainer>
            <SpinnerInner small={small} />
        </SpinnerContainer>
    );
}

export default Spinner;
