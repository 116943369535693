import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

interface ButtonProps {
    canSubmit: boolean;
}

export const ModalContent = styled.div`
    width: 100%;
    max-height: 100vh;
    
    @media(max-width: ${props => props.theme.screen.medium}) {
        max-height: 90vh;
        width: 80vw;
    }

    @media(max-width: ${props => props.theme.screen.smallTablet}) {
        width: 90vw;
        height: 90vh;
    }

    @media(max-width: ${props => props.theme.screen.small}) {
        width: 100vw;
        height: 60vh;
    }
`;

export const ModalTop = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;

    @media(max-width: ${props => props.theme.screen.medium}) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

export const IndexContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    @media(max-width: ${props => props.theme.screen.small}) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const AudioIcon = styled.img`
    width: 70px;
    height: 70px;
    object-fit: contain;
    margin-right: 10px;

    @media(max-width: ${props => props.theme.screen.medium}) {
        margin-right: 20px;
    }
`;

export const ModuleLink = styled(Buttons.LinkButton)`
    color: ${props => props.theme.links.blue};
    text-decoration: ${props => `underline solid ${props.theme.links.blue}`};
    background: ${props => props.theme.shades.white};
    border: none;

    @media(max-width: ${props => props.theme.screen.small}) {
        padding-left: 0px;
        margin-left: 0px;
    }
`;

export const LessonIndex = styled.p`
    font-weight: bold;

    @media(max-width: ${props => props.theme.screen.small}) {
        margin-top: 0px;
    }
`;

export const CloseButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin-left: auto;
    height: 100%;
    align-self: flex-start;
`;

export const CloseButton = styled(Buttons.Button)`
    display: flex;
    flex-direction: row;
    justify-content flex-start;
    align-items: flex-start;
    background: ${props => props.theme.shades.white};
    border-color: ${props => props.theme.shades.white};
    margin-top: 0px;
    margin-right: 0px;
    padding-right: 0px;
    padding-top: 0px;

    p {
        margin-top: 0px;
        margin-bottom: 10px;
        color: ${props => props.theme.primary.medium}
    }

    svg {
        margin-top: 6px;
        color: ${props => props.theme.primary.medium}
    }
`;

export const VideoRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 800px;
    height: 440px;

    @media(max-width: ${props => props.theme.screen.medium}) {
        max-width: 100%;
    }

    @media(max-width: ${props => props.theme.screen.smallTablet}) {
        width: 100%;
        max-width: 750px;
    }

    @media(max-width: ${props => props.theme.screen.small}) {
        width: 100%;
        max-width: 410px;
        height: 215px;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
`;

export const CompleteButton = styled(Buttons.Button)<ButtonProps>`
    background: ${props => props.canSubmit ? props.theme.buttons.blue : props.theme.shades.grey};
    border: ${props => props.canSubmit ? props.theme.buttons.blue : props.theme.shades.grey} 2px solid;

    @media(max-width: ${props => props.theme.screen.medium}) {
        width: 100%;
    }
`;
