import { Card } from '@apps/common-ui';
import styled from 'styled-components';

export const VideoCallContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
`;

export const VideoCallElement = styled.div`
    width: 100%;
    height 50%;
    border: 1px solid black;

    
`;

export const VideoCall = styled(Card)`
    width: 100%;
    height: 90%;
    min-height: 40vh;
    max-width: 600px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
