import styled from 'styled-components';

export const SliderRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.shades.lightGrey};
    padding-bottom: 20px;
    margin-top: 15px;
`;

export const SliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;
    width: 100%;
    max-width: 300px;
`;

export const SliderLabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

export const LevelLabel = styled.p`
    font-weight: bold;
`;

export const SliderLabel = styled.p`
    font-weight: bold;
    margin-top: 8px;
`;

export const SeverityContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 15px;
    gap: 20px;
    width: 150px;
`;

export const EmojiContainer = styled.div`
    object-fit: contain;
    width: 45px;
    height: 45px;
`;

export const Emoji = styled.img`
    width: 45px;
    height: 45px;
`;

export const SeverityText = styled.p`
    margin-top: 5px;
    width: 50px;
    line-height: 18px;
`;
