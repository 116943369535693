import * as S from './index.styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';

type Props = {
    onClick: () => void;
}

const VitalTipInfo = ({ onClick }:Props) =>
{ 
    return (
        <>
        <S.Container>
            <S.HeaderRow>
                <S.Title>How to take your vitals</S.Title>
                <S.ButtonLink buttonType="tertiary" onClick={onClick} to=''>
                    <FontAwesomeIcon icon={faAngleUp as IconProp} />
                    Hide Tip
                </S.ButtonLink>
            </S.HeaderRow>
            <S.InfoContainer>
                <S.StepContainer>
                    <S.SubTitle>Step 1</S.SubTitle>
                    <S.TextDetail>
                        Click the power button quickly to shuffle through screens until you arrive at the following screen.
                    </S.TextDetail>
                    <S.Image src="/VitalTipStep1.svg" />
                </S.StepContainer>
                <S.StepContainer>
                    <S.SubTitle>Step 2</S.SubTitle>
                    <S.TextDetail>
                    Ensure this looks like a consistent wave, if it is not, give it a few seconds to level out, or switch fingers as your readings may not be accurate.
                    </S.TextDetail>
                    <S.Image src="/VitalTipStep2.svg" />
                </S.StepContainer>
                <S.StepContainer>
                    <S.SubTitle>Step 3</S.SubTitle>
                    <S.TextDetail>
                    Take your readings
                    </S.TextDetail>
                    <S.ImageWithOverlayLabel src="/VitalTipStep3.svg" />
                </S.StepContainer>
            </S.InfoContainer>
        </S.Container>
        </>
    );
};

export default VitalTipInfo;
