import React, { useEffect, useState } from 'react';
import ZoomMtgEmbedded from '@zoomus/websdk/embedded';
import * as S from './index.styles';
import { Buttons } from '@apps/common-ui';
import { useSearchParams } from 'react-router-dom';

const VideoCall = () => {
    const client = ZoomMtgEmbedded.createClient();
    const [hasPermissions, setHasPermissions] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [meetingInfo, setMeetingInfo] = useState<any>(null);

    useEffect(() => {
        const jwt = searchParams.get('jwt');
        const meetingId = searchParams.get('mn');
        const password = searchParams.get('pwd');
        if (!jwt || !meetingId || !password) {
            alert('Missing meeting info');
        }
        setMeetingInfo({
            signature: jwt,
            sdkKey: process.env.REACT_APP_ZOOM_CLIENT_ID,
            meetingNumber: meetingId,
            password,
            userName: 'Test user',
            userEmail: ''
        });
    }, [searchParams]);

    const getCameraAndMicPermissions = () => {
        // @ts-ignore
        navigator.permissions.query({ name: 'camera' }).then((cameraPermission) => {
            // @ts-ignore
            navigator.permissions.query({ name: 'microphone' }).then((microphonePermission) => {
                if (cameraPermission.state === 'granted' && microphonePermission.state === 'granted') {
                    setHasPermissions(true);
                } else {
                    navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(() => {
                        setHasPermissions(true);
                    }).catch((err) => {
                        console.log(err);
                    });
                }
            });
        });
    };

    useEffect(() => {
        getCameraAndMicPermissions();
    }, []);

    const startMeeting = () => {
        if (!meetingInfo) {
            return;
        }
        const meetingSDKElement = document.getElementById('meetingSDKElement');

        client.init({
            debug: true,
            zoomAppRoot: meetingSDKElement || undefined,
            language: 'en-US'
        });

        client.join(meetingInfo);
    };

    return (
        <S.VideoCallContainer>
            <S.VideoCall>
                <S.VideoCallElement id="meetingSDKElement" />
            </S.VideoCall>
            <Buttons.Button size="large" onClick={startMeeting}>Start Meeting</Buttons.Button>
        </S.VideoCallContainer>
    );
};

export default VideoCall;
