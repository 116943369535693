import { Modal, Inputs } from '@apps/common-ui';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as S from './index.styles';

type Props = {
    onClose: () => void;
    onSubmit: (startExercise: boolean) => void;
}

const ExerciseCompletionCheckModal = ({ onClose, onSubmit }: Props) => {
    const handleInputVitals = () => {
        onSubmit(false);
    };

    const handleStartExercise = () => {
        onSubmit(true);
    };

    return (
        <Modal showModal dismissModal={onClose} closeOnOutsideClick={false} showTopBar={false} minHeight="50px" maxHeight="100vh" maxWidth="1000px" padded={false} overflow="auto">
            <S.ModalContent>
                <S.TitleBar>
                    <S.TitleContainer>
                        <S.Title>Have you completed this exercise already?</S.Title>
                    </S.TitleContainer>
                    <S.CloseButtonContainer>
                        <S.CloseButton onClick={onClose}>
                            <FontAwesomeIcon icon={faX as IconProp} size="xs" /><p>Close</p>
                        </S.CloseButton>
                    </S.CloseButtonContainer>
                </S.TitleBar>
                <S.ButtonContainer>
                    <S.NoButton onClick={handleStartExercise}>
                        No, I am going to do it today
                    </S.NoButton>
                    <S.YesButton onClick={handleInputVitals}>
                        Yes, I&apos;ve already done this
                    </S.YesButton>
                </S.ButtonContainer>
            </S.ModalContent>
        </Modal>
    );
};

export default ExerciseCompletionCheckModal;
