import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Mixpanel from '../../../../services/Mixpanel';
import { isToday } from '../../utils';
import * as S from './index.styles';
import { isFuture, isPast } from 'date-fns';
import { ExerciseTypes } from '@apps/common-utilities';
import { is } from 'date-fns/locale';

type CardProps = {
    dateLabel: string;
    exerciseCountLabel: string;
    date: Date;
    status: ExerciseTypes.ExerciseWeekSummaryStatus;
    next: string;
    planCompleted?: boolean;
}

const UnavailableExercise = ({ dateLabel, exerciseCountLabel, date, status, next, planCompleted }: CardProps) => {
    const isLockedAndIncomplete = status === ExerciseTypes.ExerciseWeekSummaryStatus.BLOCKED_FROM_NEW_PLAN;
    const isIncompleteAndOutOfDate = status === ExerciseTypes.ExerciseWeekSummaryStatus.INCOMPLETE_NOT_AVAILABLE;
    return (
        <S.CardBody>
            <S.ExercisePreviewInfo>
                <S.CardDate>{dateLabel}</S.CardDate>
                <S.ExerciseCount>{exerciseCountLabel}</S.ExerciseCount>
            </S.ExercisePreviewInfo>
            <S.ButtonContainer>
                {isLockedAndIncomplete
            && (
                <S.NotCompleted>Removed for safety reasons</S.NotCompleted>
            )}
                {status === ExerciseTypes.ExerciseWeekSummaryStatus.COMPLETE && (
                <S.Completed><FontAwesomeIcon icon={faCircleCheck as IconProp} /> Completed</S.Completed>
            )}
                {isIncompleteAndOutOfDate && (
                <S.NotCompleted>Incomplete - Out of Date</S.NotCompleted>
            )}

            </S.ButtonContainer>
        </S.CardBody>
    );
};

const AvailableExercise = ({ dateLabel, exerciseCountLabel, date, status, next, planCompleted }: CardProps) => {
    const isIncompleteAndCompletable = !isToday(date)
        && (status === ExerciseTypes.ExerciseWeekSummaryStatus.INCOMPLETE_LATE
            || status === ExerciseTypes.ExerciseWeekSummaryStatus.INCOMPLETE_NOT_LATE);
    const isCompleteAndViewable = !isToday(date)
        && status === ExerciseTypes.ExerciseWeekSummaryStatus.COMPLETE;
    return (
        <S.CardBody>
            <S.ExercisePreviewInfo>
                <S.CardDate>{dateLabel}</S.CardDate>
                {!planCompleted && isIncompleteAndCompletable && (
                    <S.NotCompleted>Incomplete</S.NotCompleted>
                )}
                {!planCompleted && isCompleteAndViewable && (
                    <S.Completed><FontAwesomeIcon icon={faCircleCheck as IconProp} /> Completed</S.Completed>
                )}
                {(isToday(date) || isFuture(date) || planCompleted) && (
                <S.ExerciseCount>
                    {exerciseCountLabel}
                </S.ExerciseCount>
            )}
            </S.ExercisePreviewInfo>

            <S.ButtonContainer>
                <S.ViewButton
                  isCurrentDay={isToday(date)}
                  to={`${next}/${date.toISOString()}`}
                  onClick={() => Mixpanel.track('web_view_exercise_day', {
                    date: date.toString(),
                    exerciseCount: exerciseCountLabel.slice(exerciseCountLabel.length - 1, 1)
                  })}
                >
                    View
                </S.ViewButton>
            </S.ButtonContainer>
        </S.CardBody>
    );
};

type Props = {
    date: Date;
    exerciseCount: number;
    isBreather: boolean;
    status: ExerciseTypes.ExerciseWeekSummaryStatus;
    planCompleted: boolean;
}

const getDate = (date: Date): string => `${date.toLocaleString('default', { weekday: 'long' })}, ${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}`;

const WeekdayExerciseCard = ({ date, exerciseCount, isBreather, status, planCompleted }: Props) => {
    const getExerciseCountLabel = (): string => {
        if (isBreather) {
            return `${exerciseCount} ${exerciseCount > 1 ? 'Breather Sessions' : 'Breather Session'}`;
        }
        return `${exerciseCount} ${exerciseCount > 1 ? 'Exercises' : 'Exercise'}`;
    };

    const sharedProps: CardProps = {
        dateLabel: getDate(date),
        exerciseCountLabel: getExerciseCountLabel(),
        date,
        status,
        next: isBreather ? '/breather' : '/exercises',
        planCompleted
    };

    if (isToday(date)) {
        if (status === ExerciseTypes.ExerciseWeekSummaryStatus.COMPLETE) {
            return <UnavailableExercise {...sharedProps} />;
        }
        return <AvailableExercise {...sharedProps} />;
    } else if (isPast(date)) {
        if (!planCompleted && status !== ExerciseTypes.ExerciseWeekSummaryStatus.COMPLETE && status !== ExerciseTypes.ExerciseWeekSummaryStatus.INCOMPLETE_LATE) {
            return <UnavailableExercise {...sharedProps} />;
        }
        return <AvailableExercise {...sharedProps} />;
    } else {
        return <AvailableExercise {...sharedProps} />;
    }
};

export default WeekdayExerciseCard;
