import { configureStore, combineReducers, AnyAction } from '@reduxjs/toolkit';
import sessionReducer from './reducers/session';
import coachingReducer from './reducers/coaching';

const appReducer = combineReducers({
    session: sessionReducer,
    coaching: coachingReducer,
});

// Allows us to reset the whole state back to default when a logout action
// is triggered
const reducerProxy = (state: any, action: AnyAction) => {
    if (action.type === 'session/logout') {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

export const store = configureStore({
    reducer: reducerProxy,
    devTools: process.env.NODE_ENV !== 'production'
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
