var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import Card from '../../../Card';
import { Buttons, Inputs } from '../../../..';
export var TicketCard = styled(Card)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var SupportInput = styled(Inputs.Input)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: 100%;\n  margin-left: 0;\n  background-color: ", " ;\n"], ["\n  max-width: 100%;\n  margin-left: 0;\n  background-color: ", " ;\n"])), function (props) { return props.theme.buttons.lightGrey; });
export var InputLabel = styled(Inputs.Label)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 16px;\n  color: ", ";\n  width: auto;\n  margin-left: 0px;\n"], ["\n  font-size: 16px;\n  color: ", ";\n  width: auto;\n  margin-left: 0px;\n"])), function (props) { return props.theme.text.default; });
export var SupportTextArea = styled(Inputs.TextArea)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-width: 100%;\n  background-color: ", " ;\n"], ["\n  max-width: 100%;\n  background-color: ", " ;\n"])), function (props) { return props.theme.buttons.lightGrey; });
export var SupportInputContainer = styled(Inputs.InputContainer)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  align-items: flex-start;\n"], ["\n  align-items: flex-start;\n"])));
export var Actions = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-items: center;\n  @media (max-width: ", ") {\n    width: 100%\n  }\n"], ["\n  align-items: center;\n  @media (max-width: ", ") {\n    width: 100%\n  }\n"])), function (props) { return props.theme.screen.medium; });
export var SupportText = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 0;\n"], ["\n  margin-top: 0;\n"])));
export var TicketLabel = styled.p(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-weight: bold;\n  margin-bottom: 5px;\n  margin-top: 10px;\n"], ["\n  font-weight: bold;\n  margin-bottom: 5px;\n  margin-top: 10px;\n"])));
export var SubmitButton = styled(Buttons.Button)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin: 0;\n  @media (max-width: ", ") {\n    width: 100%;\n  }\n"], ["\n  margin: 0;\n  @media (max-width: ", ") {\n    width: 100%;\n  }\n"])), function (props) { return props.theme.screen.medium; });
export var ErrorMessage = styled.p(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 16px;\n"], ["\n  color: ", ";\n  font-size: 16px;\n"])), function (props) { return props.theme.danger.medium; });
export var IssueList = styled(Inputs.Select)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 50%;\n  margin: 10px 0;\n"], ["\n  width: 50%;\n  margin: 10px 0;\n"])));
export var IssueOption = styled.option(templateObject_12 || (templateObject_12 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
