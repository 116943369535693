import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    align-self: center;
    @media(max-width: ${props => props.theme.screen.medium}) {
        width: 95vw;
        height: 100vh;
        padding: 10px 20px 0px 20px;
    }
`;

export const TopBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    background: transparent;
`;

export const CloseButton = styled(Buttons.Button)`
    display: flex;
    flex-direction: row;
    justify-content flex-start;
    align-items: flex-start;
    background: ${props => props.theme.shades.white};
    border-color: ${props => props.theme.shades.white};
    margin-top: 0px;
    margin-right: 0px;
    padding-right: 0px;
    padding-top: 0px;

    p {
        margin-top: 0px;
        margin-bottom: 10px;
        color: ${props => props.theme.primary.medium}
    }

    svg {
        margin-top: 6px;
        color: ${props => props.theme.primary.medium}
    }

    @media(max-width: ${props => props.theme.screen.medium}) {
        margin-right: 15px;
    }
`;

export const AppointmentTitle = styled.h2`
    display: block;
    align-items: start;
    width: 100%;
    max-width: 480px;
    margin-bottom: 10px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
`;

export const PopupDivider = styled.hr`
    margin: 10px 0;
    border-style: solid;
    border-color: ${props => props.theme.shades.lighterGrey};
    border-width: 1px 0 0 0;
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
`;

export const AppointmentIcon = styled.img`
    flex-shrink: 0;
    object-fit: contain;
    width: 79px;
    height: auto;

`;

export const AppointmentDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    flex-grow: 1;
`;

export const DateContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
`;

export const CalendarIcon = styled.img`
    object-fit: contain;
    width: 18px;
    height: 18px;
    align-self: center;
`;

export const MeetingTime = styled.p`
    margin: 0px;
    font-size: 18px;
`;

export const MeetingDate = styled.p`
    margin: 0px;
    font-size: 18px;
`;

export const AppointmentButton = styled(Buttons.Button)`
  text-align: center;
  width: 185px;
  font-size: 18px;
  margin: 0;
  
  &:disabled {
    background: ${props => props.theme.buttons.grey};
    border-color:${props => props.theme.buttons.grey};
}
`;

export const ButtonDetails = styled.p`
    font-size: 18px;
    margin-top: 0;
    max-width: 275px;
`;

export const ZoomTestContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const AppointmentText = styled.p`
    font-size: 18px;
`;

export const MeetingTitle = styled.h4`
    margin: 0px 0px 20px 0px;
    text-align: center;
`;

export const HelpButton = styled(Buttons.Button)`
    display: flex;
    padding: 0;
    margin-top: 18px;
    background: transparent;
    color: ${props => props.theme.buttons.blue};
    border: none;
    font-weight: bold;
`;
