import { Card } from '@apps/common-ui';
import styled from 'styled-components';

export const Heading = styled.h3`
  margin: 10px 0;
  font-weight: bold;
`;

export const CardImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  border-radius: 10px;
  margin-right: 20px;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const Link = styled.a`
  margin-left: 5px;
`;

export const BreatherCard = styled(Card)`
  border: none;
`;
