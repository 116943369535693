import { Routes } from '../api/Routes';
import { TenantApi } from '../api/UsersApi';

class PostEvents {
    async postZoomEvent(
        eventType: string
    ): Promise<boolean> {
        const eventData = {
            event: eventType,
            description: null,
        };
        return TenantApi.post(`${Routes.events.onboarding}`, eventData, { skipDeduplication: true });
    }
}

export default new PostEvents();
