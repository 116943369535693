import React, { useEffect, useState } from 'react';
import { ReactComponent as MailSuccess } from '../../../assets/MailSuccess.svg';
import { ReactComponent as NoData } from '../../../assets/NoData.svg';
import * as S from './index.styles';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Routes } from '../../../api/Routes';
import Spinner from '@apps/common-ui/src/Spinner';
import { TenantApi } from '../../../api/UsersApi';

const EmailVerification = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [verified, setVerified] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        // Async function defined inside since useEffect function shouldn't be async
        const verifyToken = async (verificationToken: string) => {
            try {
                const res = await TenantApi.post(
                    Routes.emailVerification.verify,
                    { token: verificationToken },
                    { skipAuth: true }
                );
                setVerified(true);
            } catch (err: any) {
                // 404 means the token doesn't exist/has been used which
                // means the user has already been verified
                if (err.statusCode !== 404) {
                    setError(true);
                }
            }
            setLoading(false);
        };

        const token = searchParams.get('token');
        if (token && !verified) {
            verifyToken(token);
        }
    }, [searchParams]);

    if (loading) {
        return (
            <S.Container>
                <Spinner />
            </S.Container>
        );
    }

    return (
        <S.Container>
            {error
                ? (
                    <>
                        <NoData width="250px" height="250px" />
                        <S.Error>{t('emailVerification.emailVerifiedError')}</S.Error>
                        <a href="mailto:coaching@breathesuite.com">
                            {t('emailVerification.emailVerifiedContactUs')}
                        </a>
                    </>
                )
                : (
                    <>
                        <MailSuccess width="250px" height="250px" />
                        <S.Header>{t('emailVerification.emailVerified')}</S.Header>
                        <S.Text>{t('emailVerification.emailVerifiedText')}</S.Text>
                    </>
                )}
        </S.Container>
    );
};

export default EmailVerification;
