import React, { useEffect, useState } from 'react';
import * as S from '../index.styles';
import { getTime, enableJoinButton, timeUntilAppointment } from '../utils';
import { Appointment, AppointmentContactType } from '../../../types/models';
import Mixpanel from '../../../services/Mixpanel';

type Props = {
  appointment: Appointment
  showCardBanner: boolean
  setAppointment: (appointment: Appointment) => void
  openAppointmentPopup: () => void
  isToday: boolean
}

const AppointmentCard = ({
    appointment,
    showCardBanner,
    setAppointment,
    openAppointmentPopup,
    isToday
}: Props) => {
    const [error, setError] = useState('');
    const isPhoneCall: boolean = appointment.contactType === AppointmentContactType.PhoneCall;
    const [isDisabled, setIsDisabled] = useState(true);
    const [appointmentCountdown, setAppointmentCountdown] = useState(timeUntilAppointment(appointment));

    useEffect(() => {
        const checkAndEnableButton = () => {
            if (enableJoinButton(appointment.date)) {
                setIsDisabled(false);
            }
        };
        const updateCountdown = () => {
            setAppointmentCountdown(timeUntilAppointment(appointment));
        };
        checkAndEnableButton();
        updateCountdown();

        // Checks every 1 minute
        const interval = setInterval(() => {
            checkAndEnableButton();
            updateCountdown();
        }, 60000);
        return () => clearInterval(interval);
    }, [appointment]);

    return (
        <S.AppointmentCard key={appointment.id} isToday={isToday}>
            <S.CardInfo>
                <S.AppointmentDetails>
                    <S.Time>
                        <S.AppointmentIcon src="/TimeIconGrey.svg" />
                        {getTime(appointment.date)}
                        {showCardBanner && (`, ${appointmentCountdown}`)}
                    </S.Time>
                    <S.AppointmentTitle>
                        {appointment.appointmentTypeName} with {appointment.coachName}
                    </S.AppointmentTitle>
                    <S.Length>
                        <S.AppointmentIcon
                          src={isPhoneCall ? '/AppointmentPhoneCall.svg' : '/AppointmentVideoCall.svg'}
                        />
                        {appointment.length} minute {isPhoneCall ? 'Phone' : 'Zoom video'} call
                    </S.Length>
                </S.AppointmentDetails>
                <S.ButtonContainer>
                    {showCardBanner && !isPhoneCall && (
                    <S.JoinAppointmentButton
                      disabled={isDisabled}
                      onClick={() => {
                        Mixpanel.track(`web_zoom_appointment_joined_${appointment.appointmentTypeName}`);
                        window.open(appointment.joinUrl, '_blank');
                    }}
                    >
                        Join Meeting
                    </S.JoinAppointmentButton>
                    )}
                    <S.AppointmentDetailsButton
                      buttonType="secondary"
                      onClick={() => {
                              Mixpanel.track(`web_appointment_details_${appointment.appointmentTypeName}`);
                              if (isPhoneCall || appointment.isZoom) {
                                  setAppointment(appointment);
                                  openAppointmentPopup();
                              } else {
                                  setError('Appointment not available. Please contact us to resolve the issue.');
                              }
                          }}
                    >
                        Appointment Details
                    </S.AppointmentDetailsButton>
                </S.ButtonContainer>

            </S.CardInfo>
            {error && <S.JoinError>{error}</S.JoinError>}
        </S.AppointmentCard>
    );
};

export default AppointmentCard;
