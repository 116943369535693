import { Modal } from '@apps/common-ui';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as S from './index.styles';
import { RootState } from '../../../../../state/store';
import { useSelector } from 'react-redux';

type Props = {
    onClose: () => void;
    onSubmit: () => void;
}

const PreExerciseChecklist = ({ onClose, onSubmit }: Props) => {
    const { exercisePlan } = useSelector((state: RootState) => state.coaching);

    return (
        <Modal showModal dismissModal={onClose} closeOnOutsideClick={false} showTopBar={false} maxHeight="100vh" maxWidth="1200px" padded={false} overflow="auto">
            <S.ModalContent>
                <S.TitleBar>
                    <S.TitleContainer>
                        <S.Title>Pre Exercise Checklist</S.Title>
                    </S.TitleContainer>
                    <S.CloseButtonContainer>
                        <S.CloseButton onClick={onClose}><FontAwesomeIcon icon={faX as IconProp} size="xs" /><p>Close</p></S.CloseButton>
                    </S.CloseButtonContainer>
                </S.TitleBar>
                {!exercisePlan?.complete ? (
                    <>
                        <S.ChecklistMessage>Before you start exercising, let&apos;s get a few things in order</S.ChecklistMessage>
                        <S.ListContainer>
                            <S.ListTitle>Checklist</S.ListTitle>
                            <S.ListItem>1. Have your phone nearby</S.ListItem>
                            <S.ListItem>2. Pulse oximeter</S.ListItem>
                            <S.ListItem>3. Blood pressure cuff</S.ListItem>
                            <S.ListItem>4. Any exercise equipment you may need</S.ListItem>
                            <S.ListItem>5. Water (stay hydrated)!</S.ListItem>
                            <S.ListItem>6. Oxygen (if needed). Make sure you have a full tank!</S.ListItem>
                        </S.ListContainer>
                    </>
                )
                : (
                    <S.ListContainer>
                        <S.ListTitle>Quick Reminder:</S.ListTitle>
                        <S.ListItem>1. Check your rest vitals before you start.</S.ListItem>
                        <S.ListItem>2. Keep an eye on your heart rate and oxygen with your pulse oximeter.</S.ListItem>
                        <S.ListItem>3. If you see an SpO2 value below 90% take the time to recover.</S.ListItem>
                        <S.ListItem>4. Happy Exercising!</S.ListItem>
                    </S.ListContainer>
                )}
                <S.ButtonContainer>
                    <S.VitalsButton onClick={onSubmit}>{!exercisePlan?.complete ? 'Take Resting Vitals' : 'Start Exercise'}</S.VitalsButton>
                </S.ButtonContainer>
            </S.ModalContent>
        </Modal>
    );
};

export default PreExerciseChecklist;
