import styled from 'styled-components';

export const TitleContainer = styled.div`
    display: flex;
    margin-bottom: 30px;
    width: 100%;
`;

export const TitleImageContainer = styled.div`
    width: 85px;
    height: 85px;
    border-radius: 7px;
    margin-right: 15px;
    overflow: hidden;
`;

export const TitleImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const TitleTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const Title = styled.h2`
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 600;
`;