var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var ProgressBarBackground = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: 30px;\n  background-color: ", ";\n  border-radius: ", ";\n"], ["\n  position: relative;\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: 30px;\n  background-color: ", ";\n  border-radius: ", ";\n"])), function (props) { return props.backgroundColor ? props.backgroundColor : props.theme.shades.lighterGrey; }, function (props) { return props.borderRadius ? props.borderRadius : '10px'; });
export var ProgressBarFill = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: ", "%;\n  height: 100%;\n  background-color: ", ";\n  border-radius: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  width: ", "%;\n  height: 100%;\n  background-color: ", ";\n  border-radius: ", ";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])), function (props) { return props.percentage; }, function (props) { return props.color || props.theme.primary.medium; }, function (props) { return props.borderRadius ? props.borderRadius : '10px'; });
export var ProgressBarTextSpan = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 12px;\n  font-weight: 600;\n  line-height: 20px;\n  padding: 0 5px;\n"], ["\n  color: ", ";\n  font-size: 12px;\n  font-weight: 600;\n  line-height: 20px;\n  padding: 0 5px;\n"])), function (props) { return props.theme.text.white; });
export var ProgressBarTextP = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position absolute;\n  left: 30%;\n  font-size: 12px;\n  font-weight: bold;\n  margin: 0px;\n  color: ", ";\n"], ["\n  position absolute;\n  left: 30%;\n  font-size: 12px;\n  font-weight: bold;\n  margin: 0px;\n  color: ", ";\n"])), function (props) { return props.theme.text.white; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
