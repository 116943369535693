import styled from 'styled-components';
import { Page } from '../../components/Page';

export const Container = styled(Page)`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: ${props => props.theme.screen.smallTablet}) {
        width: 90vw;
    }
`;

export const Heading = styled.h1`
    margin: 0;
    font-weight: normal;
`;

export const SubHeading = styled.h4`
    margin: 5px 0 30px 0;
    font-weight: normal;
`;

export const HelpLink = styled.div`
    width: 100%;
    padding: 15px 0;

    a {
        padding-left: 0;
    }
`;
