import { Card } from '@apps/common-ui';
import styled from 'styled-components';

interface Props {
    isToday?: boolean;
}

export const Section = styled.section<Props>`
    margin: 30px 0;
    border-radius: 9px;
`;

export const MonthHeading = styled.h1`
    font-weight: 600;
    margin: 0;
    color: ${props => props.theme.shades.softBlue};
`;

export const NoAppointmentCard = styled(Card)`
    display: flex;
    flex-direction: column;
    width: 680px;

    @media (max-width: ${props => props.theme.screen.smallTablet}) {
        width: 100%;
    }
`;

export const NoAppointmentTitle = styled.h3`
    margin: 0;
    margin-bottom: 15px;
`;

export const NoAppointmentText = styled.p`
    font-size: 18px;
    margin: 0;
    color: ${props => props.theme.text.slateGray};
`;

export const DateHeading = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  margin-bottom: 20px;
  background-color: ${props => props.theme.shades.softBlue};
  border-radius: 9px;
  font-size: 16px;
  color: ${props => props.theme.shades.white};
`;
