import Auth from '@aws-amplify/auth';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Mixpanel from '../services/Mixpanel';
import { fetchActionPlan, fetchAppointments, fetchCourse, fetchExercisePlan, fetchForms } from '../state/reducers/coaching/actions';
import { fetchUser } from '../state/reducers/session/actions';
import { RootState } from '../state/store';
import { useActionLoader } from './useActionLoader';

const useInitializeApp = () => {
    const { user } = useSelector((state: RootState) => state.session);
    const { callAction: getTenantUser, done: tenantUserDone, loading: tenantUserLoading } = useActionLoader(fetchUser);
    const { callAction: getAppointments, done: appointmentsDone, loading: appointmentsLoading } = useActionLoader(fetchAppointments);
    const { callAction: getExercisePlan, done: exercisePlanDone, loading: exercisePlanLoading } = useActionLoader(fetchExercisePlan);
    const { callAction: getForms, done: formsDone, loading: formsLoading } = useActionLoader(fetchForms);
    const { callAction: getCourse, done: courseDone, loading: courseLoading } = useActionLoader(fetchCourse);
    const { callAction: getActionPlan, done: getActionPlanDone, loading: getActionPlanLoading } = useActionLoader(fetchActionPlan);

    useEffect(() => {
        if (!user) return;
        Mixpanel.identify(user.id);
        if (!tenantUserDone && !tenantUserLoading) {
            Auth.currentAuthenticatedUser().then((authData) => {
                if (authData) {
                    getTenantUser({ userId: authData.username });
                }
            }).catch(e => e);
        }
        if (!appointmentsDone && !appointmentsLoading) {
            getAppointments({ userId: user.id });
        }
        if (!formsDone && !formsLoading) {
            getForms({ userId: user.id });
        }
        if (!exercisePlanDone && !exercisePlanLoading) {
            getExercisePlan({ userId: user.id });
        }
        if (!courseDone && !courseLoading) {
            getCourse({ userId: user.id });
        }
        if (!getActionPlanDone && !getActionPlanLoading) {
            getActionPlan({ userId: user.id });
        }
    }, [user]);
};

export default useInitializeApp;
