import { LearningTypes } from '../..';
import { ExerciseEquipment, ExerciseType, ExerciseCategory } from './exerciseTypes';

export const equipment = (key: keyof typeof ExerciseEquipment): string => {
    const labelMap = {
        [ExerciseEquipment.WEIGHTS]: 'Weights',
        [ExerciseEquipment.BANDS]: 'Bands',
        [ExerciseEquipment.CHAIR]: 'Chair',
        [ExerciseEquipment.ASSISTED_DEVICES]: 'Assisted Devices',
        [ExerciseEquipment.STEP_STAIRS]: 'Step/Stairs'
    };
    return labelMap[key];
};

export const exerciseType = (key: keyof typeof ExerciseType): string => {
    const labelMap = {
        [ExerciseType.SETS_AND_REPS]: 'Sets and Reps',
        [ExerciseType.INTERVALS]: 'Interval',
        [ExerciseType.HOLD]: 'Hold',
        [ExerciseType.SETS_AND_BREATHS]: 'Sets and Breaths'
    };
    return labelMap[key];
};

export const exerciseCategory = (key: keyof typeof ExerciseCategory): string => {
    const labelMap = {
        [ExerciseCategory.STRENGTH]: 'Strength',
        [ExerciseCategory.CARDIO]: 'Cardio',
        [ExerciseCategory.FLEXIBILITY]: 'Flexibility',
        [ExerciseCategory.BREATHING]: 'Breathing',
        [ExerciseCategory.RMT]: 'RMT Breather'
    };
    return labelMap[key];
};

export const pauseReason = (key: keyof typeof LearningTypes.CourseUserPauseReason): string => {
    const labelMap = {
        [LearningTypes.CourseUserPauseReason.NON_PAYMENT]: 'Non Payment',
        [LearningTypes.CourseUserPauseReason.ILLNESS]: 'Illness'
    };
    return labelMap[key];
};
