import { Buttons, Card } from '@apps/common-ui';
import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const FormCard = styled(Card)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0;

    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const FormCardImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 100%;
    background-color: ${props => props.theme.shades.lighterGrey};
    overflow: hidden;

    @media (max-width: ${props => props.theme.screen.small}) {
        width: 100px;
    }
`;

export const FormCardImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const FormCardBody = styled.div`
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    width: 610px;

    @media (max-width: ${props => props.theme.screen.medium}) {
        width: 100%;
    }
`;

export const FormDetails = styled.div`
    h3 {
        margin: 5px 0;
    }
`;

export const FormCardButton = styled(Buttons.LinkButton)`
    height: 44px;
    white-space: nowrap;
`;
