import { Modal, VideoEmbed } from '@apps/common-ui';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import * as S from './index.styles';
import Vimeo from '@vimeo/player';
import { useActionLoader } from '../../../../hooks/useActionLoader';
import { createLessonSubmission, fetchCourse } from '../../../../state/reducers/coaching/actions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { LearningTypes } from '@apps/common-utilities';
import Mixpanel from '../../../../services/Mixpanel';

type Props = {
    onDismiss: () => void;
    moduleId: number;
    videoUrl: string;
    moduleNumber: number;
    moduleLessonCount: number;
    lessonNumber: number;
    lessonId: number;
    alreadySubmitted: boolean;
}

const AudioModal = ({ onDismiss, moduleId, videoUrl, moduleNumber, moduleLessonCount, lessonNumber, lessonId, alreadySubmitted }: Props) => {
    const [canSubmit, setCanSubmit] = useState(false);
    const navigate = useNavigate();
    const { tenantUser } = useSelector((state: RootState) => state.session);

    const { callAction: submitLesson, done: submissionDone, error: submissionError } = useActionLoader(createLessonSubmission, {
        successMessage: 'Lesson Submitted',
        errorMessage: 'Submission Error'
    });

    const { callAction: refreshCourse, done: courseRefreshDone } = useActionLoader(fetchCourse);

    useEffect(() => {
        const iframe = document.querySelector('iframe');
        const player = new Vimeo(iframe || '');

        // allow button to be clicked when there's 10 or less seconds remaining to the video
        player.on('timeupdate', (data) => {
            if (data.duration - data.seconds <= 10) {
                setCanSubmit(true);
            }
        });

        // remove event listener on cleanup
        return () => player.off('timeupdate');
    }, []);

    const onSubmit = () => {
        if (canSubmit) {
            if (!tenantUser) {
                return;
            }

            if (alreadySubmitted) {
                navigate(`/learning/modules/${moduleId}`);
            } else {
                const submissionBody: LearningTypes.IUserLessonSubmission = {
                    lessonId,
                    completedAt: new Date(),
                    completionType: LearningTypes.LessonCompletionType.LISTENED
                };
                submitLesson({ userId: tenantUser.id, lessonSubmission: submissionBody });
            }
        }
    };

    if (courseRefreshDone) {
        navigate(`/learning/modules/${moduleId}`);
    }

    useEffect(() => {
        if (!tenantUser || submissionError) {
            return;
        }
        if (submissionDone) {
            refreshCourse({ userId: tenantUser.id });
        }
    }, [submissionDone]);

    return (
        <Modal showModal dismissModal={onDismiss} showTopBar={false} showTranslucentBackground closeOnOutsideClick={false} minWidth="40%" maxWidth="950px" maxHeight="750px">
            <S.ModalContent>
                <S.ModalTop>
                    <S.AudioIcon src="/ListenIcon.svg" />
                    <S.IndexContainer>
                        <S.ModuleLink onClick={() => Mixpanel.track('web_audio_modal_module_link')} to={`/learning/modules/${moduleId}`}>Module {moduleNumber}</S.ModuleLink>
                        <S.LessonIndex>Lesson {lessonNumber} of {moduleLessonCount}</S.LessonIndex>
                    </S.IndexContainer>
                    <S.CloseButtonContainer>
                        <S.CloseButton
                          onClick={() => {
                            Mixpanel.track('web_audio_modal_close_button');
                            onDismiss();
                          }}
                        >
                            <FontAwesomeIcon icon={faX as IconProp} size="xs" />
                            <p>Close</p>
                        </S.CloseButton>
                    </S.CloseButtonContainer>
                </S.ModalTop>
                <S.VideoRow>
                    <VideoEmbed src={videoUrl} title="LessonAudio" />
                </S.VideoRow>
                <S.ButtonContainer>
                    <S.CompleteButton
                      canSubmit={canSubmit}
                      onClick={() => {
                        Mixpanel.track('web_audio_modal_complete_button');
                        onSubmit();
                      }}
                    >
                        {alreadySubmitted ? 'Continue' : 'Complete & Continue'}
                    </S.CompleteButton>
                </S.ButtonContainer>
            </S.ModalContent>
        </Modal>
    );
};

export default AudioModal;
