import { Buttons, Inputs, theme } from '@apps/common-ui';
import styled from 'styled-components';

export const ModalContent = styled.div`
    height: 700px;
    padding: 20px 40px 30px;
    overflow-y: auto;

    @media(max-width: ${props => props.theme.screen.medium}) {
        min-width: 100vw;
        min-height: 100vh;
        padding-bottom: 50px;
    }
`;

export const TitleBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.shades.lightGrey};
    width: 100%;
    padding: 10px 0;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const Title = styled.h3`
    margin-top: 10px;
`;

export const CloseButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const CloseButton = styled(Buttons.Button)`
    display: flex;
    justify-content flex-start;
    align-items: flex-start;
    background: ${props => props.theme.shades.white};
    border-color: ${props => props.theme.shades.white};
    margin-top: 0px;
    margin-right: 0px;
    padding-right: 0px;

    p {
        margin-top: 0px;
        margin-bottom: 10px;
        color: ${props => props.theme.primary.medium}
    }

    svg {
        margin-top: 6px;
        color: ${props => props.theme.primary.medium}
    }
`;

export const Content = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;

    @media(max-width: ${props => props.theme.screen.medium}) {
        width: 100%;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;

export const Form = styled.form`
    flex-basis: 60%;
    width: 100%;
`;

export const InputsRow = styled.div`
    display: flex;
    width: 100%;
    column-gap: 60px;
    row-gap: 30px;
    flex-wrap: wrap;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const InputContainerFlowRate = styled.div`
    display: flex;
    align-items: center;
    gap: 40px;
`;

export const InputLabel = styled.p`
    font-weight: bold;
`;

export const HelperText = styled.p`
    font-size: 14px;
    color: ${props => props.theme.shades.darkestGrey};
    margin: 0;
`;

export const BaselineInput = styled(Inputs.Input)`
    margin-left: 0px;
`;

export const FormContainer = styled.div`
    padding-bottom: 30px;
    border-bottom: 1px solid ${props => props.theme.shades.lightGrey};
`;

export const ErrorContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const ErrorMessage = styled.p`
    font-weight: bold;
    color: ${props => props.theme.text.error};
`;

export const CompletionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid ${props => props.theme.shades.lightGrey};
    width: 100%;
    margin-top: 15px;
`;

export const CompleteLabel = styled.p`
    font-weight: bold;
`;

export const SetsRepsRow = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 20px;
    flex-wrap: wrap;
`;

export const SetsRepsContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const SetsLabel = styled.p`
    font-weight: bold;
    margin-right: 15px;
`;

export const RepsLabel = styled.p`
    margin-left: 10px;
`;

export const SubmitRow = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
`;

export const SubmitButton = styled(Buttons.Button)`
    width: 180px;
    text-align: center;
    white-space: nowrap;
    margin-top: 20px;
`;
export const Tips = styled.div`
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
`;

export const TipsInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const CommentContainer = styled.div`
    align-items: flex-start;
`;

export const CommentLabelContainer = styled.div`
    display: flex;
    align-items: flex-start;
    margin: 30px 0 10px;
`;

export const CommentLabel = styled.span`
    font-weight: bold;
`;

export const CommentOptionalLabel = styled.span`
    margin-left: 4px;
`;

export const CommentTextBox = styled.textarea`
    width: 100%;
    max-width: 100%;
    min-width: 10%;
    min-height: 100px;
    padding: 8px;
    font-size: 16px;
    border-radius: 3px;
    border: 1px solid ${props => props.theme.shades.lighterGrey};
`;
