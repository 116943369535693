import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';
import { Page } from '../../../components/Page';

export const Container = styled(Page)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: ${props => props.theme.screen.medium}) {
        padding-top: 0;
    }
`;

export const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 50px;
    background: ${props => props.theme.primary.medium};
`;

export const BackButton = styled(Buttons.Button)`
    color: ${props => props.theme.shades.white};
    background: ${props => props.theme.primary.medium};
    border: ${props => props.theme.primary.medium} 1px solid;
    margin-left: 30px;
    padding-left: 0px;
    svg {
        margin-left: 0px !important;
        color: ${props => props.theme.shades.white};
    }
`;

export const PageContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 800px;
    height: 100%;
    margin-bottom: 100px;

    figure {
        margin: 0px !important;
    }

    @media(max-width: ${props => props.theme.screen.medium}) {
        width: 100vw;
        padding-left: 20px;
        padding-right: 20px;
    }
`;

export const IndexContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: ${props => `1px solid ${props.theme.shades.lightGrey}`};
    padding-bottom: 20px;

    @media(max-width: ${props => props.theme.screen.medium}) {
        flex-wrap: wrap;
    }
`;

export const ModuleLink = styled(Buttons.LinkButton)`
    color: ${props => props.theme.links.blue};
    text-decoration: ${props => `underline solid ${props.theme.links.blue}`};
    background: ${props => props.theme.background};
    border: none;
    padding-left: 0px;
    padding-top: 0px;
    margin-left: 0px;
    margin-top: 30px;

    @media(max-width: ${props => props.theme.screen.small}) {
        width: 100%;
        margin-bottom: 0px;
    }
`;

export const LessonIndex = styled.p`
    margin-top: 30px;
    font-weight bold;

    @media(max-width: ${props => props.theme.screen.small}) {
        margin-top: 0px;
    }
`;

export const LessonTitle = styled.h1`
    font-weight: normal;
    width: 100%;
`;

export const ReadTime = styled.p`

`;

export const ListenContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: ${props => props.theme.backgrounds.lightGrey};
    width: 100%;
    height: 150px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 50px;
    border-radius: 16px;

    @media(max-width: ${props => props.theme.screen.small}) {
        height: 100px;
    }
`;

export const NoAudioContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: ${props => props.theme.backgrounds.lightGrey};
    width: 100%;
    height: 60px;
    padding: 0px 20px;
    margin-bottom: 50px;
    border-radius: 16px;

    @media(max-width: ${props => props.theme.screen.small}) {
        height: 100px;
    }
`;

export const ListenContainerLeft = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
`;

export const ListenIcon = styled.img`
    object-fit: cover;
    width: 70px;
    height: 70px;
    margin-right: 20px;

    @media(max-width: ${props => props.theme.screen.small}) {
        width: 55px;
        height: 55px;
    }
`;

export const ListenText = styled.h2`
    width: 150px;
    line-height: 28px;

    @media(max-width: ${props => props.theme.screen.small}) {
        display: none;
    }
`;

export const ListenContainerRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
`;

export const ListenButton = styled(Buttons.Button)`
    color: ${props => props.theme.shades.white};
    background: ${props => props.theme.buttons.blue};
    border-width: 2px;
    border-style: solid;
    border-color: ${props => props.theme.buttons.blue};
    width: 155px;
    height: 50px;
`;

export const LessonContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    * {
        font-family: inherit !important;
        margin-top: 0px;
        margin-bottom: 5px;
    }
`;

export const CompleteButton = styled(Buttons.Button)`
    color: ${props => props.theme.shades.white};
    background: ${props => props.theme.buttons.blue};
    border-width: 2px;
    border-style: solid;
    border-color: ${props => props.theme.buttons.blue};
    width: 250px;
    height: 50px;
    margin-top: 50px;
    margin-left: 0px;

    @media(max-width: ${props => props.theme.screen.small}) {
        width: 100%;
    }
`;
