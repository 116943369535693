import { theme } from '@apps/common-ui';
import { LearningTypes } from '@apps/common-utilities';
import React from 'react';
import { useSelector } from 'react-redux';
import * as S from './index.styles';

interface Props {
    completed: boolean;
    index: number;
    onButtonClick: () => void;
    type: LearningTypes.SubModuleType;
    title: string;
    isCurrent: boolean;
    currentSubmoduleString: string;
    submoduleNumberOfType: number;
}

const SubmoduleCard = ({ completed, index, onButtonClick, type, title, isCurrent, currentSubmoduleString, submoduleNumberOfType } : Props) => {
    const width = window.innerWidth;
    const breakpoint = Number(theme.screen.small.split('px')[0]);

    const getButtonText = () => {
        if (type === LearningTypes.SubModuleType.LESSON) {
            if (completed) {
                return 'View Again';
            } else if (!completed) {
                return 'View Lesson';
            }
        } else if (type === LearningTypes.SubModuleType.QUIZ) {
            if (!completed) {
                return 'Take Quiz';
            } else if (completed) {
                return 'View Results';
            }
        }
        return '';
    };

    return (
        <S.SubmoduleCard onClick={() => width <= breakpoint && completed ? onButtonClick() : () => {}}>
            {isCurrent && (
            <S.CurrentBanner>
                <>
                    {type === LearningTypes.SubModuleType.LESSON && (
                    <>
                        <S.TitleImage src="/LessonCardIcon.svg" />
                        <S.Title>You are currently on {currentSubmoduleString}</S.Title>
                    </>
                    )}
                    {type === LearningTypes.SubModuleType.QUIZ && (
                    <>
                        <S.TitleImage src="/QuizCardIcon.svg" />
                        <S.Title>You are currently on {currentSubmoduleString}</S.Title>
                    </>
                    )}
                </>
            </S.CurrentBanner>
            )}
            <S.CardContent>
                <S.DetailsColumn>
                    <S.TitleContainer>
                        {!isCurrent && (
                        <>
                            {type === LearningTypes.SubModuleType.LESSON && (
                            <>
                                <S.TitleImage src="/LessonCardIcon.svg" />
                                <S.Title>Lesson {submoduleNumberOfType}</S.Title>
                            </>
                            )}
                            {type === LearningTypes.SubModuleType.QUIZ && (
                            <>
                                <S.TitleImage src="/QuizCardIcon.svg" />
                                <S.Title>Quiz {submoduleNumberOfType}</S.Title>
                            </>
                            )}
                            {completed && <S.MobileStatusIcon src="/CompleteIcon.svg" />}
                        </>
                    )}
                    </S.TitleContainer>
                    <S.Description isCurrent={isCurrent}>{title}</S.Description>
                    {!completed && <S.MobileReadButton onClick={onButtonClick} isCompleted={false}>{getButtonText()}</S.MobileReadButton>}
                </S.DetailsColumn>
                <S.ButtonRow>
                    {completed && <S.StatusIcon isLocked={false} src="/CompleteIcon.svg" />}
                    {type === LearningTypes.SubModuleType.LESSON && <S.ReadButton isCompleted={completed} onClick={onButtonClick}>{getButtonText()}</S.ReadButton>}
                    {type === LearningTypes.SubModuleType.QUIZ && <S.ReadButton isCompleted={completed} onClick={onButtonClick}>{getButtonText()}</S.ReadButton>}
                </S.ButtonRow>
            </S.CardContent>
        </S.SubmoduleCard>
    );
};

export default SubmoduleCard;
