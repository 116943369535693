import { Page } from '../../components/Page';
import styled from 'styled-components';
import { Buttons, Card } from '@apps/common-ui';

// weekly exercise schedule styles
export const Container = styled(Page)`
    max-width: 600px;
`;

export const Section = styled.div`
    margin: 20px 0;
`;

export const NoExercisesMessage = styled.div`
    text-align: center;
`;

export const ExerciseThumbnail = styled.img`
    width: 170px;
    height: 100px;
`;

export const DailyExerciseCard = styled(Card)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    padding: 0px;
`;

export const TodayBanner = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    slign-items: center;
    background: ${props => props.theme.primary.medium};
    border: 0px;
    border-radius: 8px 8px 0px 0px;
`;

export const BannerText = styled.p`
    color: ${props => props.theme.shades.white};
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    font-weight: bold;
`;

export const CardImageHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    column-gap: 5px;
    align-items: center;
    overflow: hidden;
`;

export const ImageContainer = styled.div`
    width: 160px;
    height: 100px;
    object-fit: cover;
`;

// Exercises for day styles
export const SectionTitle = styled.h3`
    margin: 10px 0;
    font-weight: 600;
`;

export const BackButton = styled(Buttons.LinkButton)`
    margin-left: 0px;
    padding-left: 0px;
`;

export const Heading = styled.h2`
    font-weight: 600;
    margin: 5px 0;
`;

export const Subheading = styled.p`

`;

export const CardContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content flex-start;
    flex-wrap: wrap;
    gap: 20px;
    margin: 10px 0 20px 0;
`;

export const AllCompletedBanner = styled.p`
    width: 100%;
    background-color: ${props => props.theme.text.success};
    font-size: 16px;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
`;
