export var ExerciseType;
(function (ExerciseType) {
    ExerciseType["SETS_AND_REPS"] = "SETS_AND_REPS";
    ExerciseType["INTERVALS"] = "INTERVALS";
    ExerciseType["HOLD"] = "HOLD";
    ExerciseType["SETS_AND_BREATHS"] = "SETS_AND_BREATHS";
})(ExerciseType || (ExerciseType = {}));
export var ExerciseCategory;
(function (ExerciseCategory) {
    ExerciseCategory["STRENGTH"] = "STRENGTH";
    ExerciseCategory["CARDIO"] = "CARDIO";
    ExerciseCategory["FLEXIBILITY"] = "FLEXIBILITY";
    ExerciseCategory["BREATHING"] = "BREATHING";
    ExerciseCategory["RMT"] = "RMT";
})(ExerciseCategory || (ExerciseCategory = {}));
export var ExerciseEquipment;
(function (ExerciseEquipment) {
    ExerciseEquipment["BANDS"] = "BANDS";
    ExerciseEquipment["WEIGHTS"] = "WEIGHTS";
    ExerciseEquipment["CHAIR"] = "CHAIR";
    ExerciseEquipment["STEP_STAIRS"] = "STEP_STAIRS";
    ExerciseEquipment["ASSISTED_DEVICES"] = "ASSISTED_DEVICES";
})(ExerciseEquipment || (ExerciseEquipment = {}));
export var ExerciseIntervalUnit;
(function (ExerciseIntervalUnit) {
    ExerciseIntervalUnit["SECONDS"] = "SECONDS";
    ExerciseIntervalUnit["MINUTES"] = "MINUTES";
    ExerciseIntervalUnit["FEET"] = "FEET";
    ExerciseIntervalUnit["MILES"] = "MILES";
    ExerciseIntervalUnit["METERS"] = "METERS";
    ExerciseIntervalUnit["KILOMETERS"] = "KILOMETERS";
    ExerciseIntervalUnit["STEPS"] = "STEPS";
    ExerciseIntervalUnit["REPS"] = "REPS";
    ExerciseIntervalUnit["NA"] = "NA";
    ExerciseIntervalUnit["BREATHS"] = "BREATHS";
})(ExerciseIntervalUnit || (ExerciseIntervalUnit = {}));
export var Days;
(function (Days) {
    Days["SUNDAY"] = "SUNDAY";
    Days["MONDAY"] = "MONDAY";
    Days["TUESDAY"] = "TUESDAY";
    Days["WEDNESDAY"] = "WEDNESDAY";
    Days["THURSDAY"] = "THURSDAY";
    Days["FRIDAY"] = "FRIDAY";
    Days["SATURDAY"] = "SATURDAY";
})(Days || (Days = {}));
export var ExercisePlanType;
(function (ExercisePlanType) {
    ExercisePlanType["VPR"] = "VPR";
    ExercisePlanType["PT_MAINTENANCE"] = "PT_MAINTENANCE";
    ExercisePlanType["NON_PT_MAINTENANCE"] = "NON_PT_MAINTENANCE";
})(ExercisePlanType || (ExercisePlanType = {}));
export var WarningType;
(function (WarningType) {
    WarningType["HEARTRATE_LOW"] = "HEARTRATE_LOW";
    WarningType["HEARTRATE_HIGH"] = "HEARTRATE_HIGH";
    WarningType["SPO2"] = "SPO2";
    WarningType["BPSYSTOLIC_LOW"] = "BPSYSTOLIC_LOW";
    WarningType["BPSYSTOLIC_HIGH"] = "BPSYSTOLIC_HIGH";
    WarningType["BPDIASTOLIC_LOW"] = "BPDIASTOLIC_LOW";
    WarningType["BPDIASTOLIC_HIGH"] = "BPDIASTOLIC_HIGH";
    WarningType["MULTIPLE"] = "MULTIPLE";
})(WarningType || (WarningType = {}));
export var AppointmentAttendedRoleType;
(function (AppointmentAttendedRoleType) {
    AppointmentAttendedRoleType["PHYSICAL_THERAPIST"] = "PHYSICAL_THERAPIST";
    AppointmentAttendedRoleType["RESPIRATORY_THERAPIST"] = "RESPIRATORY_THERAPIST";
    AppointmentAttendedRoleType["NURSE_PRACTITIONER"] = "NURSE_PRACTITIONER";
    AppointmentAttendedRoleType["OTHER"] = "OTHER";
})(AppointmentAttendedRoleType || (AppointmentAttendedRoleType = {}));
export var SubmissionHistoryDayOfWeek;
(function (SubmissionHistoryDayOfWeek) {
    SubmissionHistoryDayOfWeek["SUNDAY"] = "sunday";
    SubmissionHistoryDayOfWeek["MONDAY"] = "monday";
    SubmissionHistoryDayOfWeek["TUESDAY"] = "tuesday";
    SubmissionHistoryDayOfWeek["WEDNESDAY"] = "wednesday";
    SubmissionHistoryDayOfWeek["THURSDAY"] = "thursday";
    SubmissionHistoryDayOfWeek["FRIDAY"] = "friday";
    SubmissionHistoryDayOfWeek["SATURDAY"] = "saturday";
})(SubmissionHistoryDayOfWeek || (SubmissionHistoryDayOfWeek = {}));
export var ExerciseWeekSummaryStatus;
(function (ExerciseWeekSummaryStatus) {
    ExerciseWeekSummaryStatus["COMPLETE"] = "COMPLETE";
    ExerciseWeekSummaryStatus["INCOMPLETE"] = "INCOMPLETE";
    ExerciseWeekSummaryStatus["INCOMPLETE_NOT_AVAILABLE"] = "INCOMPLETE_NOT_AVAILABLE";
    ExerciseWeekSummaryStatus["INCOMPLETE_NOT_LATE"] = "INCOMPLETE_NOT_LATE";
    ExerciseWeekSummaryStatus["INCOMPLETE_LATE"] = "INCOMPLETE_LATE";
    ExerciseWeekSummaryStatus["BLOCKED_FROM_NEW_PLAN"] = "BLOCKED_FROM_NEW_PLAN";
    ExerciseWeekSummaryStatus["IN_FUTURE"] = "IN_FUTURE";
})(ExerciseWeekSummaryStatus || (ExerciseWeekSummaryStatus = {}));
