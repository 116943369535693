import { addMinutes, format, formatDistanceToNowStrict, isPast } from 'date-fns';
import { Appointment } from '../../../types/models';

export const getTime = (date: string): string => {
    let time = '';
    const stringAsDate = new Date(date);

    if (stringAsDate.getHours() === 0) {
        time += '12:';
    } else {
        time += stringAsDate.getHours() > 12 ? `${stringAsDate.getHours() % 12}:` : `${stringAsDate.getHours()}:`;
    }

    time += stringAsDate.getMinutes() > 9 ? stringAsDate.getMinutes() : `0${stringAsDate.getMinutes()}`;
    time += stringAsDate.getHours() >= 12 ? ' PM' : ' AM';
    return time;
};

export const getTimeRange = (appointment: Appointment): string => {
    const startDate = new Date(appointment.date);
    const endDate = addMinutes(startDate, appointment.length);

    const startTimeFormatted = getTime(startDate.toISOString());
    const endTimeFormatted = getTime(endDate.toISOString());

    return `${startTimeFormatted} - ${endTimeFormatted}`;
};

export const getDate = (date: string): string => {
    const stringAsDate = new Date(date);
    return `${stringAsDate.toLocaleString('default', { month: 'long' })} ${stringAsDate.getDate()}`;
};

export const timeUntilAppointment = (appointment: Appointment) => {
    const appointmentDate = new Date(appointment.date);

    if (isPast(appointmentDate)) {
        return 'Appointment started';
    }

    const distance = formatDistanceToNowStrict(appointmentDate, {
        unit: 'minute',
        roundingMethod: 'ceil'
    });

    const minutes = parseInt(distance.split(' ')[0], 10);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    let timeUntil = 'in ';
    if (hours > 0) {
        timeUntil += `${hours} hour${hours > 1 ? 's' : ''}`;
    }
    if (remainingMinutes > 0) {
        timeUntil += `${hours > 0 ? ' and ' : ''}${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`;
    }

    return timeUntil;
};

export const enableJoinButton = (date: string) => {
    const appointmentTime = new Date(date);
    const now = new Date();
    const isEnableJoinButton = addMinutes(appointmentTime, -15);
    // Enable join button 15 minutes before appointment time or if the appointment has already started
    return (now >= isEnableJoinButton && now <= appointmentTime) || isPast(appointmentTime);
};

export const formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return '';
    const digits = phoneNumber.replace(/\D/g, '').slice(-10);
    if (digits.length === 10) {
        return `(${digits.substring(0, 3)}) ${digits.substring(3, 6)}-${digits.substring(6, 10)}`;
    } else {
        return phoneNumber;
    }
};

export const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');
