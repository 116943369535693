import React, { useEffect, useState } from 'react';
import * as S from '../../index.styles';
import PageHeader from '../../../../components/common/PageHeader/insex';
import WeekSelection from '../../components/WeekSelection';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import * as utils from '../../utils';
import { ExerciseTypes } from '@apps/common-utilities';
import { NoDataMessage, Spinner } from '@apps/common-ui';
import { addWeeks, isSameWeek, subWeeks } from 'date-fns';
import WeekdayExerciseCard from '../../components/WeekdayExerciseCard';
import { TenantApi } from '../../../../api/UsersApi';

const RMTWeeklySchedule = () => {
    const { tenantUser } = useSelector((state: RootState) => state.session);
    const { exercisePlan } = useSelector((state: RootState) => state.coaching);
    const [selectedWeekDates, setSelectedWeekDates] = useState(utils.getDatesInWeek(new Date()));
    const [isCurrentWeek, setIsCurrentWeek] = useState(false);
    const [weeklySummary, setWeeklySummary] = useState<ExerciseTypes.IExerciseWeekSummary | null>(null);
    const [isPlanPaused, setIsPlanPaused] = useState(false);
    const [dayCompletionsStatuses, setDayCompletionStatuses] = useState<ExerciseTypes.IExerciseWeekDaySummary[]>([]);
    const [loadingHistory, setLoadingHistory] = useState(false);

    // Submission history is used to determine which exercises were completed
    const fetchSubmissionHistory = () => {
        if (!tenantUser) {
            return;
        }
        setLoadingHistory(true);
        TenantApi.get(`/users/${tenantUser.id}/exercise-plans/weekly-summary`, {
            params: {
                startDate: selectedWeekDates[0].toISOString().split('T')[0],
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                type: utils.ExerciseFilter.RMT
            }
        }).then(res => {
            if (res) {
                setWeeklySummary(res);
                setDayCompletionStatuses(res.dailySummaries);
                setLoadingHistory(false);
            } else if (weeklySummary) {
                setWeeklySummary({ ...weeklySummary, dailySummaries: [] });
                setDayCompletionStatuses([]);
                setLoadingHistory(false);
            }
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setLoadingHistory(false);
        });
    };

    const getIsPlanPaused = () => {
        if (!tenantUser) {
            return;
        }
        TenantApi.get(`/users/${tenantUser.id}/exercise-plans/is-paused`).then(res => {
            setIsPlanPaused(res.planPaused);
        }).catch(err => {
            console.error(err);
        });
    };

    useEffect(() => {
        fetchSubmissionHistory();
    }, [tenantUser, selectedWeekDates]);

    useEffect(() => {
        if (!exercisePlan) {
            getIsPlanPaused();
        }
    }, [tenantUser, exercisePlan]);

    useEffect(() => {
        setIsCurrentWeek(isSameWeek(new Date(), selectedWeekDates[0]));
    }, [selectedWeekDates]);

    const previousWeek = () => {
        if (weeklySummary && !isSameWeek(new Date(weeklySummary.planStartDate), selectedWeekDates[0], { weekStartsOn: 1 })) {
            setSelectedWeekDates(utils.getDatesInWeek(subWeeks(selectedWeekDates[0], 1)));
        }
    };

    const nextWeek = () => {
        if (weeklySummary) {
            setSelectedWeekDates(utils.getDatesInWeek(addWeeks(selectedWeekDates[0], 1)));
        }
    };

    if (loadingHistory) {
        return <Spinner />;
    }

    if (!exercisePlan) {
        return (
            <S.Container>
                <NoDataMessage
                  pageTitle="Breather Sessions"
                  imgUrl="/NoExercisePlan.svg"
                  messageTitle={isPlanPaused ? 'Breather plan paused' : 'No breather plan assigned'}
                  messageBody={isPlanPaused ? 'Your breather plan will reappear here once your Physical Therapist resumes your plan' : 'Your breather plan will appear here after meeting with your Physical Therapist.'}
                />
            </S.Container>
        );
    }
    return (
        <S.Container>
            <PageHeader imgSrc="/BreatherIcon.svg" title="Breather Sessions" />
            <S.Section>
                {weeklySummary && !weeklySummary.complete && (
                <WeekSelection
                  previousWeek={previousWeek}
                  nextWeek={nextWeek}
                  planStartDate={weeklySummary.planStartDate}
                  planEndDate={weeklySummary.planEndDate}
                  selectedWeekDates={selectedWeekDates}
                  isCurrentWeek={isCurrentWeek}
                />
                )}
            </S.Section>
            <S.Section>
                {!weeklySummary?.dailySummaries.length && (
                <S.NoExercisesMessage>
                    <p>Breather not found for this week</p>
                </S.NoExercisesMessage>
                )}
                {!!weeklySummary?.dailySummaries.length && dayCompletionsStatuses.map((day: ExerciseTypes.IExerciseWeekDaySummary, index: number) => {
                    const date = new utils.NaiveDate(day.date);
                    return (
                        <div key={day.date}>
                            {weeklySummary
                                && (day.totalRMTExercises > 0) && (
                                    <S.DailyExerciseCard>
                                        {utils.isToday(date) && (
                                            <>
                                                <S.TodayBanner><S.BannerText>Today&apos;s Breather Session</S.BannerText></S.TodayBanner>
                                                <S.CardImageHeader>
                                                    {day.thumbnailUrls.map((url) => (
                                                        <S.ExerciseThumbnail key={url} src={url} />
                                                    ))}
                                                </S.CardImageHeader>
                                            </>
                                        )}
                                        <WeekdayExerciseCard
                                          date={date}
                                          isBreather
                                          exerciseCount={day.totalRMTExercises}
                                          status={day.status}
                                          planCompleted={weeklySummary.complete}
                                        />
                                    </S.DailyExerciseCard>
                                )}
                        </div>
                    );
                })}
            </S.Section>
        </S.Container>

    );
};

export default RMTWeeklySchedule;
