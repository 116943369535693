import styled from 'styled-components';
import { Buttons, Card } from '@apps/common-ui';

interface Props {
    isToday?: boolean;
}

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: visible;
`;

export const CardInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 30px;

    @media (max-width: ${props => props.theme.screen.smallTablet}) {
        flex-direction: column;
        gap: 0;
    }
`;

export const AppointmentCard = styled(Card)<Props>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: ${props => props.isToday ? '650px' : '680px'};
    min-height: 151px;
    margin-bottom: 20px;
    padding: 20px;

    &:last-of-type {
        margin-bottom: 0;
    }

    @media (max-width: ${props => props.theme.screen.medium}) {
        flex-direction: column;
        align-items: flex-start;
    }

    @media (max-width: ${props => props.theme.screen.smallTablet}) {
        width: 100%;
    }

    @media (max-width: ${props => props.theme.screen.small}) {
        border-color: ${props => props.isToday ? props.theme.shades.softBlue : props.theme.shades.lighterGrey};
    }
`;

export const AppointmentDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 10px;
`;

export const AppointmentTitle = styled.h3`
    margin: 0;
`;

export const AppointmentIcon = styled.img`
    margin-right: 10px;
`;

export const Time = styled.p`
    font-size: 18px;
    white-space: normal;
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
    align-items: flex-start;
    img {
        margin-top: 6px;
    }
`;

export const Length = styled(Time)`
    color: ${props => props.theme.text.slateGray};
    align-items: center;
    img {
        margin-top: 0;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    

    @media (max-width: ${props => props.theme.screen.smallTablet}) {
        width: 100%;
        align-items: flex-start;
    }
`;

export const AppointmentDetailsButton = styled(Buttons.Button)`
    align-items: center;
    text-align: center;
    font-size 18px;
    width: 219px;
    white-space: nowrap;

    @media (max-width: ${props => props.theme.screen.smallTablet}) {
        margin-top: 15px;
        margin-left: 0;
        margin-bottom: 0px;
        width: 100%;
    }
`;

export const JoinAppointmentButton = styled(AppointmentDetailsButton)`
    &:disabled {
        background: ${props => props.theme.buttons.grey};
        border-color:${props => props.theme.buttons.grey};
    }
`;

export const JoinError = styled.p`
    font-size: 18px;
    padding-left: 40px;
    color: ${props => props.theme.text.error};
    @media (max-width: ${props => props.theme.screen.small}) {
        padding-left: 20px;
    }
`;

export const Section = styled.section<Props>`
    margin-top: 30px;
    margin-bottom: ${props => props.isToday ? '25px' : '30px'};
    padding: ${props => props.isToday ? '15px' : '0'};
    background-color: ${props => props.isToday ? props.theme.shades.softBlue : 'transparent'};
    border-radius: 9px;
    width: 680px;

    @media (max-width: ${props => props.theme.screen.smallTablet}) {
        max-width: 680px;
        margin-left: 0;
        width: 100%;
    }

    @media (max-width: ${props => props.theme.screen.small}) {
        margin-left: 0;
        width: 100%;
        margin-bottom: 30px;
        padding: 0;
        background-color: transparent;
    }
`;

export const DateHeading = styled.div<Props>`
  display: inline-flex;
  align-items: center;
  padding: ${props => props.isToday ? '0 10px' : '5px 10px'};
  margin-bottom: ${props => props.isToday ? '10px' : '20px'};
  background-color: ${props => props.theme.shades.softBlue};
  border-radius: 9px;
  font-size: 16px;
  color: ${props => props.theme.shades.white};

  @media (max-width: ${props => props.theme.screen.small}) {
    padding: 5px 10px;
    margin-bottom: 20px;
  }
`;
