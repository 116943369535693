import React, { useEffect, useState } from 'react';
import { Modal } from '@apps/common-ui';
import * as S from './index.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Appointment, AppointmentContactType } from '../../types/models';
import { getTime, getDate, enableJoinButton, getTimeRange, formatPhoneNumber } from '../../components/AppointmentsList/utils';
import { useNavigate } from 'react-router';
import Mixpanel from '../../services/Mixpanel';

type Props = {
    onDismiss: () => void;
    appointment: Appointment
    onOpenZoomTestModal: () => void;
}

const AppointmentPopup = ({ onDismiss, appointment, onOpenZoomTestModal }: Props) => {
    const navigate = useNavigate();
    const [isDisabled, setIsDisabled] = useState(true);
    const isPhoneCall: boolean = appointment.contactType === AppointmentContactType.PhoneCall;

    useEffect(() => {
        const checkAndEnableButton = () => {
            if (enableJoinButton(appointment.date)) {
                setIsDisabled(false);
            }
        };
        checkAndEnableButton();
        // Checks every 30 seconds
        const interval = setInterval(checkAndEnableButton, 30000);
        return () => clearInterval(interval);
    }, [appointment.date]);

    return (
        <Modal
          showModal
          dismissModal={onDismiss}
          minWidth="30%"
          maxWidth="100vw"
          maxHeight="100vh"
          showTopBar={false}
          showTranslucentBackground
          closeOnOutsideClick={false}
        >
            <S.ModalContent>
                <S.TopBar>
                    <S.CloseButton onClick={onDismiss}><FontAwesomeIcon icon={faX as IconProp} size="xs" />
                        <p>Close</p>
                    </S.CloseButton>
                </S.TopBar>
                <S.AppointmentTitle>{appointment.appointmentTypeName}</S.AppointmentTitle>
                <S.PopupDivider />
                <S.DetailsContainer>
                    <S.AppointmentIcon src={isPhoneCall ? '/AppointmentCallPopup.svg' : '/ZoomIcon.svg'} />
                    <S.AppointmentDetails>
                        <S.DateContainer>
                            <S.CalendarIcon src="/AppointmentIcon.svg" />
                            <S.MeetingDate>{getDate(appointment.date)}</S.MeetingDate>
                        </S.DateContainer>
                        <S.DateContainer>
                            <S.CalendarIcon src="/TimeIcon.svg" />
                            <S.MeetingTime>{getTimeRange(appointment)}</S.MeetingTime>
                        </S.DateContainer>
                        {isPhoneCall
                        ? (
                            <S.ButtonDetails>
                                <strong>
                                    Our team will call your phone
                                </strong> {formatPhoneNumber(appointment.phoneNumber)}
                            </S.ButtonDetails>
                          )
                        : (
                            <S.AppointmentButton
                              disabled={isDisabled}
                              onClick={() => {
                                window.open(appointment.joinUrl, '_blank');
                                Mixpanel.track(`web_zoom_appointment_joined_${appointment.appointmentTypeName}`);
                              }}
                            >Join Zoom Call
                            </S.AppointmentButton>
                        )}
                        {isPhoneCall
                        ? (
                            <S.ButtonDetails>
                                Please be ready for a phone call at {getTime(appointment.date)}
                            </S.ButtonDetails>
                        )
                        : (
                            <S.ButtonDetails>
                                This button will become active 15 minutes before your appointment.
                            </S.ButtonDetails>
                        )}
                    </S.AppointmentDetails>
                </S.DetailsContainer>
                <S.PopupDivider />
                <S.AppointmentText>
                    You are meeting with <strong>{appointment.coachName}</strong>
                </S.AppointmentText>
                {!isPhoneCall
                && (
                <>
                    <S.PopupDivider />
                    <S.ZoomTestContainer>
                        <S.AppointmentText>
                            Want to test Zoom on this<br />device before your call?
                        </S.AppointmentText>
                        <S.HelpButton
                          onClick={() => {
                            Mixpanel.track('web_home_page_test_zoom');
                            onOpenZoomTestModal();
                        }}
                        >Test Zoom
                        </S.HelpButton>
                    </S.ZoomTestContainer>
                </>
                )}
                <S.PopupDivider />
                <S.HelpButton onClick={() => navigate('/support')}>Having issues?</S.HelpButton>
            </S.ModalContent>
        </Modal>
    );
};

export default AppointmentPopup;
