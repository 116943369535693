import { ExerciseTypes, Labels } from '@apps/common-utilities';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseISO, isToday, isBefore } from 'date-fns';
import React from 'react';
import * as S from './index.styles';

type Props = {
    exercise: ExerciseTypes.IExerciseSummary;
    isCurrentExercise: boolean;
    onStart: () => void;
    canComplete: boolean;
    exerciseIndex: string; // in the format "x of y"
    date: string;
    isPlanCompleted: boolean;
}

const getEquipmentString = (equipment: ExerciseTypes.IExerciseEquipment): string => {
    if (equipment.equipment === ExerciseTypes.ExerciseEquipment.ASSISTED_DEVICES) {
        return equipment.specification ? equipment.specification : '';
    } else {
        return equipment.specification ? `${equipment.specification} ${Labels.equipment(equipment.equipment)} ` : Labels.equipment(equipment.equipment);
    }
};

const ExerciseCard = ({ exercise, onStart, isCurrentExercise, canComplete, exerciseIndex, date, isPlanCompleted }: Props) => {
    const parsedDate = parseISO(date);
    const isPastDate = isBefore(parsedDate, new Date()) && !isToday(parsedDate);

    return (
        <S.ExerciseCard>
            <S.CardTop>
                <S.ImageContainer>
                    <S.Thumbnail src={exercise.exerciseInfo.thumbnailUrl} alt="Exercise thumbnail" />
                </S.ImageContainer>
                <S.ExerciseData>
                    {exercise.exerciseInfo.type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS
                        ? <S.ExerciseIndex>Breather {exerciseIndex}</S.ExerciseIndex>
                        : <S.ExerciseIndex>Exercise {exerciseIndex}</S.ExerciseIndex>}
                    {!!exercise.exerciseVariable.exerciseEquipment.length && (
                        <>
                            {exercise.exerciseVariable.exerciseEquipment.map((equipment) => (
                                (!!getEquipmentString(equipment) && (
                                    <S.EquipmentDetails>
                                        {getEquipmentString(equipment)}
                                    </S.EquipmentDetails>
                                ))
                            ))}
                        </>
                        )}
                    {exercise.exerciseInfo.type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS && (
                        <S.EquipmentDetails>
                            RMT Breather
                        </S.EquipmentDetails>
                    )}
                    {!exercise.exerciseVariable.exerciseEquipment.length
                        && exercise.exerciseInfo.type !== ExerciseTypes.ExerciseType.SETS_AND_BREATHS && (
                        <S.EquipmentDetails>No Equipment</S.EquipmentDetails>
                    )}
                </S.ExerciseData>
            </S.CardTop>
            <S.CardBottom>
                <S.ExerciseTitle>{exercise.exerciseInfo.title}</S.ExerciseTitle>
                {canComplete
                && !exercise.complete
                    && (
                        <S.StartButton
                          onClick={onStart}
                          isCurrentExercise={isCurrentExercise}
                          isPlanCompleted={isPlanCompleted}
                        >
                            Start {exercise.exerciseInfo.type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS ? 'Breather' : 'Exercise'}
                        </S.StartButton>
                    )}
                {exercise.complete
                && (
                <S.CompletedStatus><FontAwesomeIcon icon={faCheckCircle as IconProp} /> Completed</S.CompletedStatus>
                )}
            </S.CardBottom>
        </S.ExerciseCard>
    );
};

export default ExerciseCard;
