import { parseJSON } from 'date-fns';

export const getDateInMsFromString = (date: string): number => parseJSON(date).getMilliseconds();

export const formatDateForSafari = (originalDateString: string) => {
    // if it's already a valid date, return it
    const date = new Date(originalDateString);
    if (date.toString() !== 'Invalid Date') {
        return originalDateString;
    }
    // otherwise, format it to be safari compatible
    const [dateString, timeString, timeZoneString] = originalDateString.split(' ');
    // if the time zone is UTC, add a Z to the end to make it compatible
    if (timeZoneString === 'UTC') {
        return `${dateString}T${timeString}Z`;
    }
    return `${dateString}T${timeString}${timeZoneString}`;
};
