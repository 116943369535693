import { AmplifyProvider } from '@aws-amplify/ui-react';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import './App.css';
import './services/i18n';
import { store } from './state/store';
import { amplifyTheme, theme } from '@apps/common-ui';
import { useAuth } from '@apps/common-utilities';
import RouterContainer from './router';
import useSmartlook from './hooks/useSmartlook';

function App() {
    useAuth();
    useSmartlook();

    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <AmplifyProvider theme={amplifyTheme}>
                    <RouterContainer />
                </AmplifyProvider>
            </Provider>
            <Toaster />
        </ThemeProvider>
    );
}

export default App;
