import React from 'react';
import * as S from '../index.styles';
import { Link } from 'react-router-dom';

type Props = {
    noToday?: boolean;
}

const NoAppointments = ({ noToday }: Props) => (
    <S.Section>
        {noToday && <S.DateHeading>Today</S.DateHeading>}
        <S.NoAppointmentCard>
            {!noToday && <S.NoAppointmentTitle>No Appointments Scheduled</S.NoAppointmentTitle>}
            {noToday
            ? <S.NoAppointmentText>You have no appointments today.</S.NoAppointmentText>
            : (
                <S.NoAppointmentText>
                    Appointments not showing up? <Link to="/support">Contact Support</Link>
                </S.NoAppointmentText>
            )}
        </S.NoAppointmentCard>
    </S.Section>
);

export default NoAppointments;
