import * as S from './index.styles';
import { ReactComponent as Arrow } from '../../../assets/SidebarArrow.svg';
import React from 'react';

type Props = {
    title: string;
    subtitle?: string;
    imageUrl: string;
    clickFunction: () => void;
    buttonText: string;
    buttonArrow: boolean;
    buttonWidth?: string;
    isAppointment?: boolean;
    isDone?: boolean;
}

const HomeCard = ({ title, subtitle, imageUrl, clickFunction, buttonText, buttonArrow, buttonWidth, isAppointment, isDone }: Props) => (
    <S.HomeCard isAppointment={isAppointment}>
        <S.HomeCardImageContainer>
            <S.HomeCardImage src={imageUrl} />
        </S.HomeCardImageContainer>
        <S.HomeCardBody>
            <S.HomeCardTitlesContainer>
                <S.HomeCardTitle>{title}</S.HomeCardTitle>
                {!!subtitle && <S.HomeCardSubtitle>{subtitle}</S.HomeCardSubtitle>}
            </S.HomeCardTitlesContainer>
            { isDone
            ? <S.HomeCardCompleteIcon src="/CompleteIcon.svg" />
            : (
                <S.HomeCardButton onClick={clickFunction} width={buttonWidth}>
                    {buttonText}
                    {buttonArrow && <Arrow />}
                </S.HomeCardButton>
            )}
        </S.HomeCardBody>
    </S.HomeCard>
);

export default HomeCard;
