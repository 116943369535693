var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var getPercentage = function (min, max, value) {
    var range = parseInt("".concat(max), 10) - parseInt("".concat(min), 10);
    return (parseFloat("".concat(value)) / range) * 100;
};
export var StyledSlider = styled.input.attrs({ type: 'range' })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    -webkit-appearance: none;\n    -moz-appearance: none;\n    outline: 0;\n    width: 100%;\n    height: 14px;\n    border-radius: 32px;\n    background: ", ";\n    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);\n\n    ::-webkit-slider-thumb {\n        -webkit-appearance: none;\n        width: 35px;\n        height: 35px;\n        background-color: #EAF1F5;\n        border: 1px solid #B7C3C9;\n        border-radius: 50%;\n    }\n\n    ::-moz-range-thumb {\n        -moz-appearance: none;\n        width: 35px;\n        height: 35px;\n        background-color: #EAF1F5;\n        border: 1px solid #B7C3C9;\n        border-radius: 50%;\n    }\n"], ["\n    -webkit-appearance: none;\n    -moz-appearance: none;\n    outline: 0;\n    width: 100%;\n    height: 14px;\n    border-radius: 32px;\n    background: ", ";\n    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);\n\n    ::-webkit-slider-thumb {\n        -webkit-appearance: none;\n        width: 35px;\n        height: 35px;\n        background-color: #EAF1F5;\n        border: 1px solid #B7C3C9;\n        border-radius: 50%;\n    }\n\n    ::-moz-range-thumb {\n        -moz-appearance: none;\n        width: 35px;\n        height: 35px;\n        background-color: #EAF1F5;\n        border: 1px solid #B7C3C9;\n        border-radius: 50%;\n    }\n"])), function (props) { return "linear-gradient(to right,\n        ".concat(props.color, " 0%,\n        ").concat(props.color, " ").concat(getPercentage(props.min, props.max, props.value), "%,\n        #DBE3E8 ").concat(getPercentage(props.min, props.max, props.value), "%,\n        #DBE3E8 100%);"); });
var templateObject_1;
