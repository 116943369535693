export enum ExerciseType {
  SETS_AND_REPS = 'SETS_AND_REPS',
  INTERVALS = 'INTERVALS',
  HOLD = 'HOLD',
  SETS_AND_BREATHS = 'SETS_AND_BREATHS',
}

export enum ExerciseCategory {
  STRENGTH = 'STRENGTH',
  CARDIO = 'CARDIO',
  FLEXIBILITY = 'FLEXIBILITY',
  BREATHING = 'BREATHING',
  RMT = 'RMT',
}

export enum ExerciseEquipment {
  BANDS = 'BANDS',
  WEIGHTS = 'WEIGHTS',
  CHAIR = 'CHAIR',
  STEP_STAIRS = 'STEP_STAIRS',
  ASSISTED_DEVICES = 'ASSISTED_DEVICES',
}

export enum ExerciseIntervalUnit {
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  FEET = 'FEET',
  MILES = 'MILES',
  METERS = 'METERS',
  KILOMETERS = 'KILOMETERS',
  STEPS = 'STEPS',
  REPS = 'REPS',
  NA = 'NA',
  BREATHS = 'BREATHS',
}

export interface IExercisePlan {
  id: number | null;
  userId: string;
  startDate: string;
  dischargeDate: string | null;
  endDate: string | null;
  published: boolean;
  publishedAt: string;
  complete: boolean;
  updatedAt: string;
  onBetaBlockers: boolean;
  restingHeartRate: number;
  spO2PercentTarget: number;
  maxHeartRatePercentOverride: number;
  minHeartRatePercentOverride: number;
  userAge: number;
  createdBy: string;
  blockPreviousPlans: boolean;
  assignedExercises: IAssignedExercise[];
  paused: boolean;
  exercisePlanPauses: IExercisePause[];
  exercisePlanType: ExercisePlanType;
}

export interface IExercise {
  id?: number;
  exerciseId: number;
  exerciseTitle: string;
  exerciseType: ExerciseType;
  videoUrl: string;
  thumbnailUrl: string;
  instructions: string;
  category: ExerciseCategory;
  allowTime: boolean;
  allowDistance: boolean;
  allowReps: boolean;
  exerciseVariable: IExerciseVariable;
}

export interface IExerciseVariable {
  sets: number;
  amount: number;
  unit: string;
  holdSeconds: number;
  rmtInhaleResistance: number | null;
  rmtExhaleResistance: number | null;
  additionalInstructions: string;
  minHeartRatePercent: number;
  maxHeartRatePercent: number;
  showBreathlessnessTarget: boolean;
  showSpO2TargetPercent: boolean;
  heartRateRestPercent: number;
  showSpO2RestPercent: boolean;
  showBreathlessnessRest: boolean;
  exerciseEquipment: IExerciseEquipment[];
  requireBreathlessnessLevelReporting: boolean;
  requireLowestSpO2PercentReporting: boolean;
  requireImmediateSpO2PercentReporting: boolean;
  requireHeartRateReporting: boolean;
  requireFlowRateReporting: boolean;
  requireActivityReporting: boolean;
  requireRMTInhaleResistanceReporting: boolean;
  requireRMTExhaleResistanceReporting: boolean;
  requireRMTDifficultyReporting: boolean;
}

export interface IExerciseEquipment {
  equipment: ExerciseEquipment;
  specification?: string;
}

export enum Days {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY'
}

export enum ExercisePlanType {
  VPR = 'VPR',
  PT_MAINTENANCE = 'PT_MAINTENANCE',
  NON_PT_MAINTENANCE = 'NON_PT_MAINTENANCE'
}

export interface IExerciseInfo {
  id: number | null;
  title: string;
  videoUrl: string;
  thumbnailUrl: string;
  instructions: string;
  type: ExerciseType;
  category: ExerciseCategory;
  deletedAt: string;
}

export interface IExercisePause {
  pausedAt: string;
  unPausedAt: string;
}

export interface IAssignedExercise {
  id: number | null;
  exercisePlanId: number;
  exerciseId: number;
  dayOfWeek: Days;
  dayOfWeekOrder: number;
  exerciseVariable: IExerciseVariable;
  exerciseInfo: IExerciseInfo;
  exercisePlanPauses: IExercisePause[];
  paused: boolean;
  userId: string;
  latestSubmission: IAssignedExerciseSubmission | null;
}

export interface IAssignedExerciseBaseline {
  id: number;
  exercisePlanId: number;
  heartRate: number;
  bpSystolic: number;
  bpDyastolic: number;
  SpO2: number;
  flowRate: number;
  createdAt?: Date;
}

export interface IAssignedExerciseResults {
  setNumber: number;
  amount: number;
}

export interface IAssignedExerciseSubmission {
  id: number | null;
  heartRate: number | null;
  immediateSpO2Percent: number | null;
  lowestSpO2Percent: number | null;
  flowRate: number | null;
  breathlessnessLevel: number | null;
  startTime: Date;
  assignedExerciseId: number;
  exerciseBaselineId: number;
  assignedExerciseResults: IAssignedExerciseResults[] | null;
  rmtDifficulty: number | null;
  rmtInhaleResistance: number | null;
  rmtExhaleResistance: number | null;
  calendarCreatedAt: string;
  calendarCompletionDate: string;
  comment: string | null;
}

export interface ICreatedBaseline {
  heartRate: number,
  bpSystolic: number,
  bpDiastolic: number,
  spO2: number,
  flowRate: number | null,
  breathlessnessLevel: number,
  exercisePlanId: number,
  calendarCreatedAt: string,
  calendarCompletionDate: string;
}

export interface IFetchedBaseline {
  id: number;
  heartRate: number,
  bpSystolic: number,
  bpDiastolic: number,
  spO2: number,
  flowRate: number | null,
  breathlessnessLevel: number,
  createdAt: Date,
  calendarCreatedAt: string,
  calendarCompletionDate: string;
  exercisePlanId: number
}

export enum WarningType {
  HEARTRATE_LOW = 'HEARTRATE_LOW',
  HEARTRATE_HIGH = 'HEARTRATE_HIGH',
  SPO2 = 'SPO2',
  BPSYSTOLIC_LOW = 'BPSYSTOLIC_LOW',
  BPSYSTOLIC_HIGH = 'BPSYSTOLIC_HIGH',
  BPDIASTOLIC_LOW = 'BPDIASTOLIC_LOW',
  BPDIASTOLIC_HIGH = 'BPDIASTOLIC_HIGH',
  MULTIPLE = 'MULTIPLE'
}

export interface IExercisePlanSummary {
  userId: string;
  exercisePlanId: number;
  startDate: string;
  endDate: string | null;
  dischargeDate: string | null;
  published: boolean;
  publishedAt: string;
  createdAt: string;
  paused: boolean;
  complete: boolean;
  exercisePlanType: ExercisePlanType;
}

export interface IExercisePlanSummaryResponse {
  totalExercises: string;
  categorySummary: { [key: string]: string };
  summaries: IExercisePlanSummary[];
}

export interface IAppointmentAttended {
  appointmentName: string;
  AppointmentAttendedRoleType: string;
  group: boolean;
  date: string;
}

export interface IScheduledAppointmentSummary {
  totalIndividualAppointmentsAttended: number;
  totalIndividualAppointmentsRescheduled: number;
  totalIndividualAppointmentsMissedOrNoShowed: number;
  appointmentsAttendedSinceLastEval: number;
  oneOnOneAppointmentsSinceLastEval: number;
  groupAppointmentsSinceLastEval: number;
}

export interface IExercisePlanDischargeDatePayload {
  id?: number,
  dischargeDate: string
}

export enum AppointmentAttendedRoleType {
  PHYSICAL_THERAPIST = 'PHYSICAL_THERAPIST',
  RESPIRATORY_THERAPIST = 'RESPIRATORY_THERAPIST',
  NURSE_PRACTITIONER = 'NURSE_PRACTITIONER',
  OTHER = 'OTHER'
}

export interface IExerciseReport {
  todaysExercisesCompleted: number,
  exercisesAssigned: number,
  minuteDuration: number,
  warnings: number,
  setsCompletedPercentage: number,
  additionalExercisesCompleted: number,
  heartRateData: {
    title: string,
    value: number | null
  }[];
  spO2Data: {
    title: string,
    lowestSpO2: number | null,
    immediateSpO2: number | null
  }[];
  flowRateData: {
    title: string,
    value: number | null
  }[];
  breathlessnessData: {
    title: string,
    value: number | null
  }[];
  bloodPressureData: {
    title: string,
    bpSystolic: number | null,
    bpDiastolic: number | null
  }[],
  exerciseCompletionData: {
    title: string,
    assignedSets: number | null,
    assignedAmount: number | null,
    results: number[],
    type: ExerciseType,
  }[],
  commentData: {
    title: string,
    comment: string | null
  }[],
}

export interface IExerciseRMTReport {
  additionalExercisesCompleted: number,
  exercisesAssigned: number,
  minuteDuration: number,
  warnings: number,
  setsCompletedPercentage: number,
  todaysExercisesCompleted: number,
  rmtData: {
    title: string,
    assignedAmount: number,
    assignedSets: number,
    results: number[],
    rmtDifficulty: number,
    rmtInhaleResistanceAssigned: number,
    rmtExhaleResistanceAssigned: number,
    rmtInhaleResistanceCompleted: number,
    rmtExhaleResistanceCompleted: number,
    lowestSpO2Percent: number,
    immediateSpO2Percent: number,
    breathlessnessLevel: number,
    heartRate: number,
    flowRate: number,
    bpSystolic: number,
    bpDiastolic: number,
    startTime: string,
    endTime: string,
    comment: string,
  }[],
}

export interface IExerciseReportWeek {
  monday: IExerciseReport | null,
  tuesday: IExerciseReport | null,
  wednesday: IExerciseReport | null,
  thursday: IExerciseReport | null,
  friday: IExerciseReport | null,
  saturday: IExerciseReport | null,
  sunday: IExerciseReport | null,
}

export enum SubmissionHistoryDayOfWeek {
  SUNDAY = 'sunday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday'
}

export type IExerciseSubmissionHistoryDay = {
  assignedExerciseId: number;
  title: string;
  submissionHistory: {
    completionTime: string;
    submissionId: number;
  }[]
}

type IExerciseSubmissionHistoryDayMap = {
  [key in SubmissionHistoryDayOfWeek]: IExerciseSubmissionHistoryDay[];
}

type SubmissionHistoryBody = IExerciseSubmissionHistoryDayMap & {
  startDate: string;
  endDate: string;
}

export type IExerciseSubmissionHistory = {
  submissionHistory: SubmissionHistoryBody[];
}

export interface IExerciseWeekSummary {
  planStartDate: string;
  planEndDate: string | null;
  dailySummaries: IExerciseWeekDaySummary[];
  currentPlanId: number;
  exercisePlanType: string;
  dischargeDate: string | null;
  complete: boolean;
}

export interface IExerciseWeekDaySummary {
  date: string;
  totalNonRMTExercises: number;
  totalRMTExercises: number;
  status: ExerciseWeekSummaryStatus;
  thumbnailUrls: string[];
}

export enum ExerciseWeekSummaryStatus {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
  INCOMPLETE_NOT_AVAILABLE = 'INCOMPLETE_NOT_AVAILABLE',
  INCOMPLETE_NOT_LATE = 'INCOMPLETE_NOT_LATE',
  INCOMPLETE_LATE = 'INCOMPLETE_LATE',
  BLOCKED_FROM_NEW_PLAN = 'BLOCKED_FROM_NEW_PLAN',
  IN_FUTURE = 'IN_FUTURE',
}

export interface IExerciseDaySummary {
  dayOfWeek: string;
  startDate: string;
  endDate: string;
  onBetaBlockers: boolean;
  restingHeartRate: number;
  spO2PercentTarget: number;
  userAge: number;
  publishedAt: string;
  planId: number;
  exercises: IExerciseSummary[];
  dischargeDate: string | null;
  complete: boolean;
}

export interface IExerciseSummary {
  complete: boolean;
  exerciseId: number;
  exerciseInfo: IExerciseInfo;
  exerciseVariable: IExerciseVariable;
  id: number;
}
