import AppointmentList from '../../../components/AppointmentsList';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Appointment, Coach, TenantUser } from '../../../types/models';
import { formatDateForSafari, getDateInMsFromString } from './utils';
import { IAssignedExerciseSubmission, IExerciseWeekSummary } from '@apps/common-utilities/src/types/exerciseTypes';
import { ExerciseTypes, LearningTypes, ActionPlanTypes } from '@apps/common-utilities';
import { IActionPlan } from '@apps/common-utilities/src/types/actionPlanTypes';

export interface CoachingState {
    forms: any[];
    formsLoading: boolean;
    appointments: Appointment[] | [];
    appointmentsLoading: boolean;
    userData?: TenantUser;
    exercisePlan?: ExerciseTypes.IExerciseWeekSummary;
    exerciseSubmissionHistory?: ExerciseTypes.IAssignedExerciseSubmission[];
    newBaselineRequired?: boolean
    course?: LearningTypes.IUserCourse;
    actionPlan?: ActionPlanTypes.IActionPlan;
}

const initialState = {
    forms: [],
    formsLoading: false,
    appointments: [],
    appointmentsLoading: false
} as CoachingState;

const sessionSlice = createSlice({
    name: 'coaching',
    initialState,
    reducers: {
        setForms: (state, { payload }: PayloadAction<{ forms:any[] }>) => {
            state.forms = payload.forms;
        },
        setFormsLoading: (state, { payload }: PayloadAction<{ formsLoading: boolean }>) => {
            state.formsLoading = payload.formsLoading;
        },
        setAppointments: (state, { payload }: PayloadAction<{appointments: any}>) => {
            if (payload.appointments) {
                state.appointments = payload.appointments.map((appointment: any) => ({
                    id: appointment.id,
                    date: appointment.date,
                    contactType: appointment.contact_type,
                    appointmentTypeName: appointment.appointment_type.name,
                    length: appointment.length,
                    phoneNumber: appointment.phone_number,
                    coachName: appointment.provider.full_name,
                    joinUrl: appointment.external_videochat_url,
                    zoomInfo: appointment.zoom_info,
                    isZoom: appointment.zoom_info != null,
                }));
            }
        },
        setAppointmentsLoading: (state, { payload }: PayloadAction<{ appointmentsLoading: boolean }>) => {
            state.appointmentsLoading = payload.appointmentsLoading;
        },
        addAppointment: (state, { payload }: PayloadAction<{appointment: Appointment}>) => {
            // sort by ascending date
            const newAppointments = [...state.appointments, payload.appointment].sort((a, b) => {
                if (a.date && b.date) {
                    return getDateInMsFromString(a.date) - getDateInMsFromString(b.date);
                } return 0;
            });
            state.appointments = newAppointments;
        },
        removeForm: (state, { payload }: PayloadAction<{formId: string}>) => {
            state.forms = state.forms.filter(form => form.jotformId !== payload.formId);
        },
        setExercisePlan: (state, { payload }: PayloadAction<{exercisePlan: IExerciseWeekSummary}>) => {
            state.exercisePlan = payload.exercisePlan;
        },
        setExerciseSubmissionHistory: (state, { payload }: PayloadAction<{exerciseSubmissionHistory: IAssignedExerciseSubmission[]}>) => {
            state.exerciseSubmissionHistory = payload.exerciseSubmissionHistory;
        },
        setNewBaselineRequired: (state, { payload }: PayloadAction<{newBaselineRequired: boolean}>) => {
            state.newBaselineRequired = payload.newBaselineRequired;
        },
        setCourse: (state, { payload }: PayloadAction<{course: LearningTypes.IUserCourse}>) => {
            state.course = payload.course;
        },
        setActionPlan: (state, { payload }: PayloadAction<{actionPlan: IActionPlan}>) => {
            state.actionPlan = payload.actionPlan;
        }
    }
});

export const {
    setForms,
    setFormsLoading,
    setAppointments,
    setAppointmentsLoading,
    addAppointment,
    removeForm,
    setExercisePlan,
    setExerciseSubmissionHistory,
    setNewBaselineRequired,
    setCourse,
    setActionPlan
} = sessionSlice.actions;

export default sessionSlice.reducer;
