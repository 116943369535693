import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from '../state/store';

const useRedirectExternally = () => {
    const navigate = useNavigate();
    const { loggedIn } = useSelector((state: RootState) => state.session);
    const redirect = async (url: string | null) => {
        if (!loggedIn) {
            navigate('/login');
        } else if (url) {
            // Set the back button to go back to the previous page before the redirect page so
            // that the user can go back to the app after the redirect.
            // eslint-disable-next-line no-restricted-globals
            history.pushState(null, '', '/');
            window.location.href = url;
        }
    };
    return redirect;
};

export default useRedirectExternally;
