import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import * as S from '../../index.styles';
import { isSameWeek, subWeeks, addWeeks, isSameMonth, addDays, endOfDay, isWithinInterval } from 'date-fns';
import { ExerciseTypes } from '@apps/common-utilities';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import WeekdayExerciseCard from '../../components/WeekdayExerciseCard';
import * as utils from '../../utils';
import { Spinner, NoDataMessage } from '@apps/common-ui';
import PageHeader from '../../../../components/common/PageHeader/insex';
import WeekSelection from '../../components/WeekSelection';
import { TenantApi } from '../../../../api/UsersApi';

const ExerciseWeeklySchedule = () => {
    const { tenantUser } = useSelector((state: RootState) => state.session);
    const { exercisePlan } = useSelector((state: RootState) => state.coaching);
    const [selectedWeekDates, setSelectedWeekDates] = useState(utils.getDatesInWeek(new Date()));
    const [isCurrentWeek, setIsCurrentWeek] = useState(false);
    const [weeklySummary, setWeeklySummary] = useState<ExerciseTypes.IExerciseWeekSummary | null>(null);
    const [isPlanPaused, setIsPlanPaused] = useState(false);
    const [dayCompletionsStatuses, setDayCompletionStatuses] = useState<ExerciseTypes.IExerciseWeekDaySummary[]>([]);
    const [loadingHistory, setLoadingHistory] = useState(false);

    // Submission history is used to determine which exercises were completed
    const fetchSubmissionHistory = () => {
        if (!tenantUser) {
            return;
        }
        setLoadingHistory(true);
        TenantApi.get(`/users/${tenantUser.id}/exercise-plans/weekly-summary`, {
            params: {
                startDate: selectedWeekDates[0].toISOString().split('T')[0],
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                type: utils.ExerciseFilter.EXERCISE
            }
        }).then(res => {
            if (res) {
                setWeeklySummary(res);
                setDayCompletionStatuses(res.dailySummaries);
            } else if (weeklySummary) {
                setWeeklySummary({ ...weeklySummary, dailySummaries: [] });
                setDayCompletionStatuses([]);
            }
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setLoadingHistory(false);
        });
    };

    const getIsPlanPaused = () => {
        if (!tenantUser) {
            return;
        }
        TenantApi.get(`/users/${tenantUser.id}/exercise-plans/is-paused`).then(res => {
            setIsPlanPaused(res.planPaused);
        }).catch(err => {
            console.error(err);
        });
    };

    useEffect(() => {
        fetchSubmissionHistory();
    }, [tenantUser, selectedWeekDates]);

    useEffect(() => {
        if (!exercisePlan) {
            getIsPlanPaused();
        }
    }, [tenantUser, exercisePlan]);

    useEffect(() => {
        setIsCurrentWeek(isSameWeek(new Date(), selectedWeekDates[0]));
    }, [selectedWeekDates]);

    const previousWeek = () => {
        if (weeklySummary && !isSameWeek(new Date(weeklySummary.planStartDate), selectedWeekDates[0], { weekStartsOn: 1 })) {
            setSelectedWeekDates(utils.getDatesInWeek(subWeeks(selectedWeekDates[0], 1)));
        }
    };

    const nextWeek = () => {
        if (weeklySummary) {
            setSelectedWeekDates(utils.getDatesInWeek(addWeeks(selectedWeekDates[0], 1)));
        }
    };

    if (loadingHistory) {
        return <Spinner />;
    }

    if (!exercisePlan) {
        return (
            <S.Container>
                <NoDataMessage
                  pageTitle="Exercise"
                  imgUrl="/NoExercisePlan.svg"
                  messageTitle={isPlanPaused ? 'Exercise plan paused' : 'No exercise plan assigned'}
                  messageBody={isPlanPaused ? 'Your exercise plan will reappear here once your Physical Therapist resumes your plan' : 'Your exercise plan will appear here after meeting with your Physical Therapist.'}
                />
            </S.Container>
        );
    }

    return (
        <S.Container>
            <PageHeader imgSrc="/ExercisesTitle.png" title="Exercise" />
            <S.Section>
                {weeklySummary && !weeklySummary?.complete && (
                    <WeekSelection
                      previousWeek={previousWeek}
                      nextWeek={nextWeek}
                      planStartDate={weeklySummary.planStartDate}
                      planEndDate={weeklySummary.planEndDate}
                      selectedWeekDates={selectedWeekDates}
                      isCurrentWeek={isCurrentWeek}
                    />
                )}
            </S.Section>
            <S.Section>
                {!weeklySummary?.dailySummaries.length && (
                    <S.NoExercisesMessage>
                        <p>No exercises found for this week</p>
                    </S.NoExercisesMessage>
                )}
                {!!weeklySummary?.dailySummaries.length
                   && dayCompletionsStatuses.map((day: ExerciseTypes.IExerciseWeekDaySummary, index: number) => {
                    const date = new utils.NaiveDate(day.date);
                    return (
                        <div key={day.date}>
                            {weeklySummary
                                && (day.totalNonRMTExercises > 0) && (
                                    <S.DailyExerciseCard>
                                        {utils.isToday(date) && (
                                            <>
                                                <S.TodayBanner>
                                                    <S.BannerText>Today&apos;s Exercises</S.BannerText>
                                                </S.TodayBanner>
                                                <S.CardImageHeader>
                                                    {day.thumbnailUrls.map((url) => (
                                                        <S.ExerciseThumbnail key={url} src={url} />
                                                    ))}
                                                </S.CardImageHeader>
                                            </>
                                        )}
                                        <WeekdayExerciseCard
                                          date={date}
                                          isBreather={false}
                                          exerciseCount={day.totalNonRMTExercises}
                                          status={day.status}
                                          planCompleted={weeklySummary.complete}
                                        />
                                    </S.DailyExerciseCard>
                                )}
                        </div>
                    );
                })}
            </S.Section>
        </S.Container>
    );
};

export default ExerciseWeeklySchedule;
