import styled from 'styled-components';
import { Page } from '../../../../components/Page';
import { Buttons, Card } from '@apps/common-ui';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    width: 100%;
    margin: 0;
    padding-bottom: 90px;
`;

export const AnswerRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    width: 100%;
    padding-bottom: 30px;
    margin-bottom: 30px;
    &:not(:last-child) {
        border-bottom: ${props => `1px solid ${props.theme.shades.lightGrey}`};
    }
`;

export const Question = styled.p`
    font-size: 18px;
    font-weight: bold;
    margin: 0;
`;
export const Answer = styled.p`
    font-size: 18px;
`;

export const Counter = styled.h1`
    margin: 0;
`;

export const AnswerCard = styled.p<{correct: boolean, selected: boolean}>`
    font-size: 18px;
    border: 2px solid;
    border-radius: 6px;
    padding: 10px;
    width: 100%;
    margin: 10px 0;
    
    ${(props) => {
        if (props.correct && !props.selected) {
            return `
                color: ${props.theme.shades.green};
                background-color: ${props.theme.shades.white};
                border-color: ${props.theme.shades.green};
            `;
        } else if (props.correct && props.selected) {
            return `
                color: ${props.theme.shades.white};
                background-color: ${props.theme.shades.green};
                border-color: ${props.theme.shades.green};
            `;
        } else {
            return `
                color: ${props.theme.shades.white};
                background-color: ${props.theme.shades.darkerGrey};
                border-color: ${props.theme.shades.darkerGrey};
            `;
        }
    }}
`;

export const CompleteButton = styled(Buttons.Button)`
    background: ${props => props.theme.buttons.blue};
    color: ${props => props.theme.text.white};
    width: 245px;
    height: 55px;
    margin-top: 60px;

    @media (max-width: ${props => props.theme.screen.medium}) {
        width: 100%;
        margin-left: 0;
    }
`;
