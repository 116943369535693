import { Modal } from '@apps/common-ui';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as S from './index.styles';
import { format, isAfter, parseISO } from 'date-fns';
import CompletionDatePicker from '../CompletionDatePicker';
import { formatDateWithWeekday, formatDateNoWeekday } from '../../utils';

type Props = {
    onClose: () => void;
    onSubmit: (completionDate: string) => void;
    assignedDate: string;
    submitting: boolean;
}

const ExerciseCompletionDateModal = ({ onClose, onSubmit, assignedDate, submitting }: Props) => {
    const today = format(new Date(), 'yyyy-MM-dd');
    const formattedAssignedDate = format(parseISO(assignedDate), 'yyyy-MM-dd');
    const defaultDate = isAfter(parseISO(formattedAssignedDate), new Date()) ? today : formattedAssignedDate;

    const [completionDate, setCompletionDate] = React.useState(defaultDate);

    const handleDateChange = (date: string) => {
        setCompletionDate(date);
    };

    const handleSubmit = () => {
        onSubmit(completionDate);
    };

    return (
        <Modal showModal dismissModal={onClose} closeOnOutsideClick={false} showTopBar={false} minHeight='50px' maxHeight="100vh" maxWidth="1000px" padded={false} overflow="auto">
            <S.ModalContent>
                <S.TitleBar>
                    <S.TitleContainer>
                        <S.Title>When did you complete this exercise?</S.Title>
                    </S.TitleContainer>
                    <S.CloseButtonContainer>
                        <S.CloseButton onClick={onClose}>
                            <FontAwesomeIcon icon={faX as IconProp} size="xs" /><p>Close</p>
                        </S.CloseButton>
                    </S.CloseButtonContainer>
                </S.TitleBar>
                <S.Message>
                    Was this exercise completed on the day it was assigned, <b>{formatDateWithWeekday(parseISO(assignedDate))}</b>?
                </S.Message>
                <S.Message>
                    Choose the date you completed the exercise:
                </S.Message>
                <S.CompletedDateContainer>
                    <S.CompletedDateLabel>Completed on</S.CompletedDateLabel>
                    <CompletionDatePicker
                      startDate={parseISO(assignedDate)}
                      onChange={handleDateChange}
                    />
                </S.CompletedDateContainer>
                <S.ButtonContainer>
                    <S.VitalsButton onClick={handleSubmit} disabled={submitting}>
                        {submitting ? (
                            <FontAwesomeIcon icon={faSpinner as IconProp} spin />
                        ) : (
                            `Submit ${formatDateNoWeekday(parseISO(completionDate))}`
                        )}
                    </S.VitalsButton>
                </S.ButtonContainer>
            </S.ModalContent>
        </Modal>
    );
};

export default ExerciseCompletionDateModal;
