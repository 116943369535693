import React, { useEffect, useState } from 'react';
import * as S from '../index.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import Mixpanel from '../../../services/Mixpanel';
import { CompletedForm, Form } from '../../../types/models';
import { useActionLoader } from '../../../hooks/useActionLoader';
import { fetchForms } from '../../../state/reducers/coaching/actions';
import { Buttons, Spinner } from '@apps/common-ui';
import { useLocation, useNavigate } from 'react-router';
import PostEvents from '../../../services/PostEvents';
import OnboardingService from '../../../services/OnboardingService';

type Props = {
    dispatchSetOnboardingComplete: (continueStatus: boolean) => void;
    sourcePage: 'home' | 'onboarding';
    setShowWelcomeVideo: (show: boolean) => void;
    welcomeVideoWatched: boolean;
}

const OnboardingList = ({ dispatchSetOnboardingComplete, sourcePage, setShowWelcomeVideo, welcomeVideoWatched }: Props) => {
    const { user } = useSelector((state: RootState) => state.session);
    const { forms, formsLoading } = useSelector((state: RootState) => state.coaching);
    const formatName = (name: string) => name.split(' - ')[0];
    const location = useLocation();
    const navigate = useNavigate();
    const [zoomLinkVisited, setZoomLinkVisited] = useState(OnboardingService.isZoomLinkVisited());
    const [initialFormsOnboarding, setInitialFormsOnboarding] = useState(
        OnboardingService.getInitialFormsOnboarding()
    );
    const [completedForms, setCompletedForms] = useState(OnboardingService.getCompletedForms());

    useEffect(() => {
        if (user && !OnboardingService.isInitialFormsSet() && forms.length > 0) {
            OnboardingService.setInitialFormsOnboarding(forms);
            setInitialFormsOnboarding(forms);
        }
    }, [user, forms]);

    useEffect(() => {
        if (!formsLoading && OnboardingService.isInitialFormsSet()) {
            const currentFormIds = new Set(forms.map((form: Form) => form.jotformId));
            const newCompletedForms = initialFormsOnboarding
                .filter((form: Form) => !currentFormIds.has(form.jotformId));
            const updatedInitialForms = initialFormsOnboarding
                .filter((form: Form) => currentFormIds.has(form.jotformId));
            const newCompletedFormsMapped: CompletedForm[] = newCompletedForms.map((form: Form) => ({
                formId: form.jotformId,
                formName: form.name
            }));

            if (newCompletedForms.length > 0) {
                const updatedCompletedForms: CompletedForm[] = [...completedForms, ...newCompletedFormsMapped];

                OnboardingService.setCompletedForms(updatedCompletedForms);
                OnboardingService.setInitialFormsOnboarding(updatedInitialForms);

                setCompletedForms(updatedCompletedForms);
                setInitialFormsOnboarding(updatedInitialForms);
            }
        }
    }, [forms, initialFormsOnboarding, completedForms, formsLoading]);

    const checkOnboardingComplete = () => {
        if (zoomLinkVisited && initialFormsOnboarding.length === 0 && welcomeVideoWatched) {
            dispatchSetOnboardingComplete(true);
        }
    };

    const handleZoomLink = (url: string) => {
        PostEvents.postZoomEvent('Zoom Downloaded');
        OnboardingService.markZoomLinkVisited();
        setZoomLinkVisited(true);
        checkOnboardingComplete();
        window.open(url, '_blank');
    };

    const handleZoomVisted = () => {
        PostEvents.postZoomEvent('Zoom Downloaded Already');
        OnboardingService.markZoomLinkVisited();
        setZoomLinkVisited(true);
        checkOnboardingComplete();
    };

    useEffect(() => {
        checkOnboardingComplete();
    }, [welcomeVideoWatched, initialFormsOnboarding]);

    if (formsLoading && location.pathname !== '/') {
        return <Spinner small />;
    }

    return (
        <>
            <S.OnboardingCard>
                <S.BSIconContainer>
                    <img src="/white_logo_transparent.png" alt="breathesuite icon" />
                </S.BSIconContainer>
                <S.MobileViewContainer completed={welcomeVideoWatched}>
                    <S.FormDetails>
                        <h4>Welcome Video</h4>
                    </S.FormDetails>
                    {welcomeVideoWatched
                     ? <S.OnboardingCompleteIcon src="/CompleteIcon.svg" />
                     : (
                         <S.OnboardingListButton
                           onClick={() => setShowWelcomeVideo(true)}
                         >
                             View
                         </S.OnboardingListButton>
                        )}
                </S.MobileViewContainer>
            </S.OnboardingCard>
            <S.OnboardingCard zoomCard>
                <S.ZoomCardContainer>
                    <S.IconContainer>
                        <img src="/ZoomIcon.svg" alt="zoom icon" />
                    </S.IconContainer>
                    <S.MobileViewContainer completed={zoomLinkVisited}>
                        <S.FormDetails>
                            <h4>Download Zoom App</h4>
                            {!zoomLinkVisited
                        && (
                        <p>
                            <Buttons.Button
                              buttonType="tertiary"
                              onClick={() => handleZoomVisted()}
                            >
                                Click here
                            </Buttons.Button>
                            if you already have Zoom
                        </p>
                        )}
                        </S.FormDetails>
                        {zoomLinkVisited
                    ? <S.OnboardingCompleteIcon src="/CompleteIcon.svg" />
                    : (
                        <S.OnboardingListButton
                          onClick={() => handleZoomLink('https://zoom.us/download')}
                        >
                            Get Zoom
                        </S.OnboardingListButton>
                    )}
                    </S.MobileViewContainer>
                </S.ZoomCardContainer>
                {!zoomLinkVisited
                        && (
                        <S.ZoomCardDetail>
                            <hr />
                            <p>
                                <Buttons.Button
                                  buttonType="tertiary"
                                  onClick={() => {
                                OnboardingService.markZoomLinkVisited();
                                setZoomLinkVisited(true);
                                checkOnboardingComplete();
                            }}
                                >
                                    Tap here
                                </Buttons.Button>
                                if you already have Zoom
                            </p>
                        </S.ZoomCardDetail>
                )}
            </S.OnboardingCard>
            {initialFormsOnboarding.map((form: Form) => (
                <S.OnboardingCard key={form.jotformId}>
                    <img src="/FormIcon.svg" alt="icon" />
                    <S.MobileViewContainer>
                        <S.FormDetails>
                            <h4>{formatName(form.name)}</h4>
                        </S.FormDetails>
                        <S.OnboardingListButton
                          onClick={() => {
                        Mixpanel.track(`web_form_started_${formatName(form.name)}`);
                        navigate(`/forms/${form.jotformId}`, { state: { sourcePage } });
                        }}
                        >
                            Start Form
                        </S.OnboardingListButton>
                    </S.MobileViewContainer>
                </S.OnboardingCard>
            ))}
            {completedForms.map(({ formId, formName }: CompletedForm) => (
                <S.OnboardingCard key={formId}>
                    <img src="/FormIcon.svg" alt="icon" />
                    <S.FormDetails>
                        <h4>{formatName(formName)}</h4>
                    </S.FormDetails>
                    <S.OnboardingCompleteIcon src="/CompleteIcon.svg" />
                </S.OnboardingCard>
            ))}
        </>
    );
};

export default OnboardingList;
