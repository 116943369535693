import { Slider, Inputs, Modal } from '@apps/common-ui';
import { ExerciseTypes, usePromptToScroll } from '@apps/common-utilities';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import BreathlessnessSlider from '../../../../../components/Sliders/BreathlessnessSlider';
import Mixpanel from '../../../../../services/Mixpanel';
import * as S from './index.styles';
import { useForm } from 'react-hook-form';
import RMTDifficultySlider from '../../../../../components/Sliders/RMTDifficultySlider';
import VitalTipCard from '../VitalTipCard';
import VitalTipInfo from '../VitalTipInfo';

type Props = {
    exercise: ExerciseTypes.IExerciseSummary;
    onClose: () => void;
    checkSubmission: Function;
    showOxygen: boolean;
    submitting: boolean;
}

const getSetsLabels = (numSets: number, defaultAmount: number): ExerciseTypes.IAssignedExerciseResults[] => {
    const array: ExerciseTypes.IAssignedExerciseResults[] = [];
    for (let i = 1; i <= numSets; i += 1) {
        array.push({
            setNumber: i,
            amount: defaultAmount
        });
    }
    return array;
};

const PostExerciseBaseline = ({ onClose, exercise, checkSubmission, showOxygen, submitting }: Props) => {
    const [breathlessnessSliderValue, setBreathlessnessSliderValue] = useState(0);
    const [rmtDifficultySliderValue, setRMTDifficultySliderValue] = useState(0);
    const [oxygenAnswer, setOxygenAnswer] = useState(showOxygen);
    const [showInfo, setShowInfo] = useState(false);
    const { date } = useParams<{date: string}>();
    const containerRef = useRef<HTMLDivElement>(null);
    const ScrollPrompt = usePromptToScroll(containerRef, 'More below');
    const isRMT = exercise.exerciseInfo.type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS;
    const [comment, setComment] = useState('');

    const handleCommentChange = (newComment: string) => {
        setComment(newComment);
    };

    const [sets, setSets] = useState<{ setNumber: number, amount?: number }[]>(
        getSetsLabels(exercise.exerciseVariable.sets, exercise.exerciseVariable.amount)
    );

    const handleShowInfo = () => {
        setShowInfo(prevShowInfo => !prevShowInfo);
    };

    const schema = yup.object({
        heartRate: yup.number().when('heartRateRequired', {
            is: () => exercise.exerciseVariable.requireHeartRateReporting,
            then: yup.number().transform((num) => Number.isNaN(Number(num)) ? undefined : Number(num)).required('Heart rate is required').min(25, 'Check your reading again, your HR seems too low.')
                .max(220, 'Check your reading again, your HR seems too high.')
        }),
        immediateSpO2Percent: yup.number().when('immediateSpO2Required', {
            is: () => exercise.exerciseVariable.requireImmediateSpO2PercentReporting,
            then: yup.number().transform((num) => Number.isNaN(Number(num)) ? undefined : Number(num)).required('Immediate SpO2 is required').min(65, 'Check your reading again, your Immediate SpO2 seems too low.')
                .max(100, 'It is not possible to have your SpO2 more than 100%.')
        }),
        lowestSpO2Percent: yup.number().when('lowestSpO2Required', {
            is: () => exercise.exerciseVariable.requireLowestSpO2PercentReporting,
            then: yup.number().transform((num) => Number.isNaN(Number(num)) ? undefined : Number(num)).required('Lowest SpO2 is required').min(65, 'Check your reading again, your Lowest SpO2 seems too low.')
                .max(100, 'It is not possible to have your SpO2 more than 100%.')
        }),
        flowRate: yup.number().when('oxygenQuestion', {
            is: () => (oxygenAnswer && exercise.exerciseVariable.requireFlowRateReporting),
            then: yup.number().transform((num) => Number.isNaN(Number(num)) ? undefined : Number(num)).required('Flow rate is required').min(1, 'Is your oxygen flow rate accurate? This appears outside the expected range.')
                .max(15, 'Is your oxygen flow rate accurate? This appears outside the expected range.'),
            otherwise: yup.number().transform((num) => Number.isNaN(Number(num)) ? undefined : Number(num)).notRequired()
        }),
        rmtInhaleResistance: yup.number().when('rmtInhaleResistanceRequired', {
            is: () => exercise.exerciseVariable.requireRMTInhaleResistanceReporting,
            then: yup.number().transform((num) => Number.isNaN(Number(num)) ? undefined : Number(num)).required('Inhale resistance is required').min(0, 'Check your reading again, your Inhale resistance seems too low.')
                .max(6, 'Check your reading again, your Inhale resistance seems too high.')
        }),
        rmtExhaleResistance: yup.number().when('rmtExhaleResistanceRequired', {
            is: () => exercise.exerciseVariable.requireRMTExhaleResistanceReporting,
            then: yup.number().transform((num) => Number.isNaN(Number(num)) ? undefined : Number(num)).required('Exhale resistance is required').min(0, 'Check your reading again, your Exhale resistance seems too low.')
                .max(5, 'Check your reading again, your Exhale resistance seems too high.')
        }),
        comment: yup.string().trim().nullable()
            .max(2000, 'Comment is too long, must be less than 2000 characters')
    });

    const { register, handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            rmtExhaleResistance: exercise.exerciseVariable.rmtExhaleResistance || undefined,
            rmtInhaleResistance: exercise.exerciseVariable.rmtInhaleResistance || undefined,
            heartRate: undefined,
            immediateSpO2Percent: undefined,
            lowestSpO2Percent: undefined,
            flowRate: undefined,
            comment: '',
        }
    });

    const getSetsDescription = (type: ExerciseTypes.ExerciseType) => {
        switch (type) {
            case ExerciseTypes.ExerciseType.SETS_AND_REPS:
                return 'What were you able to complete?';
            case ExerciseTypes.ExerciseType.INTERVALS:
                return 'How many intervals did you complete?';
            case ExerciseTypes.ExerciseType.HOLD:
                return 'How many of the sets & reps with hold did you complete?';
            case ExerciseTypes.ExerciseType.SETS_AND_BREATHS:
                return 'What were you able to complete?';
            default:
                return 'What were you able to complete?';
        }
    };

    const updateSetAmount = (value: string, index: number) => {
        const temp = sets.map((set, setIndex) => {
            if (index === setIndex) {
                // Since 0 is a valid input, set to undefined so that we know when the
                // input wasn't filled out at all
                return {
                    setNumber: setIndex + 1,
                    amount: value === '' ? undefined : Number(value)
                };
            }
            return set;
        });
        setSets(temp);
    };

    const createMetrics = (data: any): void => {
        Mixpanel.track('web_post_exercise_submit_clicked', {
            date,
            exerciseName: exercise.exerciseInfo.title
        });
        let results;
        let breathlessness;
        let difficulty;

        if (exercise.exerciseVariable.requireActivityReporting) {
            // send all sets if exercise is sets and reps or hold
            if (exercise.exerciseInfo.type === ExerciseTypes.ExerciseType.SETS_AND_REPS
                || exercise.exerciseInfo.type === ExerciseTypes.ExerciseType.HOLD
                || exercise.exerciseInfo.type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS) {
                results = sets;
            } else {
                // Intervals is entered as a single number, so
                // create an array filled with all sets if exercise is intervals
                const setsCompleted = sets[0].amount || 0;
                results = [];
                for (let i = 0; i < setsCompleted; i += 1) {
                    const setNumber = i + 1;
                    results.push({ setNumber, amount: exercise.exerciseVariable.sets });
                }
            }
        } else {
            results = null;
        }

        if (exercise.exerciseVariable.requireBreathlessnessLevelReporting) {
            breathlessness = breathlessnessSliderValue < 1 ? breathlessnessSliderValue : Math.floor(breathlessnessSliderValue);
        } else {
            breathlessness = null;
        }

        if (exercise.exerciseVariable.requireRMTDifficultyReporting) {
            // RMT difficulty is 1-5, not 0-4 but the slider is 0-4 so increment by 1 to get the correct value
            difficulty = rmtDifficultySliderValue + 1;
        } else {
            difficulty = null;
        }

        const metrics = {
            ...data,
            assignedExerciseResults: results,
            breathlessnessLevel: breathlessness,
            rmtDifficulty: difficulty,
            comment
        };
        // Don't allow submission if any rep inputs weren't filled out
        if (sets.some(set => set.amount === undefined)) {
            return;
        }
        checkSubmission(metrics);
    };

    return (
        <Modal showModal dismissModal={onClose} closeOnOutsideClick={false} showTopBar={false} maxHeight="100vh" maxWidth="1200px" padded={false}>
            <S.ModalContent ref={containerRef}>
                <S.TitleBar>
                    <S.TitleContainer>
                        <S.Title>Post Exercise Review</S.Title>
                    </S.TitleContainer>
                    <S.CloseButtonContainer>
                        <S.CloseButton onClick={() => onClose()}><FontAwesomeIcon icon={faX as IconProp} size="xs" /><p>Close</p></S.CloseButton>
                    </S.CloseButtonContainer>
                </S.TitleBar>
                <S.Content>
                    <S.Form onSubmit={handleSubmit(createMetrics)}>
                        {(exercise.exerciseVariable.requireHeartRateReporting
                            || exercise.exerciseVariable.requireFlowRateReporting
                            || exercise.exerciseVariable.requireImmediateSpO2PercentReporting
                            || exercise.exerciseVariable.requireLowestSpO2PercentReporting) && (
                            <S.FormContainer>
                                <S.InputsRow>

                                    {exercise.exerciseVariable.requireImmediateSpO2PercentReporting && (
                                    <S.InputContainer>
                                        <S.InputLabel style={{ marginBottom: 0 }}>Immediate <br /> SpO2 (%)
                                        </S.InputLabel>
                                        <S.HelperText>
                                            What is your SpO2 right after you finished exercising?
                                        </S.HelperText>
                                        <S.BaselineInput
                                          style={{ marginTop: '3px' }}
                                          inputSize={Inputs.InputSize.extraSmall}
                                          {...register('immediateSpO2Percent')}
                                          onFocus={() => Mixpanel.track('web_post_exercise_baseline_input', {
                                        input: 'immediate spO2'
                                    })}
                                        />
                                    </S.InputContainer>
                                )}
                                    {exercise.exerciseVariable.requireLowestSpO2PercentReporting && (
                                    <S.InputContainer>
                                        <S.InputLabel style={{ marginBottom: 0 }}>Lowest <br /> SpO2 (%)
                                        </S.InputLabel>
                                        <S.HelperText>
                                            What is the lowest SpO2 reached during your recovery?
                                        </S.HelperText>
                                        <S.BaselineInput
                                          style={{ marginTop: '3px' }}
                                          inputSize={Inputs.InputSize.extraSmall}
                                          {...register('lowestSpO2Percent')}
                                          onFocus={() => Mixpanel.track('web_post_exercise_baseline_input', {
                                        input: 'lowest spO2'
                                    })}
                                        />
                                    </S.InputContainer>
                                )}
                                    {exercise.exerciseVariable.requireHeartRateReporting && (
                                    <S.InputContainer>
                                        <S.InputLabel>Heart Rate <br /> (bpm)</S.InputLabel>
                                        <S.BaselineInput
                                          inputSize={Inputs.InputSize.extraSmall}
                                          {...register('heartRate')}
                                          onFocus={() => Mixpanel.track('web_post_exercise_baseline_input', {
                                        input: 'heart-rate'
                                    })}
                                        />
                                    </S.InputContainer>
                                )}
                                    <S.InputContainerFlowRate>
                                        {exercise.exerciseVariable.requireFlowRateReporting && (
                                        <S.InputContainer>
                                            <S.InputLabel>Are you on <br /> Oxygen?</S.InputLabel>
                                            <Inputs.Checkbox
                                              id="oxygen-adjustment"
                                              activeText="Yes"
                                              inactiveText="Yes"
                                              defaultChecked={oxygenAnswer}
                                              disabled={false}
                                              onChange={() => {
                                        setOxygenAnswer(!oxygenAnswer);
                                        Mixpanel.track('web_post_exercise_baseline_input', {
                                                input: 'oxygen',
                                            });
                                        }}
                                            />
                                        </S.InputContainer>
                                    )}
                                        {exercise.exerciseVariable.requireFlowRateReporting && oxygenAnswer && (
                                        <S.InputContainer>
                                            <S.InputLabel>Flow rate<br /> (L/min)</S.InputLabel>
                                            <S.BaselineInput
                                              inputSize={Inputs.InputSize.extraSmall}
                                              {...register('flowRate')}
                                              onFocus={() => Mixpanel.track('web_post_exercise_baseline_input', {
                                                input: 'flow rate'
                                            })}
                                            />
                                        </S.InputContainer>
                                    )}
                                    </S.InputContainerFlowRate>
                                </S.InputsRow>
                            </S.FormContainer>
                        )}
                        {exercise.exerciseVariable.requireBreathlessnessLevelReporting && (
                            <BreathlessnessSlider
                              value={breathlessnessSliderValue}
                              width="400px"
                              onChange={(e: any) => {
                                setBreathlessnessSliderValue(e.target.value);
                                Mixpanel.track('web_post_exercise_baseline_input', {
                                input: 'breathlessness',
                                });
                            }}
                            />
                        )}
                        {exercise.exerciseVariable.requireRMTDifficultyReporting && (
                            <RMTDifficultySlider
                              value={rmtDifficultySliderValue}
                              width="400px"
                              onChange={(e: any) => {
                                setRMTDifficultySliderValue(parseInt(e.target.value, 10));
                                Mixpanel.track('web_post_exercise_baseline_input', {
                                input: 'rmt difficulty',
                                });
                            }}
                            />
                        )}
                        {exercise.exerciseInfo.type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS
                        && (
                            // Show if at least one reporting is required
                            exercise.exerciseVariable.requireRMTInhaleResistanceReporting
                            || exercise.exerciseVariable.requireRMTExhaleResistanceReporting
                        ) && (
                            <S.CompletionContainer>
                                <S.CompleteLabel>What was your resistance?</S.CompleteLabel>
                                <S.SetsRepsRow>
                                    {exercise.exerciseVariable.requireRMTInhaleResistanceReporting && (
                                        <S.SetsRepsContainer>
                                            <Inputs.Input
                                              inputSize={Inputs.InputSize.extraSmall}
                                              {...register('rmtInhaleResistance', { required: true })}
                                              type="number"
                                              min={0}
                                              max={6}
                                              onFocus={() => Mixpanel.track('web_post_exercise_baseline_input', {
                                                input: 'rmt inhale resistance'
                                            })}
                                            />
                                            <S.RepsLabel>Inhale Resistance</S.RepsLabel>
                                        </S.SetsRepsContainer>
                                    )}
                                    {exercise.exerciseVariable.requireRMTExhaleResistanceReporting && (
                                        <S.SetsRepsContainer>
                                            <Inputs.Input
                                              inputSize={Inputs.InputSize.extraSmall}
                                              {...register('rmtExhaleResistance', { required: true })}
                                              type="number"
                                              min={0}
                                              max={5}
                                              onFocus={() => Mixpanel.track('web_post_exercise_baseline_input', {
                                                input: 'rmt exhale resistance'
                                            })}
                                            />
                                            <S.RepsLabel>Exhale Resistance</S.RepsLabel>
                                        </S.SetsRepsContainer>
                                    )}
                                </S.SetsRepsRow>
                                {sets.some((set) => set.amount === undefined) && (
                                <S.ErrorContainer>
                                    <S.ErrorMessage>Please ensure all sets are filled out</S.ErrorMessage>
                                </S.ErrorContainer>
                                )}
                            </S.CompletionContainer>
                        )}
                        {exercise.exerciseVariable.requireActivityReporting && (
                        <S.CompletionContainer>
                            <S.CompleteLabel>{getSetsDescription(exercise.exerciseInfo.type)}</S.CompleteLabel>
                            <S.SetsRepsRow>
                                {(exercise.exerciseInfo.type === ExerciseTypes.ExerciseType.SETS_AND_REPS || exercise.exerciseInfo.type === ExerciseTypes.ExerciseType.HOLD) && sets.map((item, index) => (
                                    <S.SetsRepsContainer>
                                        <S.SetsLabel>Set {index + 1}</S.SetsLabel>
                                        <Inputs.Input
                                          inputSize={Inputs.InputSize.extraSmall}
                                          type="number"
                                          min={0}
                                          value={item.amount}
                                          defaultValue={item.amount}
                                          onChange={(e: any) => updateSetAmount(e.target.value, index)}
                                          onFocus={() => Mixpanel.track('web_post_exercise_baseline_input', {
                                            input: `set-reps-${index + 1}`
                                        })}
                                        />
                                        <S.RepsLabel>Total Reps</S.RepsLabel>
                                    </S.SetsRepsContainer>
                                ))}
                                {exercise.exerciseInfo.type === ExerciseTypes.ExerciseType.INTERVALS && (
                                <S.SetsRepsContainer>
                                    <Inputs.Input
                                      inputSize={Inputs.InputSize.extraSmall}
                                      type="number"
                                      min={0}
                                      value={sets[0].amount}
                                      defaultValue={sets[0].amount}
                                      onChange={(e: any) => updateSetAmount(e.target.value, 0)}
                                      onFocus={() => Mixpanel.track('web_post_exercise_baseline_input', {
                                        input: 'interval_or_hold'
                                    })}
                                    />
                                    <S.RepsLabel>/ {exercise.exerciseVariable.amount}</S.RepsLabel>
                                </S.SetsRepsContainer>
                                )}
                                {exercise.exerciseInfo.type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS && sets.map((item, index) => (
                                    <S.SetsRepsContainer>
                                        <Inputs.Input
                                          inputSize={Inputs.InputSize.extraSmall}
                                          type="number"
                                          min={0}
                                          value={sets[index].amount}
                                          defaultValue={sets[index].amount}
                                          onChange={(e: any) => updateSetAmount(e.target.value, index)}
                                          onFocus={() => Mixpanel.track('web_post_exercise_baseline_input', {
                                                input: 'sets'
                                            })}
                                        />
                                        <S.RepsLabel>/ {exercise.exerciseVariable.amount} Breath Cycles</S.RepsLabel>
                                    </S.SetsRepsContainer>
                                ))}
                            </S.SetsRepsRow>
                            {sets.some((set) => set.amount === undefined) && (
                            <S.ErrorContainer>
                                <S.ErrorMessage>Please ensure all sets are filled out</S.ErrorMessage>
                            </S.ErrorContainer>
                            )}
                        </S.CompletionContainer>
                        )}
                        <S.CommentContainer>
                            <S.CommentLabelContainer>
                                <S.CommentLabel>Do you have any notes or feedback about this exercise?</S.CommentLabel>
                                <S.CommentOptionalLabel>(optional)</S.CommentOptionalLabel>
                            </S.CommentLabelContainer>
                            <S.CommentTextBox
                              {...register('comment', {
                                onChange: (e) => handleCommentChange(e.target.value)
                            })}
                              placeholder="Enter your feedback here"
                              value={comment}
                            />
                        </S.CommentContainer>
                        {!!errors && (
                        <S.ErrorContainer>
                            {Object.values(errors).filter((e => e?.message !== '')).map((e) => <S.ErrorMessage role="alert">{e?.message}</S.ErrorMessage>)}
                        </S.ErrorContainer>
                            )}
                        <S.SubmitRow>
                            <S.SubmitButton type="submit" disabled={submitting}>{submitting ? <FontAwesomeIcon icon={faSpinner as IconProp} spin /> : 'Submit Results'}</S.SubmitButton>
                        </S.SubmitRow>
                    </S.Form>
                    { !isRMT && (
                        <S.Tips>
                            {!showInfo && <VitalTipCard onClick={handleShowInfo} />}
                            {showInfo && <VitalTipInfo onClick={handleShowInfo} />}
                        </S.Tips>
                    )}
                </S.Content>
                <ScrollPrompt />
            </S.ModalContent>
        </Modal>
    );
};

export default PostExerciseBaseline;
