import { useEffect } from 'react';
import Smartlook from 'smartlook-client';

const useSmartlook = (userId?: string) => {
    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
            const smartlookKey = process.env.REACT_APP_SMARTLOOK_KEY || '';

            if (!Smartlook.initialized()) {
                Smartlook.init(smartlookKey);
            }
            if (userId) {
                Smartlook.identify(userId, {});
            }
        }
    }, [userId]);
};

export default useSmartlook;
