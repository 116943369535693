import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HTTPBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

i18n
    .use(ChainedBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        react: {
            useSuspense: false
        },
        backend: {
            backends: [
                LocalStorageBackend,
                HTTPBackend,
            ],
            backendOptions: [
                {
                    expirationTime: 60 * 60, // 1 hour
                },
                {
                    load: 'languageOnly',
                    react: {
                        useSuspense: false,
                    },
                    keySeparator: '.',
                    fallbackLng: 'en',
                }
            ],
        },
    });

export default i18n;
