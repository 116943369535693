import styled from 'styled-components';
import { Buttons, Card, Inputs } from '@apps/common-ui';
import Container from '../../components/common/Container';
import Form from '../../components/common/Form';
import { FullWidthButton } from '@apps/common-ui/src/Button';

interface SubmitButtonProps {
  submittable: boolean;
  fullContainerWidth?: boolean;
}

interface InputContainerProps {
  isFullWidth?: boolean;
  isFullWidthOnMobile?: boolean;
}

interface InputLabelProps {
  nowWrap?: boolean;
}

interface FormRowProps {
  columnOnMobile?: boolean;
  columnOnTablet?: boolean;
  spaceBetween?: boolean;
}

interface FormTitleProps {
  noTopMargin?: boolean;
}

interface PasswordWithButtonProps {
  justifyContent?: string;
}

interface FormSubtitleProps {
  hasButtonUnderneath?: boolean;
}

interface TopBarProps {
  mobileRightSidePadding?: string;
}

interface SignUpInputProps {
  isFullWidth?: boolean;
}

export const LoginContainer = styled(Container)`
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  boxShadow: none;
  height: 100%;
  background: ${props => props.theme.shades.white};

  @media (max-width: ${props => props.theme.screen.small}) {
    width: 100vw;
  }
`;

export const TopBar = styled.div<TopBarProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  padding: 30px;
  @media (max-width: ${props => props.theme.screen.medium}) {
    margin-bottom: 0px;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: ${props => props.mobileRightSidePadding ? props.mobileRightSidePadding : '30px'};
  }
`;

export const TopBarLogo = styled.img`
  width: 190px;
  height: 60px;
  object-fit: contain;
  
  @media (max-width: ${props => props.theme.screen.small}) {
    width: 100px;
    height: 40px;
    border: none;
  }
`;

export const TopBarButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TopBarButton = styled(Buttons.Button)`
  background: transparent;
  border: none;
  color: ${props => props.theme.primary.medium};

  @media (max-width: ${props => props.theme.screen.small}) {
    margin-bottom: 0px;
    margin-top: 0px;
    margin-right: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
    padding-right: 0px;
  }
`;

export const LoginFormContainer = styled(Card)`
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: none;
  width: 40%;
  max-width: 600px;
  padding: 0px;
  
  @media (max-width: ${props => props.theme.screen.small}) {
    justify-content: flex-start;
    align-items: flex-start;
    width: 100vw;
    height: 100%;
    padding: 0 30px 30px 30px;
  }
  
`;

export const SignUpFormContainer = styled(Card)`
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border: none;
  box-shadow: none;
  width: 80%;
  max-width: 800px;
  padding: 0px 20px 40px 20px;
  margin-bottom: 50px;
  margin-top: 0px;

  @media (max-width: ${props => props.theme.screen.small}) {
    justify-content: flex-start;
    width: 100vw;
    padding: 0px;
    margin-bottom: 0px;
  }
`;

export const FormTitle = styled.h2<FormTitleProps>`
  color: ${props => props.theme.primary.medium};
  font-size: 26px;
  width: 400px;
  margin-top: ${props => props.noTopMargin ? '0px' : '20px;'};
  margin-bottom: 20px;

  @media (max-width: ${props => props.theme.screen.small}) {
    width: 100%;
  }
`;

export const FormSubtitle = styled.p<FormSubtitleProps>`
  font-size: 18px;
  line-height: 20px;
  width: 400px;
  margin-top: 0px;
  margin-bottom: ${props => props.hasButtonUnderneath ? '10px' : '20px'};

  @media (max-width: ${props => props.theme.screen.small}) {
    width: 100%;
    margin-bottom: ${props => props.hasButtonUnderneath ? '20px' : '30px'};
  }
`;

export const AuthInputContainer = styled(Inputs.InputContainer)`
  align-items: center;

  @media (max-width: ${props => props.theme.screen.small}) {
    width: 100%;
  }
`;

export const SignUpAuthInputContainer = styled(Inputs.InputContainer)<InputContainerProps>`
  align-items: flex-start;
  width: ${props => props.isFullWidth ? '100%' : '45%'};

  @media (max-width: ${props => props.theme.screen.medium}) {
    width: ${props => props.isFullWidthOnMobile || props.isFullWidth ? '100%' : '45%'};
  }

  @media (max-width: ${props => props.theme.screen.smallTablet}) {
    width: 100%;
  }
`;

export const CodeInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 400px;
  align-self: center;

  input[type='number'] {
    -moz-appearance:textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  @media (max-width: ${props => props.theme.screen.small}) {
    width: 85vw;
    margin-top: 5px;
  }
`;

export const SignUpLink = styled.a`
  text-align: start;
  width: 20%;
  white-space: nowrap;
  font-size: 16px;
`;

export const FormContainer = styled.div`
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid ${props => props.theme.primary};
  padding: 20px;
  .amplify-field {
    background-color: white;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .amplify-field .amplify-countrycodeselect {
    margin-bottom: 0;
  }
`;

export const LoginButtons = styled.div`
  width: 100%;
  display: flex;
  margin: 15px 0;
`;

export const LoginButton = styled(Buttons.Button)`
  box-shadow: none;
  font-weight: bold;
  font-size: 1rem;
  padding: 5px;
  background-color: #FBFBFB;
  color: ${props => props.theme.primary.dark};
  :hover {
    background-color: white;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 400px;
  align-self: center;

  @media (max-width: ${props => props.theme.screen.small}) {
    width: 90vw;
  }
`;

export const Message = styled.p<{
  type?: 'success' | 'error' | 'info';
}>`
  color: ${props => {
        switch (props.type) {
            case 'success':
                return props.theme.text.success;
            case 'error':
                return props.theme.text.error;
            default:
                return props.theme.primary.medium;
        }
    }};
  font-size: 0.8rem;
  margin-top: 5px;
  text-align: center;
  &:first-letter {
    text-transform: capitalize;
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction row;
  justify-content: space-between;
  width: 400px;

  @media (max-width: ${props => props.theme.screen.small}) {
    width: 100%;
  }
`;

export const LabelWithTipContainer = styled.div`
  height: 60px;

  @media (max-width: ${props => props.theme.screen.smallTablet}) {
    height: auto;
  }
`;

export const LabelTip = styled.p`
  margin: 0px;
`;

export const LoginLabel = styled(Inputs.Label)<InputLabelProps>`
  font-size: 18px;
  color: ${props => props.theme.shades.black};
  width: auto;
  margin-left: 0px;
  white-space: ${props => props.nowWrap ? 'nowrap' : 'normal'};
`;

export const SignUpLabel = styled(Inputs.Label)`
  font-size: 18px;
  color: ${props => props.theme.shades.black};
  width: auto;
  margin-left: 0px;
  white-space: nowrap;
`;

export const LabelLink = styled(Buttons.LinkButton)`
  background: transparent;
  border: none;
  color: ${props => props.theme.buttons.blue};
  font-size: 18px;
  font-weight: 600;
  padding: 0 0 0 9px;
  margin: 5px 0 0 0;
  width: 160px;

  @media (max-width: ${props => props.theme.screen.medium}) {
    display: none;
  }
`;

export const MobileForgotPassword = styled(Buttons.LinkButton)`
  display: none;
  background: transparent;
  border: none;
  color: ${props => props.theme.buttons.blue};
  font-size: 18px;
  font-weight: 600;
  align-self: center;

  @media (max-width: ${props => props.theme.screen.medium}) {
    display: block;
  }
`;

export const ChangeEmailButton = styled(Buttons.Button)`
  background: transparent;
  border: none;
  color: ${props => props.theme.buttons.blue};
  font-size: 18px;
  font-weight: 600;
  padding: 0px;
  margin: 0 0 30px 0;
  width: 400px;
  text-align: start;

  @media (max-width: ${props => props.theme.screen.small}) {
    width: 90vw;
    margin: 0 0 30px 0;
  }
`;

export const ResendButton = styled(Buttons.Button)`
  background: transparent;
  border: none;
  color: ${props => props.theme.buttons.blue};
  font-size: 18px;
  font-weight: 600;
  padding: 0 0 0 48px;
  margin: 5px 0 0 0;
  width: 160px;
`;

export const LoginInput = styled(Inputs.Input)`
  padding: 15px;  
  background-color: ${props => props.theme.shades.lightestGrey};
  font-size: 1rem;
  border-width: 1px;
  margin-right: 0px;
  margin-left: 0px;

  @media (max-width: ${props => props.theme.screen.medium}) {
    width: 400px;
  }

  @media (max-width: ${props => props.theme.screen.small}) {
    max-width: 100%;
    width: 100%;
  }
`;

export const LoginCodeInput = styled(LoginInput)`
  text-align: center;

  @media (max-width: ${props => props.theme.screen.medium}) {
    width: 45px;
  }
`;

export const SignUpInput = styled(Inputs.Input)<SignUpInputProps>`
  padding: 15px;  
  background-color: ${props => props.theme.shades.lightestGrey};
  font-size: 1rem;
  border-width: 1px;
  margin-left: 0px;
  margin-right: 0px;
  max-width: ${props => props.isFullWidth ? '100%' : '400px'};
  overflow-x: hidden;

  @media (max-width: ${props => props.theme.screen.medium}) {
    max-width: 100%;
  }

  @media (max-width: ${props => props.theme.screen.smallTablet}) {
    width: 100%;
  }
`;

export const SignUpForm = styled(Form)`
    margin-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    overflow-x: hidden;
    overflow-y: auto;

    @media (max-width: ${props => props.theme.screen.small}) {
      width: 100vw;
      padding: 0px 22px 100px 22px;
    }
`;

export const FormRow = styled.div<FormRowProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: ${props => props.theme.screen.medium}) {
    flex-direction: ${props => props.columnOnTablet ? 'column' : 'row'};
    align-items: ${props => props.columnOnTablet ? 'flex-start' : 'center'};
  }

  @media (max-width: ${props => props.theme.screen.smallTablet}) {
    flex-direction: ${props => props.columnOnMobile ? 'column' : 'row'};
    align-items: ${props => props.columnOnMobile ? 'flex-start' : 'center'};
  }
`;

export const SignUpParagraph = styled.p`
  width: 600px;
  align-self: center;
  text-align: center;

  @media (max-width: ${props => props.theme.screen.medium}) {
    width: 300px;
  }
`;

export const AlreadyHaveAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-top: 50px;
`;

export const AlreadyHaveAccountParagraph = styled.p`
  font-weight: bold;
  margin: 0px;
`;

export const AlreadyHaveAccountButton = styled(Buttons.Button)`
  background: transparent;
  border: none;
  color: ${props => props.theme.buttons.blue};
  margin-top: 0px;
  padding-top: 5px;
`;

export const PasswordViewButtonContainer = styled.div`
  position: absolute;
  right: 15%;
  top: 62%;
  transform: translateY(-50%);
`;

export const PasswordButton = styled(Buttons.Button)`
  position: absolute;
  left: 70%;
  background: transparent;
  color: ${props => props.theme.buttons.blue};
  border: none;
  font-size: 15px;
  width: 50px;
  height: 20px;
  padding: 0px;
  align-self: center;

  @media (max-width: ${props => props.theme.screen.medium}) {
    left: 82%;
  }

  @media (max-width: ${props => props.theme.screen.smallTablet}) {
    left: 90%;
  }

  @media (max-width: ${props => props.theme.screen.small}) {
    left: 65vw;
  }
`;

export const SignUpPasswordButton = styled(PasswordButton)`
  @media (max-width: ${props => props.theme.screen.medium}) {
    left: 87%;
  }

  @media (max-width: ${props => props.theme.screen.smallTablet}) {
    left: 80%;
  }
`;

export const SubmitButton = styled(Buttons.Button)<SubmitButtonProps>`
  background: ${props => props.submittable ? props.theme.buttons.blue : props.theme.buttons.grey};
  border-color: ${props => props.submittable ? props.theme.buttons.blue : props.theme.buttons.grey};
  margin: 20px 0px 20px 0px;
  width: 100%; 
  height: 50px;
  max-width: ${props => props.fullContainerWidth ? '100%' : '400px'};
  align-self: center;
  font-weight: bold;

  @media (max-width: ${props => props.theme.screen.medium}) {
    width: 400px;
  }

  @media (max-width: ${props => props.theme.screen.small}) {
    max-width: 100%;
    width: 100%;
  }
  
`;

export const PasswordWithButtonContainer = styled.div<PasswordWithButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'space-between'};
  width: 100%;

  @media (max-width: ${props => props.theme.screen.medium}) {
    width: 400px;
  }

  @media (max-width: ${props => props.theme.screen.small}) {
    width: 100%;
  }

`;

export const SignUpPasswordWithButtonContainer = styled(PasswordWithButtonContainer)`
  @media (max-width: ${props => props.theme.screen.medium}) {
    width: 100%;
  }

  @media (max-width: ${props => props.theme.screen.smallTablet}) {
    width: 100%;
  }
`;

export const MobileSignUpImage = styled.img`
  display: none;
  object-fit: contain;
  width: 75px;
  height: 45px;
  align-self: center; 
  margin-top: 30px;

  @media (max-width: ${props => props.theme.screen.medium}) {
    display: block;
  }
`;
