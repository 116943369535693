import { Auth } from 'aws-amplify';

export interface RequestOptions {
    skipAuth?: boolean;
    params?: { [key: string]: any };
    skipDeduplication?: boolean;
}

export abstract class Api {
    constructor(url: string) {
        this.endpoint = url;
    }
    private endpoint;
    private headers = {
        Accept: 'application/json, text/plain',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Request-Source': 'Web App',
        'X-User-Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    // Keep track of active request URLs to avoid sending duplicates
    private activePostRequests = new Map<string, boolean>();

    private async getToken(): Promise<string | null> {
        const authUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
        if (authUser.signInUserSession === null) {
            return null;
        }
        return authUser.signInUserSession.idToken.jwtToken;
    }

    private async getHeaders(options: any) {
        const headers: any = { ...this.headers };
        if (!options || !options.skipAuth) {
            const token = await this.getToken();
            headers.Authorization = `Bearer ${token}`;
        }
        return headers;
    }

    private async wrappedFetch<T=any>(url: string, options: any): Promise<T> {
        const headers = await this.getHeaders(options);
        return fetch(url, {
            ...options,
            headers
        }).then(res => this.handleResponse(res));
    }

    private async handleResponse(res: Response): Promise<any> {
        const isOk = res.ok;
        if (res.status === 303 || res.status === 204) {
            // 303 returns a url for redirecting, 204 is no content
            // since there's no json we return the link as text
            return res.text();
        }
        if (res.status === 404) {
            return null;
        }
        const response = await res.json().catch(err => {
            // Problem parsing json. Not necessarily an error
            // eslint-disable-next-line no-console
            console.log(err);
            return null;
        });

        if (isOk) {
            return response;
        }
        return this.handleError(response, res.status);
    }

    abstract handleError(res: Response, status: number): Promise<any>;

    public async get<T=any>(path: string, options?: RequestOptions): Promise<T> {
        let url = this.endpoint + path;
        if (options && options.params) {
            const params = new URLSearchParams(options.params).toString();
            url = `${url}?${params}`;
        }
        return this.wrappedFetch(url, {
            method: 'GET',
        });
    }

    public async post<T=any>(path: string, body: any, options?: RequestOptions): Promise<T> {
        if (this.activePostRequests.get(path) && !(options && options.skipDeduplication)) {
            return null as any;
        } else {
            // set this endpoint as active (true), once the wrapped fetch is resolved or rejected we will set it back to false
            this.activePostRequests.set(path, true);
            return this.wrappedFetch(`${this.endpoint}${path}`, {
                method: 'POST',
                body: JSON.stringify(body),
                ...options
            }).finally(() => this.activePostRequests.set(path, false));
        }
    }

    public async patch<T=any>(path: string, body: any, options?: RequestOptions): Promise<T> {
        return this.wrappedFetch(`${this.endpoint}${path}`, {
            method: 'PATCH',
            body: JSON.stringify(body),
        });
    }

    public async put<T=any>(path: string, body: any, options?: RequestOptions): Promise<T> {
        return this.wrappedFetch(`${this.endpoint}${path}`, {
            method: 'PUT',
            body: JSON.stringify(body),
        });
    }

    public async delete<T>(path: string, options?: RequestOptions): Promise<T> {
        return this.wrappedFetch(`${this.endpoint}${path}`, {
            method: 'DELETE',
        });
    }
}
