import { useState } from 'react';
import { TenantApi } from '../api/UsersApi';
import { RequestOptions } from '@apps/common-utilities/src/api/Api';
import ApiError from '@apps/common-utilities/src/api/ApiError';

export enum RequestMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH'
}

export interface RequestError {
    hasError?: boolean;
    error: string;
    message: string;
}

export interface RequestResponse<T> {
    data: T;
}
export interface ApiResponse<T> {
    response: RequestResponse<T>;
    error: RequestError;
}

export const useApiRequest = <T>(requestMethod: RequestMethod, options?: RequestOptions) => {
    const [done, setDone] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleResponse = (r: any) => {
        setLoading(false);
        setDone(true);
        const error: RequestError = { error: r && r.errors ? r.errors[0] : '', message: r && r.message ? r.message : '' };
        const response: RequestResponse<T> = { data: r };
        return { response, error };
    };

    const handleError = (e: any) => {
        setLoading(false);
        setDone(true);
        const error: RequestError = { hasError: true, error: e.errors ? e.errors[0] : '', message: e.message ? e.message : '' };
        const response: RequestResponse<T> = { data: e };
        return { response, error };
    };

    const callApi = async (url: string, body?: any): Promise<ApiResponse<T>> => {
        setLoading(true);
        setDone(false);

        switch (requestMethod) {
            case RequestMethod.GET:
                return TenantApi.get(url, options).then((r: any) => handleResponse(r)).catch((e: ApiError) => handleError(e));
            case RequestMethod.POST:
                return TenantApi.post(url, body, options).then((r: any) => handleResponse(r)).catch((e: ApiError) => handleError(e));
            case RequestMethod.PUT:
                return TenantApi.put(url, body, options).then((r: any) => handleResponse(r)).catch((e: ApiError) => handleError(e));
            case RequestMethod.DELETE:
                return TenantApi.delete(url, options).then((r: any) => handleResponse(r)).catch((e: ApiError) => handleError(e));
            case RequestMethod.PATCH:
                return TenantApi.patch(url, body, options).then((r: any) => handleResponse(r)).catch((e: ApiError) => handleError(e));
            default:
                setLoading(false);
                setDone(true);
                throw new Error('Invalid request method');
        }
    };

    return { callApi, done, loading };
};
