import { Modify } from './common';
import { ExerciseTypes } from '@apps/common-utilities';

export interface User {
  id: string;
  email: string;
  providerId?: number;
  onboardingComplete: boolean;
}

export enum SexAssignedAtBirth {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  INTERSEX = 'INTERSEX',
  NOT_DISCLOSED = 'NOT_DISCLOSED'
}

export enum MedicalCondition {
  ASTHMA = 'ASTHMA',
  COPD = 'COPD'
}

export enum AppointmentContactType {
  PhoneCall = 'Phone Call',
  Zoom = 'Healthie Video Call'
}

export interface TenantUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  weight: string;
  height: string;
  dateOfBirth: string;
  sexAssignedAtBirth: string;
  phoneNumber: string;
  medicalCondition: MedicalCondition;
  groupCode: string;
  coach?: Coach;
}

export type SimpleSignUpUser = Modify<TenantUser, {
  weight: null;
  height: null;
  dateOfBirth: null;
  sexAssignedAtBirth: null;
  phoneNumber: null;
  medicalCondition: null;
  groupCode: null;
}>;

export interface SignupUser extends TenantUser {
  password: string
}

export interface Provider {
  id: number;
  name: string,
  endpoint: string,
}

export interface Form {
  id: number;
  name: string;
  jotformId: string;
  embedUrl: string;
}

export interface CompletedForm {
  formId: string;
  formName: string;
}

export interface Appointment {
  id: string
  date: string;
  contactType: string;
  length: number;
  phoneNumber: string;
  coachName: string;
  appointmentTypeName: string
  joinUrl: string;
  zoomInfo?: {
    meetingId: string;
  }
  isZoom: boolean;
}

export interface Coach {
  userId: string;
  slackId: string;
}

export interface PostExerciseMetrics {
  heartRate: number | null;
  immediateSpO2Percent: number | null;
  lowestSpO2Percent: number | null;
  flowRate: number | null;
  breathlessnessLevel: number | null;
  assignedExerciseResults: ExerciseTypes.IAssignedExerciseResults[] | null;
  rmtDifficulty: number | null;
  rmtInhaleResistance: number | null;
  rmtExhaleResistance: number | null;
  comment: string | null;
}
