import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { Indexable } from '../types/common';

function useQuery(): Indexable<URLSearchParams> {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

export default useQuery;
