export enum Zone {
    GREEN = 'GREEN',
    YELLOW = 'YELLOW',
    RED = 'RED',
}

export enum InputType {
    MEDICATION = 'MEDICATION',
    ACTION = 'ACTION',
}

export interface IActionPlan {
    id: number;
    version: number;
    lang: string;
    copdInfo: string | null;
    actionPlanZones: IActionPlanZone[];
    createdAt: string;
    providerName: string;
    phoneNumber: string;
    emergencyContactName: string;
    emergencyContactPhone: string;
    userId: string;
    createdById: string;
    createdByName: string;
}

export interface IZoneItem {
    id: number;
    inputType: InputType;
    value: string;
}

export interface IActionPlanZone {
    id: number;
    zoneItems: IZoneItem[];
    zone: Zone;
    minFlowRate: number;
    maxFlowRate: number;
}
