export const Routes = {
    tenant: {
        user: '/users',
        forms: 'jotform/requested-forms'
    },
    support: {
        createUser: '/api/v2/users',
        addUserToOrganisation: '/api/v2/organization_memberships',
    },
    emailVerification: {
        verify: '/contact-verify',
    },
    healthie: {
        users: '/healthie/users'
    },
    customerSupport: {
        ticket: '/support/ticket'
    },
    events: {
        onboarding: '/events/onboarding'
    }
};
