var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from '../index.styles';
var SupportMessage = function (_a) {
    var toMessage = _a.toMessage, supportImage = _a.supportImage, messageText = _a.messageText;
    return (_jsxs(S.SupportCard, { children: [_jsxs(S.HeadingContainer, { children: [_jsx(S.SupportImg, { src: supportImage, alt: "Email" }), _jsx(S.SubHeader, { children: "Message" })] }), _jsx(S.SupportText, { children: messageText }), _jsx(S.EmailButton, __assign({ onClick: function () { return toMessage(); } }, { children: "Send us a Support Message" }))] }));
};
export default SupportMessage;
