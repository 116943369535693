import { Buttons } from '@apps/common-ui';
import React from 'react';
import { ReactComponent as NoData } from '../../../assets/NoData.svg';
import * as S from './index.styles';

type Props = {
    errorMessage?: string;
}

const NotFound = ({ errorMessage = '' }: Props) => (
    <S.NotFoundContainer>
        <NoData />
        <h1>{errorMessage.length ? errorMessage : 'Page not found'}</h1>
        <Buttons.FullWidthLinkButton to="/">Go to home</Buttons.FullWidthLinkButton>
    </S.NotFoundContainer>
);

export default NotFound;
