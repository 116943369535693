import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';
import { Page } from '../../../components/Page';

// might be able to combine these interfaces if additional properties aren't needed in the button props
interface ContinueButtonProps {
    answerSelected: boolean;
}

interface AnswerProps {
    isSelected: boolean
}

interface QuizAnswersProp {
    showQuizAnswers: boolean
}

export const Container = styled(Page)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: ${props => props.theme.screen.medium}) {
        padding-top: 0;
    }
`;

export const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 50px;
    background: ${props => props.theme.primary.medium};
`;

export const BackButton = styled(Buttons.Button)`
    color: ${props => props.theme.shades.white};
    background: ${props => props.theme.primary.medium};
    border: ${props => props.theme.primary.medium} 1px solid;
    margin-left: 30px;
    padding-left: 0px;
    svg {
        margin-left: 0px !important;
        color: ${props => props.theme.shades.white};
    }
`;

export const PageContent = styled.div<QuizAnswersProp>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 800px;
    max-height: 100%;
    padding: ${props => props.showQuizAnswers ? '30px' : '0'};
    background-color: ${props => props.showQuizAnswers ? props.theme.shades.white : 'none'};

    @media(max-width: ${props => props.theme.screen.medium}) {
        width: 100vw;
        padding-left: 20px;
        padding-right: 20px;
    }
`;

export const IndexContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: ${props => `1px solid ${props.theme.shades.lightGrey}`};
    padding-bottom: 20px;
`;

export const QuizIndexContainer = styled.div<QuizAnswersProp>`
    width: 100%;
    display: flex;
    justify-content: ${props => props.showQuizAnswers ? 'space-between' : 'flex-start'};
`;

export const ModuleLink = styled(Buttons.LinkButton)<QuizAnswersProp>`
    color: ${props => props.theme.links.blue};
    text-decoration: ${props => `underline solid ${props.theme.links.blue}`};
    background: ${props => props.showQuizAnswers ? props.theme.shades.white : props.theme.background};
    border: none;
    padding-left: 0px;
    padding-top: 0px;
    margin-left: 0px;
    margin-top: 30px;
`;

export const QuizLabel = styled.p`
    font-weight: bold;
    margin-top: 30px;
`;

export const DateLabel = styled(QuizLabel)`
    font-weight: 600;
    margin-top: 30px;
    color: ${props => props.theme.shades.green};
`;

export const QuestionContainer = styled.div`
    width: 100%;
`;

export const QuestionIndex = styled.p`
    margin-top: 30px;
    color: ${props => props.theme.primary.medium};
    margin-bottom: 20px;
`;

export const QuestionText = styled.p`
    margin-top: 0px;
    margin-bottom: 20px;
    font-weight: bold;

    * {
        font-family: inherit !important;
        margin-top: 0px;
        margin-bottom: 5px;
    }
`;

export const QuestionInstructions = styled.p`
    margin-top: 0px;
    margin-bottom: 30px;
`;

export const AnswerContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 40px;
    cursor: pointer;
`;

export const Answer = styled.div<AnswerProps>`
    width: 100%;
    background: ${props => props.isSelected ? props.theme.primary.medium : props.theme.shades.white};
    border: ${props => props.theme.primary.medium} 2px solid;
    border-radius: 6px;
    margin-bottom: 20px;
`;

export const AnswerText = styled.p<AnswerProps>`
    color: ${props => props.isSelected ? props.theme.text.white : props.theme.primary.medium};
    margin-left: 30px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 50px;
`;

export const ContinueButton = styled(Buttons.Button)<ContinueButtonProps>`
    background : ${props => props.answerSelected ? props.theme.buttons.blue : props.theme.buttons.grey};
    color: ${props => props.theme.text.white};
    border: ${props => props.answerSelected ? props.theme.buttons.blue : props.theme.buttons.grey} 1px solid;
    width: 140px;
    height: 50px;
    margin-right: 30px;
`;

export const PreviousButton = styled.p`
    color: ${props => props.theme.buttons.blue};
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;

export const QuizCompleteContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 30px;
    width: 100%;
`;

export const QuizCompleteText = styled.p`
    font-size: 18px;
    color: ${props => props.theme.primary.medium};
    font-weight: 600;
    margin-bottom: 30px;
    margin-top: 0px;
`;

export const ScoreContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: ${props => props.theme.shades.white};
    border: ${props => props.theme.shades.lighterGrey} 2px solid;
    border-radius: 16px;
    width: 275px;
    height: 150px;
    padding-left: 30px;
    margin-bottom: 30px;

    @media (max-width: ${props => props.theme.screen.medium}) {
        width: 100%;
    }
`;

export const ScoreTitle = styled.p`
    font-size: 20px;
    color: ${props => props.theme.primary.medium};
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
`;

export const NumericScore = styled(ScoreTitle)`
    font-weight: normal;
    margin-left: 15px;
`;

export const Score = styled.h1`
    margin-top: 0px;
    color: ${props => props.theme.primary.medium};
    font-size: 52px;
    margin-bottom: 20px;
`;

export const CompleteButton = styled(Buttons.Button)`
    background: ${props => props.theme.buttons.blue};
    color: ${props => props.theme.text.white};
    width: 245px;
    height: 55px;

    @media (max-width: ${props => props.theme.screen.medium}) {
        width: 100%;
        margin-left: 0;
    }
`;

export const CompleteButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: ${props => props.theme.screen.medium}) {
        justify-content: start;
        flex-direction: column;
    }
`;

export const ViewAnswerButton = styled(Buttons.Button)`
    font-size: 18px;
    margin-left: 40px;

    @media (max-width: ${props => props.theme.screen.medium}) {
        width: 100%;
        margin-left: 0;
        margin-top: 30px;
    }
`;
