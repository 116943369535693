import { Spinner } from '@apps/common-ui';
import { useEffect } from 'react';
import useRedirectExternally from '../../hooks/useRedirectExternally';

const HealthieRedirect = () => {
    const redirect = useRedirectExternally();

    useEffect(() => {
        redirect('https://secure.gethealthie.com/users/sign_in');
    }, []);

    return <div><Spinner /></div>;
};

export default HealthieRedirect;
