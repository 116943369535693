import { Buttons, Card } from '@apps/common-ui';
import styled from 'styled-components';

interface ButtonProps {
    width?: string;
}

interface CardProps {
    isAppointment?: boolean;
}

export const HomeCard = styled(Card)<CardProps>`
    display: flex;
    flex-direction: row;
    height: ${(props) => props.isAppointment ? '86px' : '100px'};
    width: 680px;
    border: 2px solid ${props => props.theme.shades.lighterGrey};
    border-radius: 11px;
    padding: 0;
    margin-bottom: 25px;

    @media (max-width: ${props => props.theme.screen.smallTablet}) {
        width: 100%;
    }
`;

export const HomeCardImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 100%;
    background-color: ${props => props.theme.shades.lighterGrey};
    overflow: hidden;
    border-radius: 9px 0 0 9px;

    @media (max-width: ${props => props.theme.screen.smallTablet}) {
        min-width: 70px;
        width: 10%;
    }

    @media (max-width: ${props => props.theme.screen.small}) {
        width: 150px;
    }
`;

export const HomeCardImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const HomeCardBody = styled.div`
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    width: 610px;
`;

export const HomeCardTitlesContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const HomeCardTitle = styled.h4`
    margin: 0;
`;

export const HomeCardSubtitle = styled.p`
    margin: 0;
`;

export const HomeCardButton = styled(Buttons.Button)<ButtonProps>`
    font-weight: bold;
    width: ${(props) => props.width ? props.width : '100px'};
    height: 50px;
    white-space: nowrap;
`;

export const HomeCardCompleteIcon = styled.img`
    width: 30px;
    margin: 5px;
`;
