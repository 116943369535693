import mixpanel, { Dict } from 'mixpanel-browser';

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || ' ';

class Mixpanel {
    constructor() {
        this.initialize();
    }

    initialize(): void {
        mixpanel.init(MIXPANEL_TOKEN);
    }

    identify(id: string): void {
        mixpanel.identify(id);
        mixpanel.people.set(
            {
                'User ID': id
            }
        );
    }

    track(name: string, properties?: Dict): void {
        // return if environment is dev else will use token for staging and prod respectively
        if (process.env.REACT_APP_ENVIRONMENT === 'development' || process.env.REACT_APP_ENVIRONMENT === 'staging') {
            return;
        }
        mixpanel.track(name, properties);
    }
}

export default new Mixpanel();
