import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

interface Props {
    isBold?: boolean;
}

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 30px;
    padding-top:0;
`;

export const ZoomTestIcon = styled.img`
    width: 90px;
`;

export const ModalText = styled.p<Props>`
    text-align: center;
    font-size: 18px;
    font-weight: ${props => props.isBold ? '600' : 'normal'};
`;

export const ModalButton = styled(Buttons.Button)`
    width: 100%;
    text-align: center;
    margin-top: 20px;
`;

export const ModalCloseButton = styled(Buttons.Button)`
    display: flex;
    align-self: flex-end;
`;
