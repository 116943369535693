import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';
import { Page } from '../../../../components/Page';

interface SectionProps {
    noBorder?: boolean
}

interface TitleProps {
    blue?: boolean
}

interface VideoProps {
    isPortrait: boolean;
}

export const Container = styled(Page)`
    display: flex;
    flex-direction: column;

    @media (max-width: ${props => props.theme.screen.medium}) {
        padding-top: 0;
    }
`;

export const PageContent = styled.div<VideoProps>`
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.isPortrait ? 'flex-start' : 'space-between'};
    align-items: flex-start;
    width: 100%;
    @media (max-width: ${props => props.theme.screen.small}) {
        flex-direction: ${props => props.isPortrait ? 'column-reverse' : 'column'};
        justify-content: center;
    }
`;

export const TopBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    background: ${props => props.theme.primary.medium};
    padding-left: 50px;
`;

export const BackButton = styled(Buttons.Button)`
    color: ${props => props.theme.shades.white};
    background: ${props => props.theme.primary.medium};
    border: ${props => props.theme.primary.medium} 1px solid;
    margin-left: 0px;
    padding-left: 0px;
    svg {
        margin-left: 0px !important;
        color: ${props => props.theme.shades.white};
    }
`;

export const ExerciseDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    max-width: 400px;
    margin-left: 50px;

    @media (max-width: ${props => props.theme.screen.small}) {
        margin-left: 20px;
        width: 300px;
        order: 2;
    }
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    @media (max-width: ${props => props.theme.screen.small}) {
        padding-top: 10px;
    }
`;

export const ExerciseIndex = styled.p`
    color: ${props => props.theme.text.blue};
    font-weight: bold;
    margin-top: 40px;
`;

export const ExerciseTitle = styled.h2`
    margin-bottom: 10px;
`;

export const ExerciseDescription = styled.p``;

export const Section = styled.div<SectionProps>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border-bottom: ${props => props.noBorder ? 'none' : `1px solid ${props.theme.shades.lightGrey}`};
    margin-bottom: 15px;
`;
export const SectionRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 5px;
`;

export const SectionTitle = styled.p<TitleProps>`
    font-weight: bold;
    color: ${props => props.blue ? props.theme.text.blue : props.theme.text.black};
    margin-top: 0px;
    margin-bottom: 10px;
`;

export const SectionText = styled.p`
    margin-top: 0px;
    margin-bottom: 5px;
`;

export const SectionTextDiv = styled.div`
    * {
        margin-top: 0px;
        margin-bottom: 5px;
        font-family: inherit !important;
    }
`;

export const LabelColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

export const AmountContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;

    @media (max-width: ${props => props.theme.screen.medium}) {
        flex-wrap: wrap;
    }
`;

export const AmountLabel = styled.p`
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 5px;
`;
export const UnitLabel = styled.p`
    margin-top: 0px;
    margin-bottom: 5px;
`;

export const HoldSecondsLabel = styled.p`
    margin-top: 0px;
`;

export const InputContainer = styled.div<VideoProps>`
    @media (max-width: ${props => props.theme.screen.small}) {
        width: ${props => props.isPortrait ? '80%' : '100%'}};
        padding-left: ${props => props.isPortrait ? '0px' : '20px'}}};
        padding-right: 20px;
    }
`;

export const DoneExerciseLabel = styled.p`
    font-weight: bold;
`;

export const InputButton = styled(Buttons.Button)`
    margin-left: 0px;
    margin-top: 30px;

    @media (max-width: ${props => props.theme.screen.small}) {
        width: 100%;
    }
`;

export const VideoColumn = styled.div<VideoProps>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: ${props => props.isPortrait ? '320px' : '900px'};
    height: ${props => props.isPortrait ? '570px' : '500px'};
    margin-left: 50px;

    @media (max-width: ${props => props.theme.screen.small}) {
        max-width: 100vw;
        max-height: 300px;
        margin-left: ${props => props.isPortrait ? '20px' : '0px'}};
        order: 1;
    }
`;

export const VideoContainer = styled.div<VideoProps>`
    position: relative;
    overflow: hidden;
    padding-bottom: ${props => props.isPortrait ? '177.78%' : '56.25%'};
    height: 0;

    iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
`;
