import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    padding: 10px;
    margin-top: 10px;
    width: 100%;

    @media (max-width: ${props => props.theme.screen.medium}) {
        border-bottom: 1px solid ${props => props.theme.shades.lightGrey};
    }
`;

export const HeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
`;

export const Title = styled.h3`
    padding: 3px;
    margin: 0;
`;

export const ButtonLink = styled(Buttons.LinkButton)`
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 12px;
    padding: 5px 8px;
    margin: 3px 0 0 0;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    margin-top: 10px;
    gap: 10px;
    
    @media (max-width: ${props => props.theme.screen.medium}) {
        flex-direction: column;
        align-items: center;
    }
`;

export const Image = styled.img`
    padding: 5px;
    height: 100px;
`;

export const ImageWithOverlayLabel = styled.img`
    padding: 5px;
    padding-right: 50px;
    height: 200px;
`;

export const StepContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    padding: 5px;

    @media (max-width: ${props => props.theme.screen.medium}) {
        width: 100%;
    }
`;

export const SubTitle = styled.h4`
    padding: 3px;
    margin: 0;
`;

export const TextDetail = styled.p`
    padding: 3px;
    margin: 0;
`;
