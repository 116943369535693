import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import useQuery from './useQueryParams';

export const useLoginRedirect = () => {
    const query = useQuery();
    const navigate = useNavigate();
    const [shouldRedirect, setShouldRedirect] = useState(false);

    useEffect(() => {
        if (query.get('next')) {
            setShouldRedirect(true);
        }
    }, [query]);

    const handleRedirect = useCallback(async () => {
        const next = query.get('next');
        if (next) {
            window.location.href = next;
        } else {
            navigate('/');
        }
    }, [query]);

    return { handleRedirect, shouldRedirect };
};
