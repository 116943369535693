import { Buttons, Inputs } from '@apps/common-ui';
import styled from 'styled-components';

export const ModalContent = styled.div`
    padding: 10px 20px;
    
    @media (max-width: ${props => props.theme.screen.medium}) {
        min-width: 85vw;
        max-width: 100vw;
    }
`;

export const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media (max-width: ${props => props.theme.screen.medium}) {
        flex-direction: column-reverse;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Title = styled.h3`
    margin-top: 10px;
    @media (max-width: ${props => props.theme.screen.medium}) {
        margin: 0px;
        text-align: center;
    }
`;

export const CloseButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    
    @media (max-width: ${props => props.theme.screen.medium}) {
        width: 100%;
        margin-right: 30px;
    }
`;

export const CloseButton = styled(Buttons.Button)`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: ${props => props.theme.shades.white};
    border-color: ${props => props.theme.shades.white};
    margin-top: 0px;
    margin-right: 0px;
    padding-right: 0px;

    p {
        margin-top: 0px;
        margin-bottom: 5px;
        color: ${props => props.theme.primary.medium}
    }

    svg {
        margin-top: 6px;
        color: ${props => props.theme.primary.medium}
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin: 15px 0px;

    @media (max-width: ${props => props.theme.screen.medium}) {
        flex-direction: column;
    }
`;

export const NoButton = styled(Buttons.Button)`
    color: ${props => props.theme.buttons.blue};
    background: ${props => props.theme.shades.white};
`;

export const YesButton = styled(Buttons.Button)`

`;
