import { theme } from '@apps/common-ui';
import React, { useCallback, useEffect, useRef, useState } from 'react';

type Props = {
    src: string;
    onCompleteForm: () => void
}

const JotformEmbed = ({ src, onCompleteForm }: Props) => {
    const ref = useRef<HTMLIFrameElement>(null);
    const [loaded, setLoaded] = useState(false);

    // Handle the messages that the iframe sends to the parent
    // that let's us control stuff like the height of the iframe.
    // Copied from the jotform embed snippet and modified to work with React
    const handleIframeMessage = useCallback((e) => {
        if (!e.data.split) {
            return;
        }
        const args = e.data.split(':');
        const formId = args[2];
        const iframe = ref.current;
        if (!!iframe && (!formId || src.split('?')[0].endsWith(formId))) {
            switch (args[0]) {
                case 'scrollIntoView':
                    iframe.scrollIntoView();
                    break;
                case 'setHeight':
                    iframe.style.height = `${args[1]}px`;
                    break;
                case 'collapseErrorPage':
                    if (iframe.clientHeight > window.innerHeight) {
                        iframe.style.height = `${window.innerHeight}px`;
                    }
                    break;
                case 'reloadPage':
                    window.location.reload();
                    break;
                case 'exitFullscreen':
                    // Jotform sends an exitFullscreen message when the form is submitted
                    // this allows us to detect when a form is submitted so we can remove it from
                    // the state
                    onCompleteForm();
                    break;
                default:
                    break;
            }
            const isJotForm = (e.origin.indexOf('jotform') > -1);
            if (iframe.contentWindow && isJotForm
                && 'contentWindow' in iframe && 'postMessage' in iframe.contentWindow) {
                const urls = {
                    docurl: encodeURIComponent(window.document.URL),
                    referrer: encodeURIComponent(window.document.referrer),
                };
                iframe.contentWindow.postMessage(JSON.stringify({ type: 'urls', value: urls }), '*');
            }
        }
    }, [ref.current]);

    const handleSubmissionCompleted = (event: MessageEvent) => {
        if (event.data.action && event.data.action === 'submission-completed') {
            onCompleteForm();
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleIframeMessage, false);
        window.addEventListener('message', handleSubmissionCompleted);
        return () => {
            window.removeEventListener('message', handleIframeMessage, false);
            window.removeEventListener('message', handleSubmissionCompleted);
        };
    }, []);

    return (
        <iframe
          ref={ref}
          title="Form"
          style={{
            width: window.innerWidth < parseInt(theme.screen.small.slice(0, 3), 10) ? '100vw' : 'calc(100vw - 250px)',
            minHeight: window.innerWidth < parseInt(theme.screen.small.slice(0, 3), 10) ? 'calc(100vh - 60px)' : '100vh',
            display: loaded ? 'block' : 'none',
            border: 'none',
            paddingTop: '70px',
            paddingBottom: '70px'
          }}
          src={src}
          onLoad={() => setLoaded(true)}
          scrolling="no"
        />
    );
};

export default JotformEmbed;
