import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { SidebarNavLink } from '.';

export const SidebarContainer = styled.div<{
    isSidebarOpen: boolean
}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 240px;
    min-height: 100vh;
    top: 0;
    left: 0;
    background-color: ${props => props.theme.primary.medium};
    box-sizing: border-box;
    color: ${props => props.theme.text.white};
    svg {
        margin-bottom: 15px;
    }

    @media (max-width: ${props => props.theme.screen.medium}) {
        z-index: 4;
        position: fixed;
        min-height: ${props => props.isSidebarOpen ? '100%' : '60px'};
        width: 100%;
        transition: 0.3s ease-in-out;
        overflow: hidden;
    }
`;

export const SidebarLinks = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 30px 0 0 0;
    overflow-y: auto;
    overflow-x: hidden;

    button {
        margin-left: 20px;
        max-width: 80%;
    }

    svg {
        align-self: center;
    }

    @media (max-width: ${props => props.theme.screen.medium}) {
        svg {
            position: absolute;
            bottom: 15px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
`;

export const SidebarLink = styled(NavLink)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${props => props.theme.text.white};
    margin-bottom: 10px;
    text-decoration: none;
    border-left: 5px solid transparent;
    box-shadow: none;
    cursor: pointer;a
    text-align: left;
    padding: 5px 0 5px 20px;

    &.active, :hover {
        font-weight: bold;
        border-left: 5px solid ${props => props.theme.buttons.blue};
        background: rgba(255, 255, 255, 0.1);
    }


    button {
        margin-left: 0;
    }

    svg {
        margin: 0 10px 0 0;
    }

    @media (max-width: ${props => props.theme.screen.medium}) {
        svg {
            position: static;
        }
    }
`;

export const SidebarDivider = styled.hr`
    border-top: 1px solid ${props => props.theme.primary.light};
    margin-bottom: 15px;
    border-bottom: none;
    margin: 5px 25px;
`;

export const SidebarSubHeading = styled.p`
    color: ${props => props.theme.text.lightBlue} !important;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 15px;
    cursor: default;
    padding-left: 25px;
`;

export const SidebarExternalLinkContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    a {
        width: 100%;
    }

    svg {
        margin-left: 20px;
    }

    @media (max-width: ${props => props.theme.screen.medium}) {
        svg {
            display: block;
            position: static;
        }
    }
`;

export const SidebarHamburgerContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;

    svg, p {
        margin: 20px 0 0 20px;
        color: ${props => props.theme.text.white};
        font-size: 16px;
    }
`;

export const SidebarPrivacyLinks = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 12px;
    margin-bottom: 10px;

    a {
        margin: 0 0 10px 10px;
        color: ${props => props.theme.shades.grey};
        &.active {
            background: none;
            font-weight: normal;
            border-left: 5px solid transparent;
        }
    }

    @media (max-width: ${props => props.theme.screen.medium}) {
        justify-content: flex-start;
    }
`;

export const SidebarLinksContainer = styled.div`
    display: flex;
    flexDirection: column;
    justifyContent: space-between;
`;

export const Badge = styled.span`
    background: ${props => props.theme.buttons.blue};
    color: ${props => props.theme.text.white};
    border-radius: 4px;
    padding: 2px 8px;
    font-size: 12px;
    margin-left: 10px;
    font-weight: bold;
`;

export const SidebarLogo = styled.div`
    padding-left: 40px;
    transform: scale(1.3);
`;

export const Icon = styled.img`
    margin-left: 10px;
`;