export var CourseUserPauseReason;
(function (CourseUserPauseReason) {
    CourseUserPauseReason["NON_PAYMENT"] = "NON_PAYMENT";
    CourseUserPauseReason["ILLNESS"] = "ILLNESS";
})(CourseUserPauseReason || (CourseUserPauseReason = {}));
export var SubModuleType;
(function (SubModuleType) {
    SubModuleType["LESSON"] = "LESSON";
    SubModuleType["QUIZ"] = "QUIZ";
})(SubModuleType || (SubModuleType = {}));
export var LessonCompletionType;
(function (LessonCompletionType) {
    LessonCompletionType["READ"] = "READ";
    LessonCompletionType["LISTENED"] = "LISTENED";
})(LessonCompletionType || (LessonCompletionType = {}));
