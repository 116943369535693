var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import * as S from './index.styles';
var Checkbox = function (props) {
    var defaultChecked = props.defaultChecked, onChange = props.onChange, disabled = props.disabled, activeText = props.activeText, inactiveText = props.inactiveText, id = props.id, boldActiveText = props.boldActiveText, boldInactiveText = props.boldInactiveText;
    var _a = useState(defaultChecked), isChecked = _a[0], setIsChecked = _a[1];
    var toggle = function () {
        if (disabled) {
            return;
        }
        setIsChecked(!isChecked);
        onChange(!isChecked);
    };
    return (_jsxs(S.Container, { children: [_jsx(S.CheckboxInput, { disabled: disabled, onClick: toggle, id: id, active: isChecked, defaultChecked: defaultChecked }), _jsxs(S.CheckboxLabel, __assign({ disabled: disabled, htmlFor: id }, { children: [isChecked && _jsxs(_Fragment, { children: [_jsx(FontAwesomeIcon, { icon: faCheck }), boldActiveText ? _jsx("b", { children: activeText }) : activeText] }), !isChecked && (boldInactiveText ? _jsx("b", { children: inactiveText }) : inactiveText)] }))] }));
};
export default Checkbox;
