import React from 'react';
import * as S from './index.styles';

type Props = {
    imgSrc?: string;
    title: string;
}

const PageHeader = ({ imgSrc, title }: Props) => (
    <S.TitleContainer>
        {imgSrc && (
            <S.TitleImageContainer>
                <S.TitleImage src={imgSrc} />
            </S.TitleImageContainer>
        )}
        <S.TitleTextContainer>
            <S.Title>{title}</S.Title>
        </S.TitleTextContainer>
    </S.TitleContainer>
);

export default PageHeader;
