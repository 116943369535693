import React from 'react';
import * as S from '../index.styles';
import { Modal } from '@apps/common-ui';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
}

const OnboardingCompleteModal = ({ isOpen, closeModal }:Props) => (
    <Modal
      showModal={isOpen}
      dismissModal={closeModal}
      minWidth="25%"
      maxWidth="500px"
      minHeight="20%"
      maxHeight="100vh"
      showTopBar={false}
      closeOnOutsideClick={false}
    >
        <S.ModalContent>
            <S.OnboardingCompleteIcon modalWith src="/CompleteIcon.svg" />
            <S.ModalText boldFont>Onboarding Complete</S.ModalText>
            <S.ModalText>Lets explore the BreatheSuite platform</S.ModalText>
            <S.ModalButton
              onClick={() => closeModal()}
            >Continue
            </S.ModalButton>
        </S.ModalContent>
    </Modal>
);

export default OnboardingCompleteModal;
