import styled from 'styled-components';
import { Page } from '../../../components/Page';

export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media(max-width: ${props => props.theme.screen.medium}) {
        flex-wrap: wrap;
    }
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 100px;
`;

export const CourseTitle = styled.h2`
    margin: 0;
`;

export const Container = styled(Page)`
    max-width: 600px;
`;
