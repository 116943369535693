import React from 'react';
import * as S from './index.styles';
import { LearningTypes } from '@apps/common-utilities';
import Mixpanel from '../../../../services/Mixpanel';
import { useNavigate, useParams } from 'react-router';

type Props = {
    quizAnswers: LearningTypes.IQuizSubmission | null;
}

const QuizAnswers = ({ quizAnswers }: Props) => {
    const navigate = useNavigate();
    const { moduleId } = useParams<{moduleId: string}>();

    return (
        <S.Container>
            {quizAnswers?.responses.map((submission, index) => {
            const isCorrect = submission.answers.every(answer => answer.correct === answer.selected);
            const selectedAnswers = submission.answers.filter(answer => answer.selected);
            const correctAnswers = submission.answers.filter(answer => answer.correct);
                return (
                    <S.AnswerRow key={submission.question}>
                        <S.Counter>{index + 1}</S.Counter>
                        <S.Question dangerouslySetInnerHTML={{ __html: submission.question }} />
                        <S.Answer>
                            Your Answer{selectedAnswers.length > 1 ? 's' : ''}
                            {!isCorrect && ' (Incorrect)'}:
                        </S.Answer>
                        {submission.answers
                        .filter(answer => answer.selected)
                        .map(
                            answer => (
                                <S.AnswerCard
                                  key={answer.answer}
                                  correct={answer.correct}
                                  selected={answer.selected}
                                >
                                    {answer.answer}
                                </S.AnswerCard>
                                )
                                )}
                        {!isCorrect && (
                        <>
                            <S.Answer>
                                Correct Answer{correctAnswers.length > 1 ? 's were' : ' was'}:
                            </S.Answer>
                            {submission.answers
                            .filter(answer => answer.correct)
                            .map(answer => (
                                <S.AnswerCard
                                  key={answer.answer}
                                  correct={answer.correct}
                                  selected={false}
                                >
                                    {answer.answer}
                                </S.AnswerCard>
                            ))}
                        </>
                        )}
                    </S.AnswerRow>
                );
        })}
            <S.CompleteButton
              onClick={() => {
            Mixpanel.track('web_quiz_page_complete_button');
            navigate(`/learning/modules/${moduleId}`);
        }}
            >
                Continue
            </S.CompleteButton>
        </S.Container>
    );
};

export default QuizAnswers;
