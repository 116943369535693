var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var NoDataPageTitleContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    margin-bottom: 30px;\n"], ["\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    margin-bottom: 30px;\n"])));
export var NoDataPageTitle = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    font-weight: 600;\n    margin: 0;\n"], ["\n    font-weight: 600;\n    margin: 0;\n"])));
export var NoDataContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 450px;\n    background: ", ";\n    padding: 25px;\n    border-radius: 9px;\n    border: 2px solid ", ";\n\n    @media(max-width: ", ") {\n        max-width: 80vw;\n    }\n"], ["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 450px;\n    background: ", ";\n    padding: 25px;\n    border-radius: 9px;\n    border: 2px solid ", ";\n\n    @media(max-width: ", ") {\n        max-width: 80vw;\n    }\n"])), function (props) { return props.theme.shades.white; }, function (props) { return props.theme.shades.lighterGrey; }, function (props) { return props.theme.screen.medium; });
export var NoDataTitleRow = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: center;\n    width: 100%;\n    margin-bottom: 25px;\n"], ["\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: center;\n    width: 100%;\n    margin-bottom: 25px;\n"])));
export var NoDataTitleImage = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    object-fit: cover;\n    width: 70px;\n    height: 70px;\n"], ["\n    object-fit: cover;\n    width: 70px;\n    height: 70px;\n"])));
export var NoDataTitle = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-weight: 500;\n    padding: 0 0 0 30px;\n"], ["\n    font-weight: 500;\n    padding: 0 0 0 30px;\n"])));
export var NoDataMessage = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    font-weight: 700;\n    margin: 0;\n"], ["\n    font-weight: 700;\n    margin: 0;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
