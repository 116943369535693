import { Buttons } from '@apps/common-ui';
import styled from 'styled-components';

export const PageContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 100px;
`;

export const BackButton = styled(Buttons.LinkButton)`
    margin-left: 0px;
    padding-left: 0px;
    color: ${props => props.theme.text.blue};
    
    svg {
        margin-left: 0px !important;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 40px;
    margin-top: 40px;
`;

export const TitleRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

export const TitleImage = styled.img`
    width: 82px;
    height: 82px;
    object-fit: contain;
    border-radius: 7px;
    margin-right: 20px;
`;

export const Subtitle = styled.span`
    font-size: 14px;
`;

export const Title = styled.h3`
    margin: 0px;
    margin-bottom: 15px;
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
`;
