import React, { useState, useEffect } from 'react';
import { Inputs } from '@apps/common-ui';
import { formatDateWithWeekday, formatDateNoWeekday } from '../../utils';

interface DateInfo {
  text: string;
  value: string;
}

interface DateMap {
  [key: string]: {
    formatted: string;
    noWeekday: string;
  };
}

interface Props {
    startDate: Date;
    onChange: (value: string) => void;
}

const generateDateInfoList = (start: Date): DateInfo[] => {
    const today: Date = new Date();
    const end: Date = today;
    const dateList: DateInfo[] = [];
    const currentDate = new Date(start);

    while (currentDate <= end) {
        const daysAgo = Math.floor((today.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));
        let daysAgoText: string;

        if (daysAgo === 0) {
            daysAgoText = 'today';
        } else if (daysAgo === 1) {
            daysAgoText = '1 day ago';
        } else {
            daysAgoText = `${daysAgo} days ago`;
        }

        const formattedDateWithWeekday = formatDateWithWeekday(currentDate);

        const dateString = `${formattedDateWithWeekday} (${daysAgoText})`;

        dateList.push({
            text: dateString,
            value: currentDate.toISOString().split('T')[0],
        });

        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateList;
};

const generateDateMap = (start: Date): DateMap => {
    const today: Date = new Date();
    const end: Date = today;
    const newDateMap: DateMap = {};
    const currentDate = new Date(start);

    while (currentDate <= end) {
        const formattedDateWithWeekday = formatDateWithWeekday(currentDate);
        const formattedDateNoWeekday = formatDateNoWeekday(currentDate);

        newDateMap[currentDate.toISOString().split('T')[0]] = {
            formatted: formattedDateWithWeekday,
            noWeekday: formattedDateNoWeekday,
        };

        currentDate.setDate(currentDate.getDate() + 1);
    }

    return newDateMap;
};

const CompletionDatePicker = ({ startDate, onChange } : Props) => {
    const [currentValue, setCurrentValue] = useState<string>('');
    const [selectedDate, setSelectedDate] = useState<string>('');
    const [selectedDateNoWeekday, setSelectedDateNoWeekday] = useState<string>('');

    const dates: DateInfo[] = generateDateInfoList(startDate);
    const dateMap: DateMap = generateDateMap(startDate);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setCurrentValue(selectedValue);
        setSelectedDate(dateMap[selectedValue].formatted);
        setSelectedDateNoWeekday(dateMap[selectedValue].noWeekday);
        onChange(selectedValue);
    };

    return (
        <div>
            <Inputs.Select value={currentValue} onChange={handleChange}>
                {dates.map(date => (
                    <option key={date.value} value={date.value}>
                        {date.text}
                    </option>
                ))}
            </Inputs.Select>
        </div>
    );
};

export default CompletionDatePicker;
