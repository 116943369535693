import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export enum FbEvents {
    INTEREST = 'Interest',
    QUALIFIED = 'Qualified'
}

/**
 * Track page visits through Google Analytics
 */
const usePageAnalytics = () => {
    const location = useLocation();

    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT === 'production') {
            // TS doesn't know about the tracking functions defined in the index.html. Just ignore
            // @ts-ignore
            window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
                page_path: location.pathname,
            });
            // @ts-ignore
            window.fbq('track', 'PageView');
        }
    }, [location]);
};

export const fbTrackEvent = (event: FbEvents) => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        // @ts-ignore
        window.fbq('track', event);
    }
};

export default usePageAnalytics;
