import styled from 'styled-components';

export const Container = styled.fieldset`
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  input:checked + label:before {
    background-color: ${props => props.theme.buttons.blue};
  }
`;

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })<{active: boolean, disabled?: boolean}>`
  display: none;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
`;

export const CheckboxLabel = styled.label<{disabled?: boolean}>`
  user-select: none;
  width: 100%;
  position: relative;
  margin: 0;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

  &:before {
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
    content: "";
    width: 18px;
    height: 18px;
    background-color: transparent;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    border: 1px solid ${props => props.theme.shades.lightGrey};
  }

  svg {
    position: absolute;
    top: 6px;
    left: 2px;
    color: ${props => props.theme.shades.lightestGrey};
  }
`;
