import { LearningTypes } from '@apps/common-utilities';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Mixpanel from '../../../services/Mixpanel';
import { RootState } from '../../../state/store';
import * as S from './index.styles';
import ModuleCard from './ModuleCard';
import PageHeader from '../../../components/common/PageHeader/insex';
import { Page } from '../../../components/Page';
import { NoDataMessage } from '@apps/common-ui';

const ModulesList = () => {
    const { course } = useSelector((state: RootState) => state.coaching);
    const [currentSubmoduleString, setCurrentSubmoduleString] = useState('');
    const modules = course?.modules;

    const getCurrentSubmoduleString = () => {
        if (!course || !course.currentSubModule) {
            return;
        }

        if (course.currentSubModule.subModuleType === LearningTypes.SubModuleType.LESSON) {
            setCurrentSubmoduleString(`Lesson ${course.currentSubModule.subModuleTypePosition}`);
        } else if (course.currentSubModule.subModuleType === LearningTypes.SubModuleType.QUIZ) {
            setCurrentSubmoduleString(`Quiz ${course.currentSubModule.subModuleTypePosition}`);
        }
    };

    const navigate = useNavigate();

    const navigateToCurrentSubmodule = () => {
        if (!course) {
            return;
        }
        // if no current submodule, then navigate to modules page, else navigate to current lesson or quiz
        if (course.currentSubModule.subModuleType === LearningTypes.SubModuleType.LESSON) {
            Mixpanel.track('web_view_lesson_button');
            navigate(`/modal/module/${course.currentSubModule.moduleId}/lesson/${course.currentSubModule.id}/${false}`);
        } else if (course.currentSubModule.subModuleType === LearningTypes.SubModuleType.QUIZ) {
            Mixpanel.track('web_view_quiz_button');
            navigate(`/modal/module/${course.currentSubModule.moduleId}/quiz/${course.currentSubModule.id}/${false}/${-1}`);
        }
    };

    const viewModule = (moduleId: number) => {
        Mixpanel.track('web_module_page_view_module_button');
        navigate(`/learning/modules/${moduleId}`);
    };

    useEffect(() => {
        getCurrentSubmoduleString();
    }, [course]);

    if (!modules) {
        return (
            <S.Container>
                <NoDataMessage
                  pageTitle="Education"
                  imgUrl="/NoCourse.svg"
                  messageTitle="No education plan assigned"
                  messageBody="Your education lessons will appear here after meeting with your Respiratory Therapist."
                />
            </S.Container>
        );
    }

    return (
        <Page>
            <S.ListContainer>
                <PageHeader imgSrc="/EducationTitle.png" title="Education" />
                <S.CardContainer>
                    <div>
                        <span>Your in-progress course:</span>
                        <S.CourseTitle>{course.title}</S.CourseTitle>
                    </div>
                    {modules.map((module: LearningTypes.IModuleSummary, index: number) => (
                        <ModuleCard
                          key={module.id}
                          {...module}
                          onButtonPress={() => {
                            if ((!!course && !course.currentSubModule) || (module.id && module.id !== course.currentSubModule.moduleId)) {
                                viewModule(module.id);
                            } else if (module.id === course.currentSubModule.moduleId) {
                                navigateToCurrentSubmodule();
                            }
                          }}
                          viewModule={() => viewModule(module.id)}
                          moduleTitle={module.title}
                          subModuleTitle={course.currentSubModule ? course.currentSubModule.title : ''}
                          isCurrent={!!course.currentSubModule && module.id === course.currentSubModule.moduleId}
                          currentSubmoduleString={currentSubmoduleString}
                        />
                    ))}
                </S.CardContainer>
            </S.ListContainer>
        </Page>
    );
};

export default ModulesList;
