import React, { useEffect, useState } from 'react';
import * as S from '../index.styles';
import { Slider } from '@apps/common-ui';

interface Props {
  value: number;
  width: string;
  onChange: Function;
}

enum SliderColors {
  blue = 'lightblue',
  green = 'green',
  darkGreen = 'darkgreen',
  orange = 'orange',
  red = 'red'
}

const RMTDifficultySlider = ({ value, width, onChange }: Props) => {
    const sliderValueStrings = ['Not difficult at all', 'Slightly noticeable', 'More difficult', 'Very challenging', 'Too Hard'];
    const [emojiPath, setEmojiPath] = useState('');
    const [valueString, setValueString] = useState('');
    const [sliderColor, setSliderColor] = useState<string>(SliderColors.blue);

    // Get the severity color based on the slider value
    const getColor = (sliderVal: number) => {
        let color = SliderColors.blue;
        for (let i = 0; i < sliderValueStrings.length; i += 1) {
            if (Math.floor(sliderVal) === i) {
                color = Object.values(SliderColors)[i];
            }
        }
        return color;
    };

    // Get the severity label based on the slider value
    const getSeverityLabel = (sliderVal: number) => {
        let label = sliderValueStrings[0];
        for (let i = 0; i < sliderValueStrings.length; i += 1) {
            if (Math.floor(sliderVal) === i) {
                label = sliderValueStrings[i];
            }
        }
        return label;
    };

    const getEmojiString = (sliderVal: number) => {
        if (sliderVal === 4) {
            return '/breathlessness/MaximalIcon.png';
        } else if (sliderVal === 3) {
            return '/breathlessness/SevereIcon.png';
        } else if (sliderVal === 2) {
            return '/breathlessness/ModerateIcon.png';
        } else if (sliderVal === 1) {
            return '/breathlessness/SlightIcon.png';
        } else if (sliderVal === 0) {
            return '/breathlessness/NothingIcon.png';
        }
        return '';
    };

    useEffect(() => {
        setEmojiPath(getEmojiString(value));
        setValueString(getSeverityLabel(value));
        setSliderColor(getColor(value));
    }, [value]);

    return (
        <S.SliderRow>
            <div>
                <S.LevelLabel>Level of Difficulty</S.LevelLabel>
                <S.LevelLabel>{value + 1} / 5</S.LevelLabel>
            </div>
            <S.SliderContainer>
                <Slider
                  value={value}
                  min={0}
                  max={4}
                  step={1}
                  maxWidth={width}
                  onChange={onChange}
                  sliderColor={sliderColor}
                />
                <S.SliderLabelContainer>
                    <S.SliderLabel>1</S.SliderLabel>
                    <S.SliderLabel>5</S.SliderLabel>
                </S.SliderLabelContainer>
            </S.SliderContainer>
            <S.SeverityContainer>
                <S.EmojiContainer>
                    <S.Emoji src={emojiPath} />
                </S.EmojiContainer>
                <S.SeverityText>
                    {valueString}
                </S.SeverityText>
            </S.SeverityContainer>
        </S.SliderRow>
    );
};

export default RMTDifficultySlider;
