import { Buttons, Card } from '@apps/common-ui';
import styled from 'styled-components';

export const Section = styled.section`
    margin: 15px 0;
`;

export const Heading = styled.h2`
    font-weight: normal;
    margin: 5px 0;
`;

export const Subheading = styled.h4`
    font-weight: normal;
    margin: 5px 0 20px 0;
`;

export const MoreLinkContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    a {
        padding: 8px 0;
        margin: 0;
    }
`;

export const HeadingNoSubheading = styled(Heading)`
    margin-bottom: 20px;
`;

export const HomeLinkButton = styled(Buttons.LinkButton)`
    font-weight: bold;
    @media (max-width: ${props => props.theme.screen.small}) {
        margin-left: 0px;
    }

`;
