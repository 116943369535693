import React, { useEffect, useState } from 'react';
import * as S from './index.styles';
import OnboardingList from './OnboardingList';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { markOnboardingComplete } from '../../state/reducers/session/actions';
import { RootState } from '../../state/store';
import Mixpanel from '../../services/Mixpanel';
import { useActionLoader } from '../../hooks/useActionLoader';
import { format, isAfter, set } from 'date-fns';
import { Appointment } from '../../types/models';
import OnboardingService from '../../services/OnboardingService';
import OnboardingVideo from './OnboardingVideo';

const Onboarding = () => {
    const navigate = useNavigate();
    const { user } = useSelector((state: RootState) => state.session);
    const { appointments } = useSelector((state: RootState) => state.coaching);
    const [showOnboardingVideo, setShowOnboardingVideo] = useState(true);
    const [videoWatched, setVideoWatched] = useState(false);
    const { callAction: setOnboardingComplete } = useActionLoader(markOnboardingComplete);
    const [showCompletionModal, setShowCompletionModal] = useState(false);
    const [nextAppointment, setNextAppointment] = useState<Appointment | null>(null);

    const dispatchSetOnboardingComplete = async () => {
        if (user) {
            setOnboardingComplete(user.id);
            OnboardingService.setOnboardingCompleted();
            setShowCompletionModal(true);
            OnboardingService.clearOnboardingStorage(false);
            Mixpanel.track('web_onboardingPage_onboarding_complete');
        }
    };

    const setShowWelcomeVideo = (show: boolean) => {
        OnboardingService.setOnboardingVideoShown(show);
        setShowOnboardingVideo(show);
    };

    const markVideoWatched = () => {
        OnboardingService.setOnboardingVideoWatched();
        setVideoWatched(true);
    };

    useEffect(() => {
        if (showCompletionModal) {
            navigate('/');
        }
    }, [showCompletionModal]);

    useEffect(() => {
        const getNextAppointment = appointments
            .find(appointment => isAfter(new Date(appointment.date), new Date()));
        setNextAppointment(getNextAppointment || null);
    }, [appointments]);

    useEffect(() => {
        if (!OnboardingService.shouldShowOnboardingVideo()) {
            setShowOnboardingVideo(false);
        }
        if (OnboardingService.isOnboardingVideoWatched()) {
            setVideoWatched(true);
        }
    });

    return (
        <S.OnboardingContainer>
            <S.Logo src="/icon.png" />
            <S.OnboardingTitle>Welcome to BreatheSuite</S.OnboardingTitle>
            {showOnboardingVideo && (
                <OnboardingVideo
                  setShowWelcomeVideo={setShowWelcomeVideo}
                  setVideoWatched={markVideoWatched}
                  videoWatched={videoWatched}
                />
            )}
            {!showOnboardingVideo && (
                <>
                    <S.OnboardingText>
                        {nextAppointment
                        ? (
                            <>
                                Before your first appointment on <strong>{format(new Date(nextAppointment.date), 'MMMM dd, yyyy')}</strong>,
                                <br /> we need you to complete the following tasks:
                            </>
                        )
                        : 'Before your first appointment, we need you to complete the following tasks:'}
                    </S.OnboardingText>
                    <OnboardingList
                      dispatchSetOnboardingComplete={dispatchSetOnboardingComplete}
                      sourcePage="onboarding"
                      setShowWelcomeVideo={setShowWelcomeVideo}
                      welcomeVideoWatched={videoWatched}
                    />
                    <S.ButtonsContainer>
                        <S.HelpButton
                          buttonType="tertiary"
                          onClick={() => navigate('/support', { state: { previousPage: '/onboarding' } })}
                        >Get Help
                        </S.HelpButton>
                        <S.SkipButton
                          buttonType="secondary"
                          onClick={() => {
                              OnboardingService.setOnboardingSkipped();
                              navigate('/');
                          }}
                        >Skip
                        </S.SkipButton>
                    </S.ButtonsContainer>
                </>
            )}
        </S.OnboardingContainer>
    );
};

export default Onboarding;
