import styled from 'styled-components';

const Container = styled.div<{
  column?: boolean,
  width?: string,
  justifyContent?: string,
  boxShadow?: string,
  background?: string,
  padding?: string,
  zIndex?: string,
}>`
  width: ${props => props.width || '100%'};
  display: flex;
  justify-content: ${props => props.justifyContent || 'center'};
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  align-items: center;
  box-shadow: ${props => props.boxShadow || 'none'};
  padding: ${props => props.padding};
  z-index: ${props => props.zIndex || props.theme.layout.depth.front}
  position: relative;
`;

export default Container;
