import React, { useEffect } from 'react';
import * as S from './index.styles';
import Mixpanel from '../../services/Mixpanel';
import callSupportImage from '../../assets/CallSupport.svg';
import chatSupportImage from '../../assets/ChatSupport.svg';
import emailSupportImage from '../../assets/EmailSupport.svg';
import { faPhone, faMessage, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router';
import { SupportCall, SupportText, SupportMessage } from '@apps/common-ui';

const Support = () => {
    const phoneNumber = '19282969285';
    const navigate = useNavigate();
    const location = useLocation();
    const previousPage = location.state?.previousPage;

    useEffect(() => {
        Mixpanel.track('web_support_page');
    }, []);

    const toMessagePage = () => navigate('/support/email-support', { state: { previousPage } });

    return (
        <S.SupportContainer>
            {!!previousPage && (
                <S.BackButton buttonType="tertiary" onClick={() => navigate(previousPage)}>
                    <FontAwesomeIcon icon={faChevronLeft as IconProp} />
                    Back
                </S.BackButton>
            )}
            <S.Content>
                <S.HeaderContainer>
                    <S.Header>Contact Support</S.Header>
                </S.HeaderContainer>
                <S.CardContainer>
                    <SupportCall phoneNumber={phoneNumber} supportImage={callSupportImage} />
                    <SupportText phoneNumber={phoneNumber} supportImage={chatSupportImage} />
                    <SupportMessage
                      toMessage={toMessagePage}
                      supportImage={emailSupportImage}
                      messageText="We will respond as soon as possible via email"
                    />
                </S.CardContainer>
            </S.Content>
        </S.SupportContainer>
    );
};

export default Support;
