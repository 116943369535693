import React, { useEffect, useState } from 'react';
import * as S from '../index.styles';
import CustomerSupport from '../../../services/CustomerSupport';
import Mixpanel from '../../../services/Mixpanel';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { SupportTicket } from '@apps/common-ui';
import emailSupportCompletedImage from '../../../assets/EmailSupportCompleted.svg';
import { types } from '@apps/common-utilities';

const EmailSupport = () => {
    const [completed, setCompleted] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const previousPage = location.state?.previousPage;
    const { tenantUser } = useSelector((state: RootState) => state.session);

    const submitTicket = (message: string, issue?: string, name?: string, email?: string) => {
        Mixpanel.track('web_support_ticket_submitted');
        const ticket: types.ISupportTicket = {
            message,
            fullName: tenantUser?.firstName && tenantUser?.lastName ? `${tenantUser.firstName} ${tenantUser.lastName}` : '',
            email: tenantUser?.email ? tenantUser.email : '',
            issue: issue || '',
        };
        if (name) {
            ticket.fullName = name;
        }
        if (email) {
            ticket.email = email;
        }
        CustomerSupport.createTicket(ticket, !tenantUser).then((successful: boolean) => {
            if (successful) {
                setCompleted(true);
                Mixpanel.track('web_ticket-submition_page');
            }
        });
    };

    const mixpanelTrack = (event: string) => {
        Mixpanel.track(event);
    };

    useEffect(() => {
        Mixpanel.track('web_email-support_page');
    }, []);

    return (
        <SupportTicket
          completed={completed}
          onBackClicked={() => navigate(previousPage)}
          submitTicket={submitTicket}
          tenantUser={tenantUser}
          completedMessage={`Please check ${tenantUser?.email ? tenantUser.email : 'your email'} for our response`}
          completedImage={emailSupportCompletedImage}
          message={`We will respond as soon as possible via email ${tenantUser?.email ? `to ${tenantUser.email}` : ''}`}
          mixpanelTrack={mixpanelTrack}
          issueList={[
            { issue: 'Reschedule an Appointment' },
            { issue: 'Technology Support' },
            { issue: 'Other' }
        ]}
        />
    );
};

export default EmailSupport;
