import { Spinner } from '@apps/common-ui';
import React, { useEffect } from 'react';
import useRedirectExternally from '../../hooks/useRedirectExternally';
import Mixpanel from '../../services/Mixpanel';

const CommunitiesRedirect = () => {
    const redirect = useRedirectExternally();

    // Redirect to community group
    useEffect(() => {
        redirect('https://www.facebook.com/groups/9048332911908605/');
    }, []);

    return <div><Spinner /></div>;
};

export default CommunitiesRedirect;
