import { Buttons, Card } from '@apps/common-ui';
import styled from 'styled-components';
import { Page } from '../../components/Page';

interface TextProps {
    fontWeight?: string;
    marginBottom?: string;

}

export const Container = styled(Page)`
    display: flex;
    flex-direction: column;
`;

export const Heading = styled.h2`
    font-weight: bold;
    margin: 5px 0;
    margin-bottom: 20px;
`;

export const ActionPlanCard = styled(Card)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    padding: 0px;
`;

export const ActionPlanCardDivider = styled.hr`
    border: none;
    border-top: 1px solid ${props => props.theme.shades.lighterGrey};
    margin: 0;
`;

export const ActionPlanBody = styled.div`
    padding: 20px;
`;

export const DetailsBody = styled.div`
    padding: 10px;
    padding-left: 20px;
`;

export const ActionPlanText = styled.p<TextProps>`
    margin: 0; 
    margin-bottom: ${props => props.marginBottom || '15px'};
    font-weight: ${props => props.fontWeight || 'normal'};
    @media (max-width: ${props => props.theme.screen.medium}) {
        font-size: 18px;
        line-height: 23px;
    }
`;

export const ActionRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${props => props.theme.screen.medium}) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const ActionPlanButton = styled(Buttons.LinkButton)`
    width: 145px;
    color: ${props => props.theme.text.white};
    background: ${props => props.theme.buttons.blue};
    border: 2px solid ${props => props.theme.buttons.blue};
    text-align: center;
    margin: 0;
    padding: 12px 22px;
    font-size: 1.2 rem;
    white-space: nowrap;
    @media (max-width: ${props => props.theme.screen.medium}) {
        margin-top: 15px;
        
    }
`;

export const BookCallButton = styled(Buttons.Button)`
    width: 220px;
    color: ${props => props.theme.buttons.blue};
    background: ${props => props.theme.shades.white};
    border: 2px solid ${props => props.theme.buttons.blue};
    text-align: center;
    margin: 0;
    padding: 10px 20px;
    font-size: 1.2 rem;
`;

export const ButtonContainer = styled.div`
    margin-top: 5px;
`;

export const ActionPlanMainText = styled.h3`
    font-weight: bold;
    margin: 15px 0; 
    margin-top: 25px;
`;

export const TitleContainer = styled.div<{ colorZone: string }>`
    background-color: ${props => props.theme.actionPlanZone[props.colorZone]};
    display: flex;
    padding: 10px 0;
    border-radius: 7px 7px 0 0;
`;

export const TitleText = styled.h3`
    color: ${props => props.theme.text.black};
    margin: 0;
    padding-left: 20px;
`;

export const ColorText = styled.span<{ colorZone: string }>`
    color: ${props => props.theme.actionPlanZone[props.colorZone]};
    font-weight: bold;
`;

export const BackButton = styled(Buttons.LinkButton)`
    margin-left: 0px;
    padding-left: 0px;
    margin-bottom: 20px;
`;

export const BulletPointsContainer = styled.ul`
  list-style-type: disc; 
  padding-left: 20px;
  margin: 0;
  margin-left: 7px;
`;

export const BulletPoint = styled.li`
`;
