var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Select from 'react-select';
import { theme } from '@apps/common-ui';
// Using react-select. View docs - https://react-select.com/home
var MultiSelect = function (props) {
    var placeholder = props.placeholder;
    var colourStyles = {
        control: function (styles) { return (__assign(__assign({}, styles), { width: '100%', maxWidth: '400px', borderRadius: '10px', backgroundColor: theme.shades.offWhite, margin: '10px', padding: '2px', boxShadow: 'none', border: "1px solid ".concat(theme.shades.lightGrey), '&:hover': {
                border: "1px solid ".concat(theme.shades.lightGrey)
            } })); },
        option: function (styles, _) { return (__assign(__assign({}, styles), { ':hover': {
                backgroundColor: theme.buttons.blue,
                color: 'white'
            } })); },
        multiValueLabel: function (styles) { return (__assign(__assign({}, styles), { backgroundColor: theme.buttons.blue, color: 'white' })); },
        multiValueRemove: function (styles) { return (__assign(__assign({}, styles), { backgroundColor: theme.buttons.blue, color: 'white', ':hover': {
                backgroundColor: theme.buttons.blue,
                color: 'white',
                opacity: 0.8
            } })); },
        placeholder: function (styles) { return (__assign(__assign({}, styles), { color: 'black' })); }
    };
    props = __assign(__assign({}, props), { styles: colourStyles, menuPosition: 'fixed', placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : 'Select', isMulti: true });
    return (_jsx(Select, __assign({}, props)));
};
export default MultiSelect;
